import {
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {AccountsMenuItemChip} from './AccountsMenuItemChip';
import {AccountsMenuItemIcon} from './AccountsMenuItemIcon';
import {formatNumberWithSpacesAndComma} from '../../../../../../utils/number';
import {BANK_TYPES} from '../../../bridge-api/utils/constants';

export const AccountsMenuItem = ({
    label,
    value,
    onChangeTab,
    uncategorized,
    amount,
    color,
    hasError,
    tabValue,
    isHiway,
    isMobile,
}) => {
    const isSelected = tabValue === value;
    const isArchive = value === BANK_TYPES.archived;

    return (
        <Button
            fullWidth
            onClick={e => {
                onChangeTab(e, value);
            }}
            startDecorator={(
                <AccountsMenuItemIcon
                    value={value}
                    isSelected={isSelected}
                    isHiway={isHiway}
                    hasError={hasError}
                />
            )}
            endDecorator={(
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    {(typeof amount === 'number' || typeof amount === 'string') && (
                        <Typography
                            level="body-md"
                            fontStyle="normal"
                            sx={{
                                color: isSelected && !hasError
                                    ? 'neutral.solidColor'
                                    : 'neutral.softColor',
                            }}
                        >
                            {formatNumberWithSpacesAndComma(Number(amount))} €
                        </Typography>
                    )}
                    {hasError && !isSelected && (
                        <Box color="neutral.softColor">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Box>
                    )}
                    {!hasError && !isSelected && (
                        <FontAwesomeIcon icon={faArrowRight} />
                    )}
                </Stack>
            )}
            color={hasError ? 'warning' : (isSelected ? 'primary' : 'neutral')}
            size="lg"
            variant={isSelected ? 'solid' : (hasError ? 'outlined' : 'soft')}
            sx={{
                p: 2,
                ...(!hasError ? {
                    backgroundColor: isSelected ? 'primary.softColor' : 'background.body',
                } : {}),
                boxShadow: 'xs',
                ...(isHiway ? {
                    '& .MuiButton-startDecorator': {
                        mr: 1,
                    },
                } : {}),
                ...(isArchive && !isSelected ? {
                    color: 'neutral.outlinedColor',
                } : {}),
                ...(isMobile ? {
                    minHeight: '2.25rem',
                    height: '2.25rem',
                    py: 1,
                } : {}),
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                flexGrow={1}
                sx={hasError ? {color: 'neutral.softColor'} : {}}
            >
                {label}
                <AccountsMenuItemChip
                    value={value}
                    uncategorized={uncategorized}
                    hasError={hasError}
                    isSelected={isSelected}
                    isMobile={isMobile}
                />
            </Stack>

            {/* <Box
                sx={{
                    position: 'absolute',
                    height: '3px',
                    left: '30%',
                    right: '30%',
                    top: 0,
                    backgroundColor: color ?? 'background.cocoa',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                }}
            /> */}
        </Button>
    );
};

AccountsMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeTab: PropTypes.func.isRequired,
    uncategorized: PropTypes.number,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    hasError: PropTypes.bool,
    tabValue: PropTypes.string,
    isHiway: PropTypes.bool,
    isMobile: PropTypes.bool,
};

AccountsMenuItem.defaultProps = {
    value: null,
    uncategorized: 0,
    amount: null,
    color: null,
    hasError: false,
    tabValue: null,
    isHiway: false,
    isMobile: false,
};
