import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Sheet, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {flexCenter} from '../../../utils/styles';
import {EurAmount} from '../../eur-amount/EurAmount';
import {LoadingSkeleton} from '../loading-skeleton/LoadingSkeleton';

export const FigureSheet = ({icon, title, amount, helperText}) => {
    return (
        <Box
            sx={{
                'borderRadius': 'lg',
                'backgroundColor': 'background.surface',
                'color': 'text.secondary',
                'display': 'flex',
                'alignItems': 'center',
                'p': 2,
                'gap': 1,
                'position': 'relative',
                'overflow': 'hidden',
                'flex': 1,
                'minWidth': 'fit-content',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    width: '6px',
                    backgroundColor: 'var(--joy-palette-primary-softColor)',
                    height: '32px',
                    top: '50%',
                    translate: '-50% -50%',
                    borderRadius: '3px',
                },
            }}
        >
            <Sheet
                color="neutral"
                variant="outlined"
                sx={{
                    borderRadius: '50%',
                    backgroundColor: 'common.white',
                    width: '32px',
                    height: '32px',
                    mr: 0.5,
                    ...flexCenter,
                }}
            >
                <FontAwesomeIcon icon={icon} />
            </Sheet>
            <Typography level="title-sm" textColor="text.secondary">
                {title} :
            </Typography>
            <Typography level="h3" flexShrink={0} textColor="text.primary">
                <LoadingSkeleton placeholder="00000">
                    <Stack direction="row" alignItems="baseline" spacing={0.5}>
                        <EurAmount decimalScale={0} amount={amount} />
                        {helperText && <Typography level="h4" textColor="text.primary">{helperText}</Typography>}
                    </Stack>
                </LoadingSkeleton>
            </Typography>
        </Box>
    );
};

FigureSheet.propTypes = {
    icon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    helperText: PropTypes.string,
};

FigureSheet.defaultProps = {
    helperText: undefined,
};
