import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import DOAFile from './DOAFile/DOAFile';
import FlowStepWrapper from './FlowStepWrapper';
import SectionStatus from './SectionStatus';
import {selectRouterLocation} from '../../../../../../lib/router/connected-router-saga';
import {range} from '../../../../../../utils/number';
import UploadDocuments from '../../../../../../v1/components/ui-kit/UploadDocuments';
import CategorisationSection
    from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import {FILE_FORMAT, PAST_YEAR} from '../../../accounting/utils/constants';
import {DEPOSIT_OF_ACCOUNTS_STATUS, FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepUploadFinalFec = ({
    isActive,
    isFocused,
    annualAccountId,
    status,
    finalFec,
    areDocumentsGenerated,
    onOpenBalanceSheetDetails,
    isRefreshingAnnualAccount,
    balanceSheetAdditionalInfo,
}) => {
    const {t} = useTranslation('companies');
    const params = useParams();
    const {companyId} = params;
    const dispatch = useDispatch();
    const location = useSelector(selectRouterLocation);
    const search = location?.query;
    const year = Number(search?.['year']) ?? PAST_YEAR;

    const [files, setFiles] = useState([]);
    const [hasToReplace, setHasToReplace] = useState(false);

    const {
        balanceSheetSplitPage,
        balanceSheetSplitConfig,
        balanceSheetNumberOfPages,
        balanceSheetCanBeAutomaticallyProcessed,
        manualAfterReplaceActionNeeded,
    } = balanceSheetAdditionalInfo || {};

    const getSuccessAlertText = useCallback(() => {
        let resText = '';
        Object.keys(balanceSheetSplitConfig).forEach(documentType => {
            const {startPage, endPage} = balanceSheetSplitConfig[documentType];
            const documentName = t(`documents:documentsDatabaseNames.${documentType}`);
            resText += t(
                'bilanDocumentAlert.success.text',
                {documentName, startPage, endPage},
            );
        });
        return resText;
    }, [balanceSheetSplitConfig, t]);

    const hasUploaded = !!finalFec && !hasToReplace;
    const isDeletingPossible = status === DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED;

    const isUploadingOrDeleting = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.UPLOAD_FINAL_FEC_FILE),
    );

    const pageRange = range(1, balanceSheetNumberOfPages);

    const allDocumentsGeneratingInProgress = useSelector(AccountingSelector.selectAllDocumentsGenerationProgress);

    const fileLoader = useSelector(AccountingSelector.selectFileLoader);

    useEffect(() => {
        const {eventId, inProgress} = fileLoader?.[0] ?? {};

        if (!!eventId && !inProgress && hasToReplace) {
            setFiles([]);
            if (hasToReplace) {
                // Exit from replace mode after file uploading
                setHasToReplace(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileLoader]);

    const onAddFile = files => {
        setFiles(files);
        dispatch(AccountingActions.uploadFinalFECFile({
            companyId,
            year,
            file: files[0],
            shouldRefreshAnnualAccounts: true,
            annualAccountId,
        }));

        if (hasToReplace) {
            setHasToReplace(false);
        }
    };

    const onRemoveFile = () => {
        setFiles([]);
    };

    const handleDownload = () => {
        dispatch(AccountingActions.downloadFinalFECFile({
            companyId,
            year,
            type: FILE_FORMAT.CSV,
        }));
    };

    const handleReplace = () => {
        setFiles([]);
        setHasToReplace(true);
    };

    const handleDelete = () => {
        if (isDeletingPossible && annualAccountId) {
            setFiles([]);
            dispatch(AccountingActions.deleteFinalFECFile({
                companyId,
                finalFecId: finalFec.id,

                // Make sure to refresh annual account
                annualAccountId,
            }));
        }
    };

    const onSplitPageChange = event => {
        dispatch(AccountingActions.sendBalanceSheetSplitPageData({
            companyId,
            annualAccountId,
            balanceSheetSplitPage: event.target.value,
        }));
    };

    const handleRegenerateBalanceSheetDerivedDocumentsClick = _ => {
        dispatch(AccountingActions.regenerateBalanceSheetDerivedDocuments({
            companyId,
            annualAccountId,
        }));
    };

    const balanceSheetDerivedDocumentsRegenerating = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.REGENERATING_BALANCE_SHEET_DERIVED_DOCUMENTS),
    );

    const isBalanceSheetSplitSelectVisible = useMemo(() => (
        manualAfterReplaceActionNeeded
        || (!areDocumentsGenerated
        && !allDocumentsGeneratingInProgress
        && !balanceSheetCanBeAutomaticallyProcessed)),
    [
        manualAfterReplaceActionNeeded,
        areDocumentsGenerated,
        allDocumentsGeneratingInProgress,
        balanceSheetCanBeAutomaticallyProcessed,
    ]);

    return (
        <FlowStepWrapper>
            <CategorisationSection
                title={t('accounting.uploadFEC.sections.upload')}
                status={isActive
                    ? (isFocused && FORMALITY_SECTION_STATUS.REQUIRED)
                    : FORMALITY_SECTION_STATUS.WAITING_FOR_REPORT_UPLOAD
                    }
                SectionStatusComponent={SectionStatus}
                isActive={isActive}
                isFocused={isActive && isFocused}
                statusTranslationSource="companies"
                statusTranslationPath="accounting.uploadFec.statuses"
                sx={{flex: 1, width: '100%', maxWidth: '600px'}}
            >
                {isActive && (
                    <>
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(16),
                            lineHeight: theme => theme.typography.pxToRem(22),
                            fontWeight: 600,
                        }}
                        >
                            {t('accounting.uploadFEC.flowTitle', {year})}
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: '100%',
                        }}
                        >
                            {!hasUploaded ? (
                                <>
                                    <Alert
                                        severity="info"
                                        sx={{
                                            'color': 'text_v2.secondary',
                                            'borderRadius': '12px',
                                            '.MuiAlert-icon': {
                                                display: 'flex',
                                                alignItems: 'center',
                                            },
                                        }}
                                        icon={(
                                            <InfoOutlinedIcon
                                                sx={{
                                                    fontSize: 'inherit',
                                                    color: 'v2.light.info.main',
                                                }}
                                            />
                                        )}
                                    >
                                        {t('accounting.uploadFEC.alert')}
                                    </Alert>

                                    <UploadDocuments
                                        files={files}
                                        onAddFiles={onAddFile}
                                        onDeleteFile={onRemoveFile}
                                        fileLimit={1}
                                        buttonText={t('accounting.uploadFEC.fileUploadButton')}
                                        acceptFiles="text/csv, text/plain"
                                        hasMaxFileInfoForced
                                        readableFileExtension={t('accounting.uploadFEC.extention')}
                                        dropZoneText=""
                                        isDisabled={isRefreshingAnnualAccount || isUploadingOrDeleting}
                                        isUploadInProgress={isUploadingOrDeleting}
                                    />
                                </>
                            ) : (
                                <DOAFile
                                    fileName={`${year}.csv`}
                                    onDownload={handleDownload}
                                    onDelete={handleDelete}
                                    onReplace={handleReplace}
                                    isDeletingPossible={isDeletingPossible}
                                />
                            )}
                            {!isUploadingOrDeleting && hasUploaded && (
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                <Typography sx={{fontWeight: 600}}>
                                    {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedDataTitle')}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        backgroundColor: areDocumentsGenerated ? 'v2.light.success.shades4' : 'gray_v2.50',
                                        padding: '8px 16px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderRadius: '8px',
                                        gap: 1,
                                    }}
                                >
                                    <Typography>
                                        {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedDataMessage')}
                                    </Typography>
                                    <Button
                                        variant="text"
                                        endIcon={<ArrowForwardIcon />}
                                        sx={{py: 0}}
                                        onClick={() => {
                                            dispatch(AccountingActions.getBalanceSheetDetails(
                                                {companyId, year},
                                            ));
                                            onOpenBalanceSheetDetails();
                                        }}
                                    >
                                        {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.viewAmounts')}
                                    </Button>
                                </Box>
                                {!balanceSheetCanBeAutomaticallyProcessed && (
                                    <Alert
                                        sx={{
                                            'color': balanceSheetSplitPage ? 'v2.light.success.shades160' : 'v2.light.warning.shades160',
                                            'letterSpacing': '0.15px',
                                            '.MuiSvgIcon-root': {
                                                color: balanceSheetSplitPage ? 'v2.light.success.main' : 'v2.light.warning.main',
                                            },
                                        }}
                                        severity={balanceSheetSplitPage ? 'success' : 'warning'}
                                        icon={balanceSheetSplitPage ? <CheckCircleOutline /> : <WarningIcon />}
                                    >
                                        <AlertTitle
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            {
                                                    balanceSheetSplitPage
                                                        ? t('bilanDocumentAlert.success.title')
                                                        : t('bilanDocumentAlert.warning.title')
                                                }
                                        </AlertTitle>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: theme => theme.typography.pxToRem(14),
                                                whiteSpace: 'pre-line !important',
                                            }}
                                        >
                                            {
                                                    balanceSheetSplitPage
                                                        ? getSuccessAlertText()
                                                        : t('bilanDocumentAlert.warning.text', {balanceSheetNumberOfPages})
                                                }
                                        </Typography>
                                    </Alert>
                                )}
                                {
                                    !!isBalanceSheetSplitSelectVisible
                                    && (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                        >
                                            <Typography sx={{fontWeight: 600}}>
                                                {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.selectPageText')}
                                            </Typography>
                                            <FormControl sx={{m: 1, minWidth: '200px'}}>
                                                <InputLabel>{t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.selectPageLabel')}</InputLabel>
                                                <Select
                                                    disabled={!!balanceSheetDerivedDocumentsRegenerating}
                                                    value={balanceSheetSplitPage}
                                                    label={t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.selectPageLabel')}
                                                    onChange={onSplitPageChange}
                                                >
                                                    {pageRange.map((page, index) => (
                                                        <MenuItem
                                                            key={page}
                                                            value={page}
                                                            disabled={index === pageRange.length - 1}
                                                        >{page}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )
                                }
                                {!areDocumentsGenerated && (
                                <Button
                                    variant="contained"
                                    startIcon={allDocumentsGeneratingInProgress
                                        ? <CircularProgress size={24} />
                                        : <DocumentScannerIcon />
                                                }
                                    disabled={
                                        allDocumentsGeneratingInProgress
                                        || isRefreshingAnnualAccount
                                        || (!balanceSheetCanBeAutomaticallyProcessed && !balanceSheetSplitPage)
                                    }
                                    onClick={() => {
                                        dispatch(UiActions.setActiveModal(
                                            ModalsKeys.DEPOSIT_OF_ACCOUNTS_GENERATE_DOCUMENTS,
                                            true,
                                        ));
                                    }}
                                >
                                    {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.buttonGenerateDocuments')}
                                </Button>
                                )}
                                {manualAfterReplaceActionNeeded && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={balanceSheetDerivedDocumentsRegenerating
                                            ? <CircularProgress size={24} />
                                            : <DocumentScannerIcon />
                                        }
                                        disabled={
                                            !!balanceSheetDerivedDocumentsRegenerating
                                            || isRefreshingAnnualAccount
                                            || !balanceSheetSplitPage
                                        }
                                        onClick={handleRegenerateBalanceSheetDerivedDocumentsClick}
                                    >
                                        {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.buttonRegenerateBalanceSheetDocuments')}
                                    </Button>
                                )}
                            </Box>
                            )}
                        </Box>
                    </>
                )}
            </CategorisationSection>
        </FlowStepWrapper>
    );
};

FlowStepUploadFinalFec.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    annualAccountId: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(DEPOSIT_OF_ACCOUNTS_STATUS)).isRequired,
    finalFec: PropTypes.object.isRequired,
    areDocumentsGenerated: PropTypes.bool.isRequired,
    isRefreshingAnnualAccount: PropTypes.bool.isRequired,
    onOpenBalanceSheetDetails: PropTypes.func.isRequired,
    balanceSheetAdditionalInfo: PropTypes.object,
};

FlowStepUploadFinalFec.defaultProps = {
    balanceSheetAdditionalInfo: null,
};

export default React.memo(FlowStepUploadFinalFec);
