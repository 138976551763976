import {useEffect} from 'react';
import {getConfig} from '../v1/config';

/**
 * Hook to dynamically load a script
 * @typedef {Object} ScriptOptions
 * @property {string} [src] - Script source URL
 * @property {string} [type='text/javascript'] - Script type
 * @property {string} [id] - Script ID
 * @property {string} [defer] - Whether to defer the script
 * @property {string} [async=true] - Whether to load the script asynchronously
 */

/**
 * @param {ScriptOptions} options - Script options
 * @param {boolean} [runInDevelopment=true] - Whether to run the script in development environment
 * @returns {void}
 */
const useScript = (options = {async: true, type: 'text/javascript'}, runInDevelopment = true) => {
    const {environment} = getConfig();

    useEffect(() => {
        // Skip script execution if we're in development environment and runInDevelopment is false
        if (environment === 'develop' && !runInDevelopment) return;

        const script = document.createElement('script');

        Object.entries(options).forEach(([key, value]) => {
            script[key] = value;
        });

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [runInDevelopment, environment, options]);
};

export default useScript;
