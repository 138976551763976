import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../ui/utils/constants';

export const AbandonChangesModal = ({onConfirm}) => {
    const {t} = useTranslation('vatDeclaration');

    return (
        <ModalWrapper
            isV3
            modalKey={ModalsKeys.VAT_DECLARATION_ABANDON_CHANGES}
            title={<Typography level="h1">{t('abandonChanges.title')}</Typography>}
            containerSx={{width: '595px'}}
            buttons={({handleClose}) => (
                <Stack direction="row" spacing={2} width="100%">
                    <Button
                        variant="outlined"
                        color="neutral"
                        size="lg"
                        startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                        onClick={() => handleClose()}
                        fullWidth
                    >
                        {t('abandonChanges.cancel')}
                    </Button>
                    <Button
                        variant="soft"
                        color="danger"
                        size="lg"
                        loadingPosition="end"
                        onClick={onConfirm}
                        fullWidth
                    >
                        {t('abandonChanges.confirm')}
                    </Button>
                </Stack>
            )}
        >
            <Typography level="body-lg" color="text.secondary">
                {t('abandonChanges.description')}
            </Typography>
        </ModalWrapper>
    );
};

AbandonChangesModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
};
