import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDynamicIcon} from '../../../../../../../../hooks/useDynamicIcon';

const PopularCategory = ({faIcon, title, onClick, numberOfCategories}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dynamicIcon = useDynamicIcon(faIcon);

    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: 'v2.light.primary.shades4',
                borderRadius: 2,
                minWidth: isMobileSize
                    ? '150px'
                    : `calc((600px - 64px - 16px) / ${numberOfCategories < 3 ? numberOfCategories : 3})`,
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <Typography fontSize="48px">
                <FontAwesomeIcon icon={dynamicIcon ?? faInfoCircle} fontSizeAdjust="48px" />
            </Typography>

            <Typography
                component="span"
                sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    lineHeight: theme => theme.typography.pxToRem(20),
                    letterSpacing: '0.17px',
                    fontWeight: 400,
                    color: 'text_v2.secondary',
                    textAlign: 'center',
                    flex: '1 0 0',
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

PopularCategory.propTypes = {
    faIcon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    numberOfCategories: PropTypes.number,
};

PopularCategory.defaultProps = {
    numberOfCategories: 3,
};

export default PopularCategory;
