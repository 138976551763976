import {Crisp} from 'crisp-sdk-web';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {CONFIG} from '../../config';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import {USER_ROLES} from '../../utils/user-roles';

const CrispModule = () => {
    return <CrispModuleLoader />;
};

const CrispModuleLoader = () => {
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const {id, email, role, firstName, lastName} = loggedInUser || {};

    // Load Crisp on page load
    useEffect(() => {
        window.CRISP_COOKIE_EXPIRE = 172800; // 48h in seconds
        Crisp.configure(CONFIG.CRISP_ID, {
            autoload: false,
        });
        Crisp.setPosition('left');
    }, []);

    // On logged in user change hide/show chat and set neccessary data
    useEffect(() => {
        if (email && id && (role === USER_ROLES.FREELANCER || role === USER_ROLES.CARE) && !Crisp.chat.isVisible()) {
            Crisp.setTokenId(`crisp_${id}`);
            Crisp.user.setEmail(email);

            // Show chat (show implicitly calls load method)
            Crisp.chat.show();
        } else if (Crisp.chat.isVisible()) {
            // Hide chat
            Crisp.chat.hide();
            Crisp.setTokenId(null);
            // Reset API is not working properly on Crisp
            // Crisp.session.reset();
        }
    }, [id, email, role]);

    useEffect(() => {
        const name = firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
        if (name) {
            Crisp.user.setNickname(name);
        }
    }, [firstName, lastName]);

    return null;
};

export default CrispModule;
