import {all, call, delay, fork, put, select, takeEvery} from 'redux-saga/effects';
import {BankActions} from './bank.action';
import {BankActionTypes} from './bank.action-type';
// eslint-disable-next-line import/no-cycle
import {
    bankFillMissingData,
    bankFreelancerCreateAccountFlow,
    bankFreelancerCreateAccountOnboardingFlow,
    bankFreelancerFinalizeOnboardingFlow,
    getBankIntegrationsFlow,
    startKYCFlow,
} from './bank.loader.saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {USER_ROLES, isUserCare} from '../../../utils/user-roles';
import {selectUser} from '../../../v1/app/user/user.selectors';
import {AnimationActions} from '../../animations/store/animations.action';
import {ACCOUNT_STATUSES} from '../../company-profile/modules/accounting/utils/constants';
import {FreelancerSelectors} from '../../freelancer';
import {CapitalDepositInternalSubSteps} from '../../freelancer/modules/capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../freelancer/modules/companies';
import {registrationNotPreparedFlow} from '../../freelancer/modules/companies/store/companies.saga';
import {OnboardingActions} from '../../freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../freelancer/modules/onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {LoadingActions, LoadingTypes} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {BankApi} from '../api/bank.api';
import {TransactionsActions} from '../modules/account-balance/store/transactions.action';
// eslint-disable-next-line import/no-cycle
import {getBankTransactionFlow, watchTransactionsSaga} from '../modules/account-balance/store/transactions.saga';
import {TransactionsSelector} from '../modules/account-balance/store/transactions.selector';
// eslint-disable-next-line import/no-cycle
import {watchBridgeSaga} from '../modules/bridge-api/store/brdige-api.saga';
import {BridgeActions} from '../modules/bridge-api/store/bridge-api.action';
import {
    getHiwayBankIntegration,
    getIntegrationInfo,
} from '../modules/bridge-api/utils/bridge-util';

import {BANK_TYPES} from '../modules/bridge-api/utils/constants';
import {watchBankRibAndDocumentsSaga} from '../modules/rib-and-documents/store/bankRibAndDocuments.saga';
import {DOWNLOADED_RIB_FILE_NAME} from '../modules/rib-and-documents/utils/constants';
// eslint-disable-next-line import/no-cycle
import {watchBeneficieriesSaga} from '../modules/transfers/store/beneficiaries.saga';
// eslint-disable-next-line import/no-cycle
import {handleSSEUploadFileSuccessQRCode, watchTransfersSaga} from '../modules/transfers/store/transfers.saga';

export const getUserIntegrationDataFlow = function* ({userId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, true));

        const bankIntegrations = yield call(BankApi.getBankUserIntegrations, {userId, withArchived: true});

        yield put(BankActions.storeActiveIntegrationData(bankIntegrations.nonArchived));
        yield put(BankActions.storeArchivedIntegrationData(bankIntegrations.archived));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, false));

        return bankIntegrations;
    } catch (e) {
        // TODO Maybe add some more specific error message for this case
        Debug.error('bank', 'Error: ', {e});

        // Clear integration data so user does not see wrong data
        yield put(BankActions.storeActiveIntegrationData([]));
        yield put(BankActions.storeArchivedIntegrationData([]));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, false));
        Toast.error('genericError');
    }
};


const startCapitalDepositFlow = function* (bankIntegrationId, companyId) {
    try {
        const response = yield call(BankApi.startCapitalDeposit, bankIntegrationId, companyId);

        yield put(BankActions.storeCapitalDeposit(response));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        if (e?.response?.status === 409) {
            return;
        }

        yield put(BankActions.setCapitalDepositError(true));

        Toast.error('genericError');
    }
};

const getCapitalDepositFlow = function* (bankIntegrationId, accountHolders) {
    if (!bankIntegrationId || !accountHolders) {
        Debug.error('bank:getCapitalDepositFlow', 'No accountHolders or bankIntegrationId: ',
            {bankIntegrationId, accountHolders});
        return;
    }

    // Capital deposit is not accessible for care user
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    if (isUserCare(loggedInUser)) {
        return {};
    }

    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_CAPITAL_DEPOSIT, true));

        const response = yield call(BankApi.getCapitalDeposit, bankIntegrationId, accountHolders);

        yield put(BankActions.storeCapitalDeposit(response));

        yield put(LoadingActions.setLoading(LoadingTypes.GET_CAPITAL_DEPOSIT, false));

        return response;
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GET_CAPITAL_DEPOSIT, false));

        if (e?.response?.status === 409) {
            return;
        }

        yield put(BankActions.setCapitalDepositError(true));

        Toast.error('genericError');
    }
};

const updateRefusedDocumentsFlow = function* ({bankIntegrationId, accountHolders, files}) {
    if (!bankIntegrationId || !accountHolders) {
        Debug.error('bank:getCapitalDepositFlow', 'No accountHolders or bankIntegrationId: ',
            {bankIntegrationId, accountHolders});
        return;
    }

    try {
        yield put(LoadingActions.setLoading(LoadingTypes.UPDATE_REFUSED_DOCUMENTS, true));

        yield all(files.map(({type, file}) => call(
            BankApi.uploadCapitalDepositDocument,
            bankIntegrationId, accountHolders, file.file, type,
        )));

        yield delay(1000); // TODO: verify this value from experience
        yield put(LoadingActions.setLoading(LoadingTypes.UPDATE_REFUSED_DOCUMENTS, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.UPDATE_REFUSED_DOCUMENTS, null));
        yield put(BankActions.setCapitalDepositError(true));

        Toast.error('genericError');
    }
};

const transitionCapitalDepositFlow = function* (bankIntegrationId, accountHolders, shouldCallAPI = true) {
    const activeSubStepKey = yield select(OnboardingSelectors.selectSubStep);
    const progress = yield select(OnboardingSelectors.selectProgress);

    try {
        if (shouldCallAPI) {
            yield call(BankApi.transitionCapitalDeposit, bankIntegrationId, accountHolders);
        }

        if (activeSubStepKey !== CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER) {
            yield put(AnimationActions.storeNextDispatch(
                OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION),
            ));
            yield put(AnimationActions.setIsAnimationActive(false));

            yield put(OnboardingActions.setProgress({
                ...progress,
                [OnboardingSteps.CAPITAL_DEPOSIT]: {
                    ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                    isCompleted: true,
                    subSteps: {},
                },
            }));
        }
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});

        if (e?.response?.status === 409) {
            if (e?.response.data?.message
                === 'Capital deposit is in invalid status to transition: WAITING_FOR_SHAREHOLDERS_FUND_DEPOSIT') {
                return;
            }
        }

        Toast.error('genericError');
    }
};

const closeBankAccountFlow = function* (data) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        const response = yield call(BankApi.closeBankAccount, data);

        if (response?.consentUrl) {
            window.location = response.consentUrl;
        }
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const deleteBankAccountFlow = function* ({id}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK, true));

        yield call(BankApi.deleteBankAccount, {id});

        yield all([
            put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK, false)),
            put(UiActions.setModalData(ModalsKeys.DELETE_INTEGRATION_MODAL, null)),
            put(UiActions.setActiveModal(ModalsKeys.DELETE_INTEGRATION_MODAL, false)),
        ]);

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        let integrations;
        if (loggedInUser.role === USER_ROLES.ADMINISTRATOR) {
            const user = yield select(selectUser);

            integrations = yield call(getUserIntegrationDataFlow, {userId: user?.id});
        } else {
            integrations = yield call(getUserIntegrationDataFlow, {userId: loggedInUser?.id});
        }

        yield call(setNewDefaultBank, integrations?.nonArchived);
    } catch (e) {
        Debug.error('bank deleteBankAccountFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK, false));
    }
};

const archiveBankAccountFlow = function* ({
    bankAccountHolderId,
    id,
    bankStatements,
    archivingDate,
    isBridgeApiIntegration,
}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK, true));

        yield call(BankApi.archiveBankAccount, {
            bankAccountHolderId,
            id,
            bankStatements,
            archivingDate,
            isBridgeApiIntegration,
        });

        yield all([
            put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK, false)),
            put(UiActions.setModalData(ModalsKeys.ARCHIVE_INTEGRATION_MODAL, null)),
            put(UiActions.setActiveModal(ModalsKeys.ARCHIVE_INTEGRATION_MODAL, false)),
        ]);

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const integrations = yield call(getUserIntegrationDataFlow, {userId: user?.id});

        yield call(setNewDefaultBank, integrations?.nonArchived);
    } catch (e) {
        Debug.error('bank archiveBankAccountFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK, false));
    }
};

const setNewDefaultBank = function* (integrations) {
    try {
    // TODO Add to selector
    // Count integrations with account
        let numberOfIntegrationsWithAccount = 0;
        if (integrations && integrations?.length > 0) {
            integrations.forEach(integration => {
                const {account} = getIntegrationInfo(integration);

                if (account?.id && account?.status === ACCOUNT_STATUSES.ACTIVE) {
                    numberOfIntegrationsWithAccount += 1;
                }
            });
        }

        if (numberOfIntegrationsWithAccount > 0) {
            const hasDefault = integrations.some(integration => {
                const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
                const bankAccount = bankAccountHolder?.bankAccounts?.[0];
                return bankAccount?.isDefault;
            });

            // If there is no default
            if (!hasDefault) {
                if (numberOfIntegrationsWithAccount > 1) {
                    yield put(UiActions.setActiveModal(ModalsKeys.PICK_DEFAULT_BANK_MODAL, true));
                } else {
                // If there is only one make that one default
                    const integration = integrations.find(integration => {
                        const {account} = getIntegrationInfo(integration);

                        return account?.id && account?.status === ACCOUNT_STATUSES.ACTIVE;
                    });

                    const bankAccountHolder = integration.bankAccountHolders.find(holder => holder.type === 'COMPANY');
                    const bankAccount = bankAccountHolder?.bankAccounts?.[0];

                    if (integration) {
                        yield put(BridgeActions.setDefaultBank(
                            {
                                id: bankAccount?.id,
                                bankAccountHolderId: bankAccountHolder?.id,
                            },
                        ));

                        yield put(UiActions.setActiveModal(ModalsKeys.NEW_DEFAULT_ACCOUNT_MODAL, true));
                    }
                }
            }
        }
    } catch (e) {
        Debug.error('bank setNewDefaultBank', 'Error: ', {e});
        throw new Error(e);
    }
};


// TODO: THIS IS NOT USED ANY MORE for SwanIO, lets keep it in case we may use it for Bridge API
// const generateAndDownloadRibFlow = function* (freelancerId, companyId) {
//     try {
//         // Generate new RIB file
//         yield call(BankApi.generateRibFile, {
//             freelancerId,
//             companyId,
//         });
//
//         // Poll documents until RIB is generated
//         yield call(pollDocumentsByType, {
//             freelancerId,
//             companyId,
//             type: DOCUMENT_TYPES.BANK_ACCOUNT_DETAILS,
//         });
//
//         // Download RIB
//         const documents = yield select(DatabaseSelectors.selectDocuments);
//
//         const ribDocument = Object.values(documents).find(document => {
//             return document.type === DOCUMENT_TYPES.BANK_ACCOUNT_DETAILS
//                 && document.category === DOCUMENT_CATEGORIES.BANK;
//         });
//
//         if (ribDocument) {
//             yield call(downloadDocumentSaga, {
//                 freelancerId,
//                 companyId,
//                 documentId: ribDocument.id,
//                 isDownload: true,
//             });
//         } else {
//             Toast.error('genericError');
//         }
//
//         yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
//     } catch (e) {
//         Debug.error('bank', 'Error: ', {e});
//         Toast.error('genericError');
//
//         yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
//     }
// };

const downloadSwanRibFlow = function* (freelancerId, companyId, integration) {
    try {
        const {account, bankAccountHolder} = getIntegrationInfo(integration);
        const bankAccountHolderId = bankAccountHolder?.id;
        const bankAccountId = account?.id;

        const response = yield call(BankApi.getRibDocumentData, {bankAccountHolderId, bankAccountId});

        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
        window.saveAs(response?.documentDataUrl, DOWNLOADED_RIB_FILE_NAME);
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
    }
};

export const handleSSEUploadFileFail = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(TransactionsSelector.createModalFileLoaderSelectorByEventId(message.id));

        yield put(TransactionsActions.updateFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        // Get transaction info (needed for categorization)
        if (event.transactionId) {
            yield call(getBankTransactionFlow, {
                id: event.transactionId,
                accountId: event?.accountId,
            });
        }

        Toast.error('bankFileUploadError');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleSSEUploadFileSuccess = function* (data) {
    try {
        const message = JSON.parse(data.data);

        // Get transaction related to event
        const event = yield select(TransactionsSelector.createModalFileLoaderSelectorByEventId(message.id));

        if (!event?.transactionId) {
            // If there is not transfer try QR code event
            handleSSEUploadFileSuccessQRCode(message);
            return;
        }

        // Get transaction info (needed for categorization)
        if (event.transactionId) {
            const replacement = yield select(TransactionsSelector.selectReplacedTransaction);
            // If transaction is replaced use replacement ID
            if (replacement?.from && replacement?.from === event?.transactionId) {
                yield call(getBankTransactionFlow, {
                    id: replacement.to,
                    accountId: event?.accountId,
                    doNotShowLoader: true,
                });
            } else {
                yield call(getBankTransactionFlow, {
                    id: event.transactionId,
                    accountId: event?.accountId,
                    doNotShowLoader: true,
                });
            }
        }

        // Set in progress to false
        yield put(TransactionsActions.updateFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const getTransactionListInitWorker = function* () {
    // TODO Bridge check if needed
    yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, false));
    // return;
    //
    // const accountId = yield call(getHiwayAccountId);
    //
    // yield call(getBankIntegrationsFlow);
    //
    // yield call(
    //     getTransactionListFlow,
    //     {
    //         sortOrder: 'DESC',
    //         limit: LIMIT_COUNT,
    //         offset: 0,
    //         searchQuery: undefined,
    //         filterQuery: undefined,
    //     },
    //     accountId,
    // );
};

const downloadRibWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, true));

    yield call(downloadSwanRibFlow, payload.freelancerId, payload.companyId, payload.integration);
};

const startCapitalDepositWorker = function* () {
    yield put(BankActions.setCapitalDepositError(false));

    // Fetch bank integration data
    const bankIntegration = yield call(getBankIntegrationsFlow);

    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    if (
        ![
            'REGISTRATION_NOT_PREPARED',
            'REGISTRATION_PARTIALLY_PREPARED',
            'REGISTRATION_LAUNCHED',
            'REGISTRATION_FINALIZATION',
            'REGISTRATION_COMPLETED',
        ].includes(company.status)
    ) {
        yield call(registrationNotPreparedFlow, {
            freelancerId: freelancer.id,
            companyId: company.id,
        });
    }

    const hiwayBankIntegration = getHiwayBankIntegration(bankIntegration);
    yield call(startCapitalDepositFlow, hiwayBankIntegration?.id, company?.id);

    // Fetch bank integration data
    yield call(getBankIntegrationsFlow);
};

export const getCapitalDepositWorker = function* () {
    const bankIntegration = yield call(getBankIntegrationsFlow);

    const hiwayBankIntegration = getHiwayBankIntegration(bankIntegration);
    const {bankAccountHolder} = getIntegrationInfo(hiwayBankIntegration);

    return yield call(getCapitalDepositFlow, hiwayBankIntegration?.id, bankAccountHolder?.id);
};

export const transitionCapitalDepositWorker = function* ({payload}) {
    const bankIntegration = yield call(getBankIntegrationsFlow);

    const hiwayBankIntegration = getHiwayBankIntegration(bankIntegration);
    const bankAccountHolders = hiwayBankIntegration?.bankAccountHolders;
    const bankAccountHolder = bankAccountHolders
        ? bankAccountHolders.find(holder => holder.type === 'COMPANY')
        : {};

    yield call(transitionCapitalDepositFlow, hiwayBankIntegration?.id, bankAccountHolder?.id, payload);

    yield put(BankActions.getCapitalDeposit());
};

const closeBankAccountWorker = function* ({payload}) {
    const {integration} = payload ?? {};

    if (integration) {
        const {account, bankAccountHolder} = getIntegrationInfo(integration);

        yield call(closeBankAccountFlow, {
            bankAccountId: account?.id,
            bankAccountHolderId: bankAccountHolder?.id,
            redirectUri: `${window.location.origin}${RoutePaths.BANK_TRANSACTION_LIST}?closeAccountId=${integration?.id}`,
        });
    } else {
        const bankIntegration = yield call(getBankIntegrationsFlow);

        const hiwayBankIntegration = getHiwayBankIntegration(bankIntegration);
        const bankAccountHolders = hiwayBankIntegration?.bankAccountHolders;
        const bankAccountHolder = bankAccountHolders
            ? bankAccountHolders.find(holder => holder.type === 'INDIVIDUAL')
            : {};

        yield call(closeBankAccountFlow, {
            bankAccountHolderId: bankAccountHolder?.id,
            bankAccountId: bankAccountHolder?.bankAccounts?.[0]?.id,
        });
    }
};

const deleteBankAccountWorker = function* ({payload}) {
    yield call(deleteBankAccountFlow, {
        id: payload?.id,
    });
};

const archiveBankAccountWorker = function* ({payload}) {
    const isBridgeApiIntegration = payload?.integration?.type === BANK_TYPES.bridge;
    const {bankAccountHolder, account} = getIntegrationInfo(payload?.integration);
    const {bankStatements, archivingDate} = payload;

    yield call(archiveBankAccountFlow, {
        bankAccountHolderId: bankAccountHolder?.id,
        id: account?.id,
        bankStatements,
        archivingDate,
        isBridgeApiIntegration,
    });
};

const getUserIntegrationDataWorker = function* ({payload}) {
    yield call(getUserIntegrationDataFlow, {
        userId: payload,
    });
};

const updateRefusedDocumentsWorker = function* ({payload}) {
    const bankIntegration = yield call(getBankIntegrationsFlow);

    const hiwayBankIntegration = getHiwayBankIntegration(bankIntegration);
    const {bankAccountHolder} = getIntegrationInfo(hiwayBankIntegration);

    return yield call(updateRefusedDocumentsFlow, {
        bankIntegrationId: hiwayBankIntegration?.id,
        accountHolders: bankAccountHolder?.id,
        files: payload,
    });
};

export const watchBankSaga = function* () {
    yield fork(watchBeneficieriesSaga);
    yield fork(watchTransfersSaga);
    yield fork(watchBridgeSaga);
    yield fork(watchBankRibAndDocumentsSaga);
    yield fork(watchTransactionsSaga);

    yield all([
        // General
        takeEvery(BankActionTypes.CREATE_BANK_ACCOUNT, bankFreelancerCreateAccountFlow),
        takeEvery(BankActionTypes.CREATE_BANK_ACCOUNT_ONBOARDING, bankFreelancerCreateAccountOnboardingFlow),
        takeEvery(BankActionTypes.FINALIZE_BANK_ACCOUNT, bankFreelancerFinalizeOnboardingFlow),
        takeEvery(BankActionTypes.FILL_MISSING_DATA, bankFillMissingData),
        takeEvery(BankActionTypes.START_KYC, startKYCFlow),
        takeEvery(BankActionTypes.DOWNLOAD_RIB, downloadRibWorker),
        takeEvery(BankActionTypes.GET_USER_INTEGRATION_DATA, getUserIntegrationDataWorker),
        // TODO Split bank onboarding in separate files
        // Onboarding
        takeEvery(BankActionTypes.START_CAPITAL_DEPOSIT, startCapitalDepositWorker),
        takeEvery(BankActionTypes.GET_CAPITAL_DEPOSIT, getCapitalDepositWorker),
        takeEvery(BankActionTypes.TRANSITION_CAPITAL_DEPOSIT_STATUS, transitionCapitalDepositWorker),
        takeEvery(BankActionTypes.CLOSE_BANK_ACCOUNT, closeBankAccountWorker),
        takeEvery(BankActionTypes.DELETE_ACCOUNT, deleteBankAccountWorker),
        takeEvery(BankActionTypes.ARCHIVE_ACCOUNT, archiveBankAccountWorker),
        takeEvery(BankActionTypes.UPDATE_REFUSED_DOCUMENTS, updateRefusedDocumentsWorker),
    ]);
};
