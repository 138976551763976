export const InsuranceStatus = {
    SIGNED: 'SIGNED',
    NOT_SIGNED: 'NOT_SIGNED',
    WAITING_FOR_COMPANY_REGISTRATION: 'WAITING_FOR_COMPANY_REGISTRATION',
};

export const InsuranceType = {
    RC: 'RC',
    RC_PC: 'RC_PC',
    PC: 'PC',
};

export const INSURANCE_TABS = {RC_TAB: 'RC_TAB', PC_TAB: 'PC_TAB'};

export const AXA_LINK = 'https://www.axa-assurancescollectives.fr/faq-prevoyance-entreprise/';


export const NOTIFICATION_TYPES = {
    insurance: 'insurance',
};
