import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Chip} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import JoyUIThemeProvider from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {importLocaleBundle} from '../../../lib/i18next';
import {VatDeclarationStatus, VatDeclarationStatusIcons} from '../constants';

// TODO: Move translations to formalities
importLocaleBundle('vatDeclaration');

const variants = {
    [VatDeclarationStatus.VALIDATED]: {
        color: 'success',
        variant: 'outlined',
    },
    [VatDeclarationStatus.SUBMITTED]: {
        color: 'neutral',
        variant: 'soft',
    },
    [VatDeclarationStatus.TODO]: {
        color: 'neutral',
        variant: 'soft',
    },
    [VatDeclarationStatus.REFUSED]: {
        color: 'danger',
        variant: 'soft',
    },
    [VatDeclarationStatus.PENDING_VERIFICATION]: {
        color: 'warning',
        variant: 'soft',
    },
    [VatDeclarationStatus.ERROR]: {
        color: 'danger',
        variant: 'solid',
    },
};

export const Status = ({status, size}) => {
    const {color, variant} = variants[status] || {};
    const icon = VatDeclarationStatusIcons[status];
    const {t} = useTranslation('vatDeclaration');

    return (
        <JoyUIThemeProvider>
            <Chip
                color={color}
                variant={variant}
                size={size}
                startDecorator={(
                    <Box mt="1px" fontSize="12px">
                        <FontAwesomeIcon fixedWidth icon={icon} />
                    </Box>
                )}
                sx={{px: 1}}
            >
                {t(`status.${status}`)}
            </Chip>
        </JoyUIThemeProvider>
    );
};

Status.propTypes = {
    status: PropTypes.string.isRequired,
    size: PropTypes.string,
};

Status.defaultProps = {
    size: 'md',
};
