import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton, Tooltip} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import JoyUIThemeProvider from '../../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {DocumentActions} from '../../../../../document/store/document.action';
import {LoadingTypes, useLoadingState} from '../../../../../loading';

const DownloadStatementAction = ({rowData}) => {
    const {t} = useTranslation('bankRibAndDocuments');
    const dispatch = useDispatch();
    const isDownloadInProgress = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);

    const downloadStatementDocument = useCallback(() => {
        if (!isDownloadInProgress) {
            dispatch(DocumentActions.getDocument(rowData?.documentId, true));
        }
    }, [dispatch, isDownloadInProgress, rowData?.documentId]);

    return (
        <JoyUIThemeProvider>
            <Tooltip placement="top" title={t('downloadPdf')}>
                <IconButton
                    sx={{
                        'borderRadius': 2,
                        'bgcolor': 'v2.blueGray.50',
                        '&:hover': {
                            bgcolor: 'v2.light.primary.shades12',
                        },
                    }}
                    onClick={downloadStatementDocument}
                    disabled={!!isDownloadInProgress}
                >
                    <FontAwesomeIcon icon={faDownload} />
                </IconButton>
            </Tooltip>
        </JoyUIThemeProvider>
    );
};

DownloadStatementAction.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default DownloadStatementAction;
