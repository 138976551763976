export const JobTypes = {
    DEVELOPER: 'DEVELOPER',
    LEAD_DEVELOPER: 'LEAD_DEVELOPER',
    SOFTWARE: 'SOFTWARE_ARCHITECT',
    DATA_ENGINEER: 'DATA_ENGINEER',
    DEVOPS: 'DEVOPS',
    PHARMACOVIGILANCE: 'PHARMACOVIGILANCE',
    PRODUCT_OWNER: 'PRODUCT_OWNER',
    PRODUCT_MANAGER: 'PRODUCT_MANAGER',
    PROJECT_MANAGER: 'PROJECT_MANAGER',
    SCRUM_MASTER: 'SCRUM_MASTER',
    COACH_AGILE: 'COACH_AGILE',
    CTO: 'CTO',
    INFRA_PROD: 'INFRA_PROD',
    PRODUCT_DESIGNER: 'PRODUCT_DESIGNER',
    BUSINESS_INTELLIGENCE: 'BUSINESS_INTELLIGENCE',
    BUSINESS_ANALYST: 'BUSINESS_ANALYST',
    ARCHITECT: 'ARCHITECT',
    UX_UI_DESIGNER: 'UX_UI_DESIGNER',
    UX_WRITER: 'UX_WRITER',
    ARTISTIC_DIRECTION: 'ARTISTIC_DIRECTION',
    NETWORK_TELECOM: 'NETWORK_TELECOM',
    CLOUD: 'CLOUD',
    CPO: 'CPO',
    OTHER: 'OTHER',
    PMO: 'PMO',
};

const CareJobTypesSurgical = {
    MAXILLOFACIAL_SURGERY: 'MAXILLOFACIAL_SURGERY',
    ORAL_SURGERY: 'ORAL_SURGERY',
    ORTHOPEDIC_AND_TRAUMATOLOGIC_SURGERY: 'ORTHOPEDIC_AND_TRAUMATOLOGIC_SURGERY',
    PEDIATRIC_SURGERY: 'PEDIATRIC_SURGERY',
    PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY: 'PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY',
    THORACIC_AND_CARDIOVASCULAR_SURGERY: 'THORACIC_AND_CARDIOVASCULAR_SURGERY',
    VASCULAR_SURGERY: 'VASCULAR_SURGERY',
    VISCERAL_AND_DIGESTIVE_SURGERY: 'VISCERAL_AND_DIGESTIVE_SURGERY',
    OBSTETRIC_GYNECOLOGY: 'OBSTETRIC_GYNECOLOGY',
    NEUROSURGERY: 'NEUROSURGERY',
    OPHTHALMOLOGY: 'OPHTHALMOLOGY',
    OTORHINOLARYNGOLOGY_AND_CERVICOFACIAL_SURGERY: 'OTORHINOLARYNGOLOGY_AND_CERVICOFACIAL_SURGERY',
    UROLOGY: 'UROLOGY',
    STOMATOLOGY: 'STOMATOLOGY',
};

const CareJobTypesMedical = {
    ALLERGOLOGY: 'ALLERGOLOGY',
    ANATOMICAL_AND_CYTOPATHOLOGY: 'ANATOMICAL_AND_CYTOPATHOLOGY',
    ANESTHESIA_AND_RESUSCITATION: 'ANESTHESIA_AND_RESUSCITATION',
    DERMATOLOGY_AND_VENEREOLOGY: 'DERMATOLOGY_AND_VENEREOLOGY',
    ENDOCRINOLOGY_DIABETOLOGY_AND_NUTRITION: 'ENDOCRINOLOGY_DIABETOLOGY_AND_NUTRITION',
    MEDICAL_GENETICS: 'MEDICAL_GENETICS',
    GERIATRICS: 'GERIATRICS',
    MEDICAL_GYNECOLOGY: 'MEDICAL_GYNECOLOGY',
    HEMATOLOGY: 'HEMATOLOGY',
    HEPATOGASTROENTEROLOGY: 'HEPATOGASTROENTEROLOGY',
    INFECTIOUS_AND_TROPICAL_DISEASES: 'INFECTIOUS_AND_TROPICAL_DISEASES',
    CARDIOVASCULAR_MEDICINE: 'CARDIOVASCULAR_MEDICINE',
    EMERGENCY_MEDICINE: 'EMERGENCY_MEDICINE',
    OCCUPATIONAL_MEDICINE_AND_HEALTH: 'OCCUPATIONAL_MEDICINE_AND_HEALTH',
    GENERAL_PRACTICE: 'GENERAL_PRACTICE',
    INTENSIVE_CARE_MEDICINE_AND_RESUSCITATION: 'INTENSIVE_CARE_MEDICINE_AND_RESUSCITATION',
    INTERNAL_MEDICINE_AND_CLINICAL_IMMUNOLOGY: 'INTERNAL_MEDICINE_AND_CLINICAL_IMMUNOLOGY',
    FORENSIC_MEDICINE_AND_MEDICAL_EXPERTISE: 'FORENSIC_MEDICINE_AND_MEDICAL_EXPERTISE',
    NUCLEAR_MEDICINE: 'NUCLEAR_MEDICINE',
    PHYSICAL_AND_REHABILITATION_MEDICINE: 'PHYSICAL_AND_REHABILITATION_MEDICINE',
    VASCULAR_MEDICINE: 'VASCULAR_MEDICINE',
    NEPHROLOGY: 'NEPHROLOGY',
    NEUROLOGY: 'NEUROLOGY',
    ONCOLOGY: 'ONCOLOGY',
    PEDIATRICS: 'PEDIATRICS',
    PULMONOLOGY: 'PULMONOLOGY',
    PSYCHIATRY: 'PSYCHIATRY',
    RADIOLOGY_AND_MEDICAL_IMAGING: 'RADIOLOGY_AND_MEDICAL_IMAGING',
    RHEUMATOLOGY: 'RHEUMATOLOGY',
    PUBLIC_HEALTH: 'PUBLIC_HEALTH',
    MEDICAL_BIOLOGY: 'MEDICAL_BIOLOGY',
};

export const CareJobTypes = {
    ...CareJobTypesSurgical,
    ...CareJobTypesMedical,
};

export const CareJobTypesWithGrouping = {
    'surgicalSpecialty': Object.values(CareJobTypesSurgical),
    'medicalSpecialty': Object.values(CareJobTypesMedical),
};

export const ContactSource = {
    RECOMMENDATION: 'RECOMMENDATION',
    WEBSITE_LEAD: 'WEBSITE_LEAD',
    LINKEDIN: 'LINKEDIN',
    FACEBOOK: 'FACEBOOK',
    INSTAGRAM: 'INSTAGRAM',
    MEETUP: 'MEETUP',
    WEBINAR: 'WEBINAR',
    CONFERENCE: 'CONFERENCE',
    LEAD: 'LEAD',
    OTHER: 'OTHER',
    // WALK_IN: 'WALK_IN',
};

export const StartContractType = {
    DIRECT_START_WITHOUT_PE: 'DIRECT_START_WITHOUT_PE',
    DIRECT_START_WITH_PE: 'DIRECT_START_WITH_PE',
    RDD_1_MONTH: 'RDD_1_MONTH',
    RDD_4_MONTHS: 'RDD_4_MONTHS',
};

export const StartContractTypeCare = {
    DIRECT_START_WITHOUT_PE: 'DIRECT_START_WITHOUT_PE',
    DIRECT_START_WITH_PE: 'DIRECT_START_WITH_PE',
};

export const ContractStatus = {
    IN_RESEARCH: 'IN_RESEARCH',
    CONTRACT_FOUND: 'CONTRACT_FOUND',
};

export const UserStatus = {
    INVITED: 'INVITED',
    CONTRACT_PENDING: 'CONTRACT_PENDING',
    COMPANY_PENDING: 'COMPANY_PENDING',
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    WORKSHOPS_SCHEDULED: 'WORKSHOPS_SCHEDULED',
    CONTRACT_SIGNED: 'CONTRACT_SIGNED',
};
