import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Chip, Stack, Typography} from '@mui/joy';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import useMediaQuery from '../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {TREASURY_MOBILE_SIZE} from '../../../dashboard-v3/utils/constants';
import {ModalsKeys} from '../../../ui/utils/constants';
import {BridgeActions} from '../../modules/bridge-api/store/bridge-api.action';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.BANK_ACCOUNT_HIWAY_OR_BRIDGE;

const BankAccountHiwayOrBridgeModal = () => {
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:${TREASURY_MOBILE_SIZE})`);

    const handleAddBridgeBank = () => {
        dispatch(BridgeActions.connectToBridge());
    };

    const handleAddHiwayBank = () => {
        dispatch(BankActions.createBankAccount());
    };

    return (
        <ModalWrapper
            isV3
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('bankAccountHiwayOrBridgeModal.title')}
                </Typography>
            )}
            containerSx={{
                width: '520px',
            }}
        >
            <Typography>
                {t('bankAccountHiwayOrBridgeModal.description')}
            </Typography>
            <Stack
                spacing={2}
                sx={{mt: 4}}
            >
                <Button
                    variant="solid"
                    color="primary"
                    startDecorator={<FontAwesomeIcon icon={faPlus} />}
                    size={isMobileSize ? 'sm' : 'md'}
                    onClick={handleAddHiwayBank}
                    endDecorator={(
                        <Chip variant="soft" sx={{backgroundColor: 'primary.600'}}>
                            <Typography level="body-xs" sx={{color: 'danger.solidColor'}}>
                                {t('bankAccountHiwayOrBridgeModal.recommended')}
                            </Typography>
                        </Chip>
                    )}
                >
                    {t('bankAccountHiwayOrBridgeModal.newHiwayProAccount')}
                </Button>
                <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={<FontAwesomeIcon icon={faPlus} />}
                    size={isMobileSize ? 'sm' : 'md'}
                    onClick={handleAddBridgeBank}
                    endDecorator={(
                        <Chip variant="soft" sx={{backgroundColor: 'background.body'}}>
                            <Typography level="body-xs" sx={{color: 'neutral.softColor'}}>
                                {t('bankAccountHiwayOrBridgeModal.otherBank')}
                            </Typography>
                        </Chip>
                    )}
                >
                    {t('bankAccountHiwayOrBridgeModal.newAccount')}
                </Button>
            </Stack>
        </ModalWrapper>
    );
};

export {
    BankAccountHiwayOrBridgeModal,
};
