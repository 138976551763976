import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {useDynamicIcon} from '../../../../../../../../hooks/useDynamicIcon';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {AccountBalanceHelperFunctions} from '../../../../util/functions';

const CategoryName = ({category, isSelected}) => {
    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';
    const selectCategoriesObject = useSelector(TransactionsSelector.selectCategoriesObject);
    const {faIcon} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectCategoriesObject,
        category,
    });
    const categoryNameName = Array.isArray(category) ? category?.[0] : category;
    const translatedCategoryName = selectCategoriesObject[categoryNameName]?.[language] ?? categoryNameName;

    const dynamicIcon = useDynamicIcon(faIcon);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                color: isSelected ? '#fff' : 'text_v2.secondary',
            }}
        >
            <FontAwesomeIcon icon={dynamicIcon} />
            <Typography
                sx={{
                    ml: 1,
                    fontSize: theme => theme.typography.pxToRem(14),
                }}
            >
                {translatedCategoryName}
            </Typography>
        </Box>
    );
};

CategoryName.propTypes = {
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    isSelected: PropTypes.bool.isRequired,
};

export default React.memo(CategoryName);
