import {faArrowRight, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {EurAmount} from '../../../../components/eur-amount/EurAmount';
import {LoadingSkeleton, LoadingSkeletonProvider} from '../../../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {resolveRoute} from '../../../../v1/navigation/resolveRoute';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {VatDeclarationSection} from '../VatDeclarationSection';

const Item = ({title, content}) => (
    <Stack spacing={1}>
        <Typography level="body-md" textColor="text.tertiary">
            {title}
        </Typography>
        <Typography level="body-md" textColor="neutral.plainColor">
            {content}
        </Typography>
    </Stack>
);

Item.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
};

export const DeclarationHistoryOverview = () => {
    const {t} = useTranslation('vatDeclaration');
    const historyOverview = useSelector(VatDeclarationSelectors.selectHistoryOverview);
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_HISTORY_OVERVIEW),
    );

    return (
        <LoadingSkeletonProvider isLoading={isLoading}>
            <VatDeclarationSection title={t('Historique de l’année en cours')}>
                <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Item
                            title={t('totalVatDeclared')}
                            content={(
                                <LoadingSkeleton placeholder="0000">
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                        <EurAmount amount={historyOverview?.totalVatDeclared} />
                                        {Number(historyOverview?.totalVatDeclared) > 0 && (
                                            <Box color="success.softActiveColor">
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </Box>
                                        )}
                                    </Stack>
                                </LoadingSkeleton>
                              )}
                        />
                        <Item
                            title={t('totalVatCollected')}
                            content={(
                                <LoadingSkeleton placeholder="0000">
                                    <EurAmount amount={historyOverview?.totalVatCollected} />
                                </LoadingSkeleton>
                              )}
                        />
                        <Item
                            title={t('totalVatDeducted')}
                            content={(
                                <LoadingSkeleton placeholder="0000">
                                    <EurAmount amount={historyOverview?.totalVatDeducted} />
                                </LoadingSkeleton>
                              )}
                        />
                    </Stack>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            size="sm"
                            variant="soft"
                            color="neutral"
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                            component={Link}
                            to={resolveRoute(
                                RoutePaths.VAT_DECLARATION_YEARLY_HISTORY,
                                {declarationId: declaration.id},
                            )}
                        >
                            {t('details')}
                        </Button>
                    </Box>
                </Stack>
            </VatDeclarationSection>
        </LoadingSkeletonProvider>
    );
};

