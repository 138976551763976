import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

const DocumentSigningToolbar = ({
    MainActionComponent,
    onNextClick,
    onSignClick,
    isSigning,
    isLoadingNextStep,
    documentId,
    isSigned,
}) => {
    const {t} = useTranslation('document');

    if (MainActionComponent) {
        if (typeof MainActionComponent === 'function') {
            return React.createElement(MainActionComponent);
        }
        return MainActionComponent;
    }

    // Signing
    return (
        <LoadingButton
            sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
            startIcon={isSigned ? null : <EditIcon />}
            endIcon={isSigned ? <ArrowForwardIcon /> : null}
            variant="contained"
            color={isSigned ? 'buttonSuccess' : 'secondary'}
            size="small"
            loading={isSigning || isLoadingNextStep || !documentId}
            loadingPosition="start"
            onClick={isSigned ? onNextClick : onSignClick}
            fullWidth
        >
            {isSigned ? t('signing.next') : t('signing.signButton')}
        </LoadingButton>

    );
};

DocumentSigningToolbar.defaultProps = {
    MainActionComponent: null,
};

DocumentSigningToolbar.propTypes = {
    MainActionComponent: PropTypes.any,
    onNextClick: PropTypes.func.isRequired,
    onSignClick: PropTypes.func.isRequired,
    isSigning: PropTypes.bool.isRequired,
    isLoadingNextStep: PropTypes.bool.isRequired,
    documentId: PropTypes.string.isRequired,
    isSigned: PropTypes.bool.isRequired,
};

export default DocumentSigningToolbar;
