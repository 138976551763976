export const InsuranceActionTypes = {
    GET_INSURANCE: 'insurance/GET_INSURANCE',
    SET_INSURANCE: 'insurance/SET_INSURANCE',
    STORE_INSURANCE: 'insurance/STORE_INSURANCE',
    SKIP_INSURANCE: 'insurance/SKIP_INSURANCE',
    FINALIZE_INSURANCE: 'insurance/FINALIZE_INSURANCE',
    GENERATE_INSURANCE_DOCUMENT: 'insurance/GENERATE_INSURANCE_DOCUMENT',
    LOAD_INSURANCE_DOCUMENT: 'insurance/LOAD_INSURANCE_DOCUMENT',
    SIGN_INSURANCE_POST_ONBOARDING: 'insurance/SIGN_INSURANCE_POST_ONBOARDING',
};
