import {createSelector} from 'reselect';
import {VatDeclarationStatus} from '../constants';

const selectDeclarations = state => state.vatDeclaration.declarations;
const createDeclarationsSelector = () => createSelector([selectDeclarations], declarations => {
    if (!declarations?.count) {
        return {data: [], count: 0};
    }
    return {data: declarations.items, count: Number(declarations.count)};
});

const selectTotalEligibleToDeclare = createSelector([selectDeclarations], declarations => {
    return declarations?.totalEligibleToDeclare ?? 0;
});

const selectDeclarationHistory = state => state.vatDeclaration.declarationHistory;
const createDeclarationHistorySelector = () => createSelector([selectDeclarationHistory], declarations => {
    if (!declarations?.count) {
        return {data: [], count: 0};
    }
    return {data: declarations.items, count: Number(declarations.count)};
});


const selectStatusFilterCount = state => {
    const filterCount = state.vatDeclaration.declarations?.filterCount || {};
    return {
        [VatDeclarationStatus.TODO]: filterCount.todo,
        [VatDeclarationStatus.SUBMITTED]: filterCount.submitted,
        [VatDeclarationStatus.PENDING_VERIFICATION]: filterCount.pendingVerification,
        [VatDeclarationStatus.OVERDUE]: filterCount.overdue,
        [VatDeclarationStatus.REFUSED]: filterCount.refused,
        [VatDeclarationStatus.VALIDATED]: filterCount.validated,
        [VatDeclarationStatus.ERROR]: filterCount.error,
    };
};

const selectIsLoadingDeclarations = state => state.vatDeclaration.isLoadingDeclarations;
const selectBatchProgress = state => state.vatDeclaration.batchProgress;
const selectProcessedDeclarationsCount = state => {
    const {submitted = 0, pendingVerification = 0, refused = 0, error = 0} = state.vatDeclaration.batchProgress;
    return submitted + pendingVerification + refused + error;
};
const selectVATSupportedMonths = state => state.vatDeclaration.supportedMonths;
const selectVATDeclaration = state => state.vatDeclaration.vatDeclarationDetails;

const selectIsManualMode = createSelector([selectVATDeclaration], declaration => declaration?.isManual);
const selectIsEditingDeclaration = state => state.vatDeclaration.isEditingDeclaration;
const selectHistoryOverview = state => state.vatDeclaration.historyOverview;
const selectManualValidationCustomError = state => state.vatDeclaration.manualValidationCustomError;

export const VatDeclarationSelectors = {
    selectDeclarations,
    createDeclarationsSelector,
    selectTotalEligibleToDeclare,
    selectStatusFilterCount,
    selectIsLoadingDeclarations,
    selectVATSupportedMonths,
    selectVATDeclaration,
    selectBatchProgress,
    selectProcessedDeclarationsCount,
    selectIsManualMode,
    selectIsEditingDeclaration,
    selectHistoryOverview,
    createDeclarationHistorySelector,
    selectManualValidationCustomError,
};

