import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsDownloadInProgress} from '../../../../../v1/app/expenses/adminExpenses/adminExpenses.selectors';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {BankSelector} from '../../../store/bank.selector';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {RibAndDocumentsActions} from '../store/ribAndDocuments.actions';
import {RibAndDocumentsSelector} from '../store/ribAndDocuments.selector';
import {getActiveHiwayIntegration} from '../utils/ribAndDocuments.util';

const DownloadAllAsZipButton = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const dispatch = useDispatch();

    const integrations = useSelector(BankSelector.selectIntegrations);
    const isDownloadInProgress = useSelector(selectIsDownloadInProgress);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.DOWNLOAD_ALL_STATEMENTS_AS_ZIP),
    );

    const hiwayIntegration = getActiveHiwayIntegration(integrations);
    const {account, bankAccountHolder} = getIntegrationInfo(hiwayIntegration);
    const bankAccountHolderId = bankAccountHolder?.id;
    const bankAccountId = account?.id;

    const bankAccountStatements = useSelector(RibAndDocumentsSelector.selectBankAccountStatements);
    const noBankAccountStatements = !bankAccountStatements?.count;

    const downloadAllAsZip = () => {
        dispatch(RibAndDocumentsActions.downloadAllStatementsAsZip({bankAccountId, bankAccountHolderId}));
    };

    return (
        <Button
            variant="solid"
            color="primary"
            disabled={isDownloadInProgress || noBankAccountStatements}
            onClick={downloadAllAsZip}
            startDecorator={<FontAwesomeIcon icon={faDownload} />}
            loading={!!isLoading}
            loadingPosition="start"
            size="sm"
            sx={{
                mt: {
                    mobile: 2,
                    desktop: 0,
                },
                width: {
                    mobile: '100%',
                    desktop: 'auto',
                },
            }}
        >
            {t('accountStatementsTable.downloadAsZip')}
        </Button>
    );
};

export default DownloadAllAsZipButton;
