import {USER_ROLES} from '../../../utils/user-roles';

export const USER_STATUSES = {
    INVITED: 'INVITED',
    CONTRACT_PENDING: 'CONTRACT_PENDING',
    COMPANY_PENDING: 'COMPANY_PENDING',
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    WORKSHOPS_SCHEDULED: 'WORKSHOPS_SCHEDULED',
    CONTRACT_SIGNED: 'CONTRACT_SIGNED',
};

export const USER_TABS = {
    ACCOUNT: {
        value: 'account',
        isVisibleByPayrollAdmin: true,
    },
    MANAGED_FREELANCERS: {
        value: 'managed-freelancers',
        requiredRoles: [USER_ROLES.ADMINISTRATOR],
    },
    COMPANIES: {
        value: 'companies',
        requiredRoles: [USER_ROLES.FREELANCER, USER_ROLES.CARE],
    },
    COACHING: {
        value: 'coaching',
        requiredRoles: [USER_ROLES.FREELANCER, USER_ROLES.CARE],
    },
};
