import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack, Typography} from '@mui/joy';
import {useEffect, useMemo, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ManualInputField} from './ManualInputField';
import {ValidateManualConfirmationModal} from './ValidateManualConfirmationModal';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {VATDeclarationActions} from '../../store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {VatDeclarationSection} from '../VatDeclarationSection';

const FIELDS = [
    {name: 'vatCollectedPayable20', tooltip: '44571700'},
    {name: 'vatCollectedNotPayable20', tooltip: '44571000'},
    {name: 'vatCollectedPayable85', tooltip: '44574700'},
    {name: 'vatCollectedNotPayable85', tooltip: '44574000'},
    {name: 'deductibleVatOnOtherGoodsServices', tooltip: '44566000'},
    {name: 'vatDeductibleOnCollections', tooltip: '44564000'},
    {name: 'vatDeductibleOnFixedAssets', tooltip: '44562000'},
    {name: 'intraCommunityServicePurchases', tooltip: '44566200'},
    {name: 'intraCommunityGoodsAcquisitions', tooltip: '44566210'},
    {name: 'vatDueIntraCommunity', tooltip: '44520000'},
    {name: 'previouslyVatCreditToCarryForward', tooltip: '44567000'},
    {name: 'vatCreditToCarryForward', tooltip: '44567000', decimalScale: 2},
    {name: 'vatPayment', tooltip: '44551000', decimalScale: 0},
    {name: 'variousCurrentManagementExpenses', tooltip: '65800000'},
    {name: 'variousCurrentManagementIncome', tooltip: '75800000'},
];

const Errors = {
    MUST_BE_EXCLUSIVE: 'MUST_BE_EXCLUSIVE',
    CANT_HAVE_BOTH_CREDIT_AND_DEBIT: 'CANT_HAVE_BOTH_CREDIT_AND_DEBIT',
    MUST_BE_IN_CENTS: 'MUST_BE_IN_CENTS',
    MUST_BE_WHOLE_NUMBER: 'MUST_BE_WHOLE_NUMBER',
    MUST_HAVE_DOCUMENT: 'MUST_HAVE_DOCUMENT',
    CANT_BE_NEGATIVE: 'CANT_BE_NEGATIVE',
};

const hasAnyValue = value => Number(value.credit) > 0 || Number(value.debit) > 0;

const getErrors = (values, documents) => {
    const errors = {};

    const exclusiveFieldGroups = [
        ['vatCreditToCarryForward', 'vatPayment'],
        ['variousCurrentManagementExpenses', 'variousCurrentManagementIncome'],
    ];

    exclusiveFieldGroups.forEach(group => {
        if (group.every(fieldName => hasAnyValue(values[fieldName]))) {
            group.forEach(fieldName => {
                errors[fieldName] = Errors.MUST_BE_EXCLUSIVE;
            });
        }
    });


    Object.entries(values).forEach(([fieldName, value]) => {
        if (Number(value.credit) > 0 && Number(value.debit) > 0) {
            errors[fieldName] = Errors.CANT_HAVE_BOTH_CREDIT_AND_DEBIT;
        }

        if (Number(value.debit) < 0 || Number(value.debit) < 0) {
            errors[fieldName] = Errors.CANT_BE_NEGATIVE;
        }
    });

    const centsOnlyFields = ['variousCurrentManagementExpenses', 'variousCurrentManagementIncome'];
    centsOnlyFields.forEach(fieldName => {
        if (Number(values[fieldName].credit) >= 1 || Number(values[fieldName].debit) >= 1) {
            errors[fieldName] = Errors.MUST_BE_IN_CENTS;
        }
    });

    const wholeNumberFields = ['vatCreditToCarryForward', 'vatPayment'];
    wholeNumberFields.forEach(fieldName => {
        if (values[fieldName].credit.match(/\./) || values[fieldName].debit.match(/\./)) {
            errors[fieldName] = Errors.MUST_BE_WHOLE_NUMBER;
        }
    });

    if (!documents.length) {
        errors.form = Errors.MUST_HAVE_DOCUMENT;
    }

    return errors;
};

export const InputFieldsSection = () => {
    const {t} = useTranslation('vatDeclaration');
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const {documents} = declaration;
    const dispatch = useDispatch();
    const isSubmitting = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_VALIDATE_MANUAL),
    );
    const isDocumentsLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_DOCUMENTS),
    );

    const [isValid, setIsValid] = useState(false);

    const defaultValues = useMemo(
        () => FIELDS.reduce(
            (acc, field) => ({
                ...acc,
                [field.name]: {
                    debit: declaration?.fecLinesDetails?.[field.name]?.debit ?? '0',
                    credit: declaration?.fecLinesDetails?.[field.name]?.credit ?? '0',
                },
            }),
            {},
        ),
        [declaration],
    );

    const methods = useForm({defaultValues});
    const values = useWatch({control: methods.control});
    const {setError, clearErrors} = methods;

    useEffect(() => {
        const errors = getErrors(values, documents);

        setIsValid(Object.keys(errors).length === 0);
        Object.keys(values).forEach(key => {
            if (errors[key]) {
                setError(key, errors[key]);
            } else {
                clearErrors(key);
            }
        });
    }, [values, documents, setError, clearErrors]);

    const handleSubmit = values => {
        const data = {};
        Object.entries(values).forEach(([fieldName, value]) => {
            data[fieldName] = {
                debit: value.debit || '0',
                credit: value.credit || '0',
            };
        });
        dispatch(VATDeclarationActions.validateManualMode({declarationId: declaration.id, data}));
    };

    const handleValidateClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_VALIDATE_CONFIRMATION, true));
    };

    return (
        <VatDeclarationSection title={t('accountingData')} isRequired sx={{mb: 20}}>
            <ValidateManualConfirmationModal onConfirm={methods.handleSubmit(handleSubmit)} />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Stack direction="row" spacing={2} mb={1}>
                        <Box width="350px" />
                        <Typography fontSize="sm" textColor="text.primary" flex={1}>
                            {t('manualModeDebit')}
                        </Typography>
                        <Typography fontSize="sm" textColor="text.primary" flex={1}>
                            {t('manualModeCredit')}
                        </Typography>
                    </Stack>
                    <Stack
                        spacing={2}
                        divider={<Box sx={{borderBottom: '1px dashed', borderBottomColor: 'neutral.outlinedBorder'}} />}
                    >
                        {FIELDS.map(field => (
                            <ManualInputField
                                key={field.name}
                                label={t(`manualModeFields.${field.name}`)}
                                isDisabled={isSubmitting}
                                {...field}
                            />
                        ))}
                    </Stack>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            borderRadius: 'lg',
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            boxShadow: 'lg',
                            p: 2,
                            bgcolor: 'background.surface',
                            display: 'flex',
                            alignItems: 'center',
                            mr: 0.5,
                            width: '600px',
                        }}
                    >
                        <Button
                            onClick={handleValidateClick}
                            variant="solid"
                            color="primary"
                            size="sm"
                            disabled={!isValid || isDocumentsLoading}
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                            loading={isSubmitting}
                            loadingPosition="end"
                            fullWidth
                        >
                            {t('validateDeclaration')}
                        </Button>
                    </Box>
                </form>
            </FormProvider>
        </VatDeclarationSection>
    );
};
