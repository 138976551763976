import {
    faBank,
    faCircleInfo,
    faCloudArrowUp,
    faCopy,
    faDownload,
    faFileLines,
    faRepeat,
    // faSync,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Chip, IconButton, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AccountBalanceTotal} from './account-balance/AccountBalanceTotal';
import {AlertWarning} from '../../../../../components/joy-ui/alerts/AlertWarning';
import {push} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {copyToClipboard} from '../../../../../utils/copy-to-clipboard';
import {USER_ROLES} from '../../../../../utils/user-roles';
import {selectCompany} from '../../../../../v1/app/company/company.selectors';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import ArchivingSidebarActions from '../../../components/archive/ArchivingSidebarActions';
import {BankActions} from '../../../store/bank.action';
import {BridgeActions} from '../../bridge-api/store/bridge-api.action';
import {BANK_TYPES, BankIntegrationStatus} from '../../bridge-api/utils/constants';

const BankDetails = ({isMobileSize, integration}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const isHiway = integration?.type === BANK_TYPES.hiway;

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserAdmin = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;
    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.DOWNLOAD_DOCUMENT,
    ));
    // For admin user
    const freelancerCompany = useSelector(selectCompany);

    const downloadRib = () => {
        dispatch(BankActions.downloadRib({
            freelancerId: isUserAdmin
                ? freelancerCompany?.userId
                : loggedInUser.id,
            companyId: isUserAdmin
                ? freelancerCompany?.id
                : loggedInUser.defaultCompanyId,
            integration,
        }));
    };

    const viewRibDocumentPage = () => {
        dispatch(push(RoutePaths.BANK_RIB_AND_DOCUMENTS));
    };

    const fixAccount = () => {
        dispatch(BridgeActions.fixBridgeAccount({status: integration?.status, id: integration?.id}));
    };

    const hasError = integration?.status === BankIntegrationStatus.COMPANY_VALIDATION_FORM_REQUIRED
        || integration?.status === BankIntegrationStatus.MULTI_FACTOR_AUTHENTICATION_REQUIRED
        || integration?.status === BankIntegrationStatus.USER_ACTION_REQUIRED
        || integration?.status === BankIntegrationStatus.ERROR
        || integration?.status === BankIntegrationStatus.IN_PROGRESS;

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            sx={{
                height: isMobileSize ? 'calc(100% - 64px)' : '100vh',
            }}
        >
            <Stack direction="column">
                {!isMobileSize && (
                    <Stack
                        direction="column"
                        gap={0.5}
                        sx={{
                            px: 3,
                            py: 2,
                            backgroundColor: 'background.surface',
                        }}
                    >
                        <Typography level="title-sm" sx={{color: 'text.tertiary'}}>
                            {integration?.name ?? t('accountSettingsSidebar.hiwayPro')}
                        </Typography>
                        <Typography level="title-lg" sx={{color: 'text.primary'}}>
                            {t('accountBalance.parameters')}
                        </Typography>
                        <Typography level="body-xs" sx={{color: 'text.tertiary'}}>
                            {t('accountSettingsSidebar.subtitle')}
                        </Typography>
                    </Stack>
                )}
                <Stack
                    direction="column"
                    gap={3}
                    sx={{px: 2, py: 3}}
                >
                    <Stack
                        direction="column"
                        borderRadius="md"
                        gap={2}
                        sx={{
                            p: 2,
                            border: '1px solid',
                            borderColor: 'neutral.outlinedBorder',
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems="center"
                            gap={0.5}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                            >
                                <Box fontSize="sm" sx={{color: 'text.tertiary'}}>
                                    <FontAwesomeIcon icon={faBank} fixedWidth />
                                </Box>
                                <Typography level="title-md" sx={{color: 'text.primary'}}>
                                    {integration?.name ?? t('accountSettingsSidebar.hiwayPro')}
                                </Typography>
                            </Stack>
                            <AccountBalanceTotal amount={integration?.amount} isInSidebar />
                        </Stack>

                        {!isHiway && hasError && (
                            <AlertWarning
                                title={t('accountSettingsSidebar.alertTitle')}
                            >
                                <Stack
                                    direction="column"
                                    gap={1}
                                >
                                    <Typography
                                        level="body-sm"
                                        sx={{
                                            color: 'warning.softColor',
                                        }}
                                    >
                                        {t('accountSettingsSidebar.alertText1')}
                                    </Typography>
                                    <Typography
                                        level="body-sm"
                                        sx={{
                                            color: 'warning.softColor',
                                        }}
                                    >
                                        {t('accountSettingsSidebar.alertText2')}
                                    </Typography>
                                    <Typography
                                        level="body-sm"
                                        sx={{
                                            color: 'warning.softColor',
                                        }}
                                    >
                                        {t('accountSettingsSidebar.alertText3')}
                                    </Typography>
                                </Stack>
                            </AlertWarning>
                        )}

                        {isHiway && (
                            <Chip
                                variant="soft"
                                size="lg"
                                color="success"
                                startDecorator={(
                                    <FontAwesomeIcon icon={faCloudArrowUp} />
                                )}
                                fullWidth
                                sx={{
                                    'maxWidth': '100%',
                                    'alignSelf': 'stretch',
                                    'px': 2,
                                    'gap': 0.75,
                                    '& > .MuiChip-label': {
                                        flexGrow: 0,
                                    },
                                }}
                            >
                                {t('accountSettingsSidebar.instantSync')}
                            </Chip>
                        )}

                        {/* TODO: && (
                            <Chip
                                variant="soft"
                                size="md"
                                color="neutral"
                                endDecorator={(
                                    <FontAwesomeIcon icon={faSync} />
                                )}
                                fullWidth
                                sx={{
                                    'maxWidth': '100%',
                                    'alignSelf': 'stretch',
                                    'px': 2,
                                    'gap': 0.75,
                                    '& > .MuiChip-label': {
                                        flexGrow: 0,
                                    },
                                }}
                            >
                                {t('accountSettingsSidebar.instantSync')}
                            </Chip>
                        ) */}
                    </Stack>

                    <Stack
                        direction="column"
                        gap={2}
                    >
                        <Stack
                            direction="row"
                            gap={1}
                            alignItems="center"
                            sx={{mt: 1}}
                        >
                            <Typography level="h4" sx={{color: 'text.secondary'}}>
                                {t('accountSettingsSidebar.info')}
                            </Typography>
                            <Box fontSize="sm" color="text.icon">
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </Box>
                        </Stack>
                        <AccountCard
                            name={t('accountSettingsSidebar.IBAN')}
                            value={integration?.iban}
                        />
                        <AccountCard
                            name={t('accountSettingsSidebar.BIC')}
                            value={integration?.bic}
                        />

                        {isHiway && (
                            <Stack
                                direction="column"
                                gap={2}
                                sx={{
                                    mb: 1,
                                }}
                            >
                                <Button
                                    startDecorator={(
                                        <FontAwesomeIcon icon={faDownload} />
                                    )}
                                    loading={isLoading}
                                    loadingPosition="start"
                                    size="lg"
                                    color="primary"
                                    variant="solid"
                                    fullWidth
                                    onClick={downloadRib}
                                >
                                    {t('accountSettingsSidebar.downloadButton')}
                                </Button>

                                {!isUserAdmin && (
                                    <Button
                                        startDecorator={(
                                            <FontAwesomeIcon icon={faFileLines} />
                                        )}
                                        loading={isLoading}
                                        loadingPosition="start"
                                        size="lg"
                                        color="neutral"
                                        variant="outlined"
                                        fullWidth
                                        onClick={viewRibDocumentPage}
                                    >
                                        {t('accountSettingsSidebar.goToRibPageButton')}
                                    </Button>
                                )}
                            </Stack>
                        )}

                        {!isHiway && hasError && (
                            <Button
                                startDecorator={(
                                    <FontAwesomeIcon icon={faRepeat} />
                                )}
                                size="lg"
                                color="primary"
                                variant="solid"
                                fullWidth
                                onClick={fixAccount}
                            >
                                {t('accountSettingsSidebar.reConnect')}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </Stack>

            <ArchivingSidebarActions
                isHiway={isHiway}
                integration={integration}
            />
        </Stack>
    );
};

BankDetails.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

const AccountCard = ({name, value}) => {
    return (
        <Stack
            direction="column"
            gap={1}
            sx={{
                p: 1,
                pl: 2,
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                borderRadius: 'md',
            }}
        >
            <Stack
                direction="row"
                gap={1}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    level="title-sm"
                    sx={{color: 'text.tertiary'}}
                >
                    {name}
                </Typography>
                {value && (
                    <IconButton
                        color="neutral"
                        variant="plain"
                        size="md"
                        onClick={() => {
                            copyToClipboard(value);
                            Toast.success('copiedToClipboard');
                        }}
                    >
                        <Box fontSize="xl">
                            <FontAwesomeIcon icon={faCopy} />
                        </Box>
                    </IconButton>
                )}
            </Stack>

            <Typography level="h4" sx={{color: 'text.secondary'}}>
                {value}
            </Typography>
        </Stack>
    );
};

AccountCard.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
};

AccountCard.defaultProps = {
    value: '',
};

export default BankDetails;
