import {call, fork, put, select} from 'redux-saga/effects';

import {setIsLoadingApp} from './app.actions';
import {selectIsLoadingApp} from './app.selectors';
import {getCompany} from './company/company.actions';
import {loadFreelancerAccountSaga} from './freelancer/freelancer.sagas';
import {selectFreelancerCompanies} from './freelancer/freelancer.selectors';
import {getUser} from './user/user.actions';
import {USER_STATUSES} from './user/user.constants';
import {selectCurrentCognitoUser} from '../../features/auth/store/auth.selectors';
import {getAndStoreCurrentUserInfo} from '../../features/auth/store/getAndStoreCurrentUserInfo';
import {SettingsActions} from '../../features/settings/store/settings.actions';
import {SettingsSelectors} from '../../features/settings/store/settings.selectors';
import {TermsApi} from '../../features/terms/api/terms.api';
import {UiActions} from '../../features/ui/store/ui.action';
import {ModalsKeys} from '../../features/ui/utils/constants';
import {LoggedInUserSelectors} from '../../features/user/modules/logged-in-user';
import {USER_ROLES, isUserAdmin} from '../../utils/user-roles';

const loadUserSettings = function* () {
    const settings = yield select(SettingsSelectors.selectSettings);

    if (!settings || typeof settings.shouldReceiveOpportunityNotifications === 'undefined') {
        yield put(SettingsActions.getUserSettings());
    } else {
        yield put(SettingsActions.setIsLoadingUserSettings(false));
    }
};

const loadAdditionalDataForAdministrators = function* (freelancerId, companyId) {
    if (freelancerId) {
        yield put(getUser(freelancerId));

        if (companyId) {
            yield put(getCompany(freelancerId, companyId));
        }
    }

    const isLoadingApp = yield select(selectIsLoadingApp);

    if (isLoadingApp) {
        yield put(setIsLoadingApp(false));
    }
};

const checkRequiredConsents = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    if (
        !isUserAdmin(loggedInUser)
        && loggedInUser.status !== USER_STATUSES.CONTRACT_PENDING
        && loggedInUser.status !== USER_STATUSES.INVITED
    ) {
        const response = yield call(TermsApi.getRequiredConsents, loggedInUser.id);
        const cgvsItem = response?.find?.(item => item.type === 'CGVS');
        if (cgvsItem) {
            yield put(UiActions.setModalData(ModalsKeys.UPDATED_TERMS_CONSENT_MODAL, cgvsItem));
            yield put(UiActions.setActiveModal(ModalsKeys.UPDATED_TERMS_CONSENT_MODAL, true));
        }
    }
};

export const appLoaderSaga = function* ({payload}) {
    const {
        params: {freelancerId, userId, companyId},
    } = payload;
    let currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (!currentCognitoUser) {
        yield call(getAndStoreCurrentUserInfo);
    }

    currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(loadUserSettings);
    yield fork(checkRequiredConsents);

    if (currentCognitoUser && currentCognitoUser.role !== USER_ROLES.FREELANCER) {
        yield call(loadAdditionalDataForAdministrators, freelancerId || userId, companyId);

        return;
    }

    yield call(loadFreelancerAccountSaga, currentCognitoUser.id);

    const companies = yield select(selectFreelancerCompanies);

    if (!companies || !Object.keys(companies).length) {
        yield put(setIsLoadingApp(false));

        return;
    }

    yield put(setIsLoadingApp(false));
};
