import {InvoiceDto} from './invoice.dto';
import {frApiInstance} from '../../../lib/fr-api-instance';
import {invoiceApiInstance} from '../../../lib/invoice-api-instance';
import {safeTransformData} from '../../../v1/app/api/axios';

const getInvoiceList = ({companyId, params}) => {
    return invoiceApiInstance({
        method: 'GET',
        params,
        url: `/v1/companies/${companyId}/invoices`,
    }).then(result => result?.data);
};

const getInvoiceStats = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/invoices/stats`,
    }).then(result => result?.data);
};

const getInvoiceStatsIndicators = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/invoices/stats/indicators`,
    }).then(result => result?.data);
};

const getFinancialData = ({freelancerId, year}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/stats/financial-data`,
        params: {year},
    }).then(response => safeTransformData(response));
};

const getInvoiceDraft = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/invoices/draft`,
    }).then(result => result?.data);
};

const getInvoiceDetails = ({companyId, id}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/invoices/${id}`,
    }).then(result => result?.data);
};

const finalizeInvoice = ({data, companyId, id}) => {
    return invoiceApiInstance({
        method: 'POST',
        data: data,
        url: `/v1/companies/${companyId}/invoices/${id}/finalize`,
    }).then(result => result?.data);
};

const getInvoicePreview = ({data, companyId, id}) => {
    return invoiceApiInstance({
        method: 'POST',
        data: data,
        url: `/v1/companies/${companyId}/invoices/${id}/generate-preview`,
    }).then(result => result?.data);
};

const deleteInvoice = ({id, companyId}) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/companies/${companyId}/invoices/${id}`,
    }).then(result => result?.data);
};

const createExternalInvoice = ({
    companyId,
    invoiceDocument,
    name,
    countryCode,
    vatType,
    docNumber,
    date,
    dueDate,
    totalAmount,
    totalFeeAmount,
    feeVatType,
    feePartnerName,
    feePartnerInvoiceDocNumber,
    partnerInvoiceDocument,
}) => {
    const formData = new FormData();

    formData.append('invoiceDocument', invoiceDocument);
    formData.append('name', name);
    formData.append('countryCode', countryCode);
    formData.append('vatType', vatType);
    formData.append('docNumber', docNumber);
    formData.append('date', date);
    formData.append('dueDate', dueDate);
    formData.append('totalAmount', totalAmount);

    if (feePartnerInvoiceDocNumber) {
        formData.append('totalFeeAmount', totalFeeAmount);
        formData.append('feeVatType', feeVatType);
        formData.append('feePartnerName', feePartnerName);
        formData.append('feePartnerInvoiceDocNumber', feePartnerInvoiceDocNumber);
        formData.append('partnerInvoiceDocument', partnerInvoiceDocument);
    }

    return invoiceApiInstance({
        method: 'POST',
        data: formData,
        url: `/v1/companies/${companyId}/invoices/import-external`,
    }).then(result => result?.data);
};

const deleteExternalInvoice = ({id, companyId, file, docNumber, date}) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('docNumber', docNumber);
    formData.append('date', date);

    return invoiceApiInstance({
        method: 'POST',
        data: formData,
        url: `/v1/companies/${companyId}/invoices/${id}/cancel-external`,
    }).then(result => result?.data);
};

const createInvoice = ({data, companyId}) => {
    return invoiceApiInstance({
        method: 'POST',
        data: InvoiceDto.dtoToCreateInvoice(data),
        url: `/v1/companies/${companyId}/invoices`,
    }).then(result => result?.data);
};

const saveInvoice = ({data, companyId, id}) => {
    return invoiceApiInstance({
        method: 'PUT',
        data: InvoiceDto.dtoToSaveInvoice(data),
        url: `/v1/companies/${companyId}/invoices/${id}`,
    }).then(result => result?.data);
};

const saveInvoiceClientFiles = ({fileList, companyId, id}) => {
    const formData = new FormData();

    fileList.forEach(file => {
        formData.append('files', file);
    });

    return invoiceApiInstance({
        method: 'POST',
        data: formData,
        url: `/v1/companies/${companyId}/clients/${id}/documents/upload`,
    }).then(result => result?.data);
};

const saveAnnexFile = ({fileList, companyId, invoiceId}) => {
    const formData = new FormData();

    fileList.forEach(file => {
        formData.append('files', file);
    });

    return invoiceApiInstance({
        method: 'POST',
        data: formData,
        url: `/v1/companies/${companyId}/invoices/${invoiceId}/documents/upload`,
    }).then(result => result?.data);
};

const deleteAnnexFile = ({invoiceId, fileId, companyId}) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/companies/${companyId}/invoices/${invoiceId}/documents/${fileId}`,
    }).then(result => result?.data);
};

const deleteInvoiceClientFile = ({documentId, companyId, id}) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/companies/${companyId}/clients/${id}/documents/${documentId}`,
    }).then(result => result?.data);
};

const getInvoiceClients = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/clients`,
    }).then(result => result?.data);
};

const getInvoiceClient = ({companyId, id}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/clients/${id}`,
    }).then(result => result?.data);
};

const saveInvoiceClient = ({data, companyId}) => {
    return invoiceApiInstance({
        method: 'POST',
        data: data,
        url: `/v1/companies/${companyId}/clients`,
    }).then(result => result?.data);
};

const updateInvoiceClient = ({data, id, companyId}) => {
    return invoiceApiInstance({
        method: 'PUT',
        data: InvoiceDto.dtoToClientUpdate(data),
        url: `/v1/companies/${companyId}/clients/${id}`,
    }).then(result => result?.data);
};

const getInvoiceServices = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/services`,
    }).then(result => result?.data);
};

const saveInvoiceService = ({companyId, data}) => {
    return invoiceApiInstance({
        method: 'POST',
        data: InvoiceDto.dtoToInvoiceServiceUpdate(data),
        url: `/v1/companies/${companyId}/services`,
    }).then(result => result?.data);
};

const updateInvoiceService = ({companyId, data, id}) => {
    return invoiceApiInstance({
        method: 'PUT',
        data: InvoiceDto.dtoToInvoiceServiceUpdate(data),
        url: `/v1/companies/${companyId}/services/${id}`,
    }).then(result => result?.data);
};

const getServiceDetails = ({companyId, id}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/services/${id}`,
    }).then(result => result?.data);
};

const deleteService = ({companyId, id}) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/companies/${companyId}/services/${id}`,
    }).then(result => result?.data);
};

const deleteClient = ({companyId, id}) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/companies/${companyId}/clients/${id}`,
    }).then(result => result?.data);
};

const importInvoice = ({sourceInvoiceId, companyId, id}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/invoices/${id}/import-from?sourceInvoiceId=${sourceInvoiceId}`,
    }).then(result => result?.data);
};

const sendReminder = ({id, companyId, emails, subject, message}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/invoices/${id}/send-reminder`,
        data: {
            replyTo: emails,
            subject,
            body: message,
        },
    }).then(result => result?.data);
};

const sendInvoice = ({id, companyId}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/invoices/${id}/send`,
    }).then(result => result?.data);
};

const reCreateInvoice = ({id, companyId}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/invoices/${id}/re-create`,
    }).then(result => result?.data);
};

const getClientSuggestions = ({clientNamePartial}) => {
    const encodedClientNamePartial = encodeURIComponent(clientNamePartial);
    return frApiInstance({
        method: 'GET',
        url: `/search?q=${encodedClientNamePartial}&minimal=true&include=siege&limite_matching_etablissements=25&page=1&per_page=25`,
    }).then(result => result?.data?.results);
};

const getNextCreditNoteDocNumber = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/invoices/next-credit-note-docnumber`,
    }).then(result => result?.data);
};

const getLastExternalInvoices = ({companyId}) => {
    return invoiceApiInstance
        .get(`/v1/companies/${companyId}/invoices?limit=5&filterQuery=%7B%22isExternal%22:true,%22type%22:%22INVOICE%22%7D&sortBy=createdAt&sortOrder=DESC`)
        .then(result => result?.data);
};

const getAriaInfo = ({freelancerId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/integrations/aria/integration-info`,
    }).then(result => result?.data);
};

const activateAria = ({fileList, idDocType, freelancerId}) => {
    const documentsData = {};

    if (fileList?.length) {
        const formData = new FormData();

        fileList.forEach(file => {
            formData.append('files', file);
            formData.append('idDocType', idDocType);
        });

        documentsData.data = formData;
    }

    return invoiceApiInstance({
        method: 'POST',
        ...documentsData,
        url: `/v1/freelancers/${freelancerId}/integrations/aria/send-contract`,
    }).then(result => result?.data);
};

const getLendingEligibility = ({id, companyId, debtorIdentifier}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/clients/${id}/lending-eligibility`,
        data: {
            debtorIdentifier,
        },
    }).then(result => result?.data);
};

export const InvoiceApi = {
    getLastExternalInvoices,
    getInvoiceList,
    getInvoiceStats,
    getInvoiceDraft,
    getInvoiceDetails,
    finalizeInvoice,
    getInvoicePreview,
    deleteInvoice,
    createExternalInvoice,
    deleteExternalInvoice,
    deleteAnnexFile,
    createInvoice,
    saveInvoiceClientFiles,
    deleteInvoiceClientFile,
    getInvoiceClients,
    getInvoiceClient,
    saveAnnexFile,
    deleteClient,
    saveInvoice,
    saveInvoiceClient,
    updateInvoiceClient,
    getInvoiceServices,
    saveInvoiceService,
    updateInvoiceService,
    getServiceDetails,
    deleteService,
    importInvoice,
    sendReminder,
    sendInvoice,
    reCreateInvoice,
    getClientSuggestions,
    getNextCreditNoteDocNumber,
    getAriaInfo,
    activateAria,
    getLendingEligibility,
    getInvoiceStatsIndicators,
    getFinancialData,
};
