import {faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import JoyUIThemeProvider from '../../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {capitalizeFirstLetter} from '../../../../../dashboard-v3/utils/constants';

const StatementsName = ({rowData}) => {
    return (
        <JoyUIThemeProvider>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    py: 1,
                    textOverflow: 'ellipsis',
                }}
            >
                <Typography textColor="text.primary">
                    <FontAwesomeIcon icon={faFilePdf} />
                </Typography>
                <Typography sx={{ml: 1}} level="body-md" fontWeight={700}>
                    {capitalizeFirstLetter(rowData.statementName)}
                </Typography>
            </Box>
        </JoyUIThemeProvider>
    );
};

StatementsName.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default StatementsName;
