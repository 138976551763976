import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Input, Stack, Tooltip, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useController, useFormState} from 'react-hook-form';
import {StandardNumberField} from '../../../../components/input/StandardNumberField';


export const ManualInputField = ({name, label, isDisabled, tooltip, decimalScale}) => {
    const {field: debitField} = useController({name: `${name}.debit`});
    const {field: creditField} = useController({name: `${name}.credit`});
    const {errors} = useFormState();

    const commonProps = {
        allowNegative: false,
        thousandSeparator: ' ',
        customInput: Input,
        color: errors[name] ? 'danger' : 'neutral',
        size: 'md',
        sx: {
            backgroundColor: 'transparent',
            py: 1,
            px: 1.5,
            width: 0,
            flex: 1,
        },
        areLeadingZerosAllowed: false,
        decimalScale,
        disabled: isDisabled,
    };

    return (
        <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
            <Typography
                level="body-md"
                textColor="text.secondary"
                sx={{width: '350px', flexShrink: 0, textWrap: 'balance'}}
            >
                {label}{' '}
                {tooltip && (
                    <Tooltip
                        title={tooltip}
                        placement="top"
                        sx={{bgcolor: 'background.surface', color: 'neutral.plainColor'}}
                    >
                        <Box sx={{display: 'inline', color: 'text.tertiary', fontSize: '16px', cursor: 'pointer'}}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </Box>
                    </Tooltip>
                )}
            </Typography>
            <Stack direction="row" spacing={2} flex={1}>
                <StandardNumberField {...commonProps} {...debitField} />
                <StandardNumberField {...commonProps} {...creditField} />
            </Stack>
        </Stack>
    );
};

ManualInputField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    tooltip: PropTypes.string,
    decimalScale: PropTypes.number,
};

ManualInputField.defaultProps = {
    isDisabled: false,
    tooltip: undefined,
    decimalScale: 2,
};
