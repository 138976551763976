import AdapterMoment from '@date-io/moment';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Input,
    List,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    Stack,
    Switch,
    Typography,
} from '@mui/joy';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DrawerWrapper from '../../../../../../components/drawer-wrapper/DrawerWrapper';
import {FormFieldLabel} from '../../../../../../components/joy-ui/forms/FormFieldLabel';
import useMediaQuery from '../../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {DatePicker, LocalizationProvider} from '../../../../../../components/joy-ui/x-date-pickers';
import {FILTER_LIST_MAP} from '../../util/constants';

export const AccountBalanceMobileFilter = ({
    isOpen,
    setIsOpen,
    filter,
    setFilter,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize300 = useMediaQuery(`(max-width:300px)`);

    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);

    const [types, setTypes] = useState(filter.transactionTypes);
    const [hasStatusFilterActive, setHasStatusFilterActive] = useState(filter.isNotCategorized ?? false);

    const onAddPaymentType = value => {
        const transactionTypes = [...types];
        transactionTypes.push(value);
        setTypes(transactionTypes);
    };

    const onRemovePaymentType = value => {
        const transactionTypes = [...types];
        const index = transactionTypes.indexOf(value);
        transactionTypes.splice(index, 1);
        setTypes(transactionTypes);
    };

    const onFilterApply = () => {
        setFilter(filter => {
            return {
                ...filter,
                startDate: startDate,
                endDate: endDate,
                isNotCategorized: hasStatusFilterActive,
                transactionTypes: types,
            };
        });
        setIsOpen(false);
    };

    const onFilterReset = () => {
        setStartDate('');
        setEndDate('');
        setTypes([]);
        setHasStatusFilterActive(false);
    };

    const filterResetToPrevious = () => {
        setStartDate(filter.startDate);
        setEndDate(filter.endDate);
        setTypes(filter.transactionTypes);
        setHasStatusFilterActive(filter.isNotCategorized);
    };

    useEffect(() => {
        if (filter.isNotCategorized) {
            setStartDate('');
            setEndDate('');
            setTypes([]);
            setHasStatusFilterActive(true);
        }
    }, [filter?.isNotCategorized]);

    const handleClose = () => {
        filterResetToPrevious();
        setIsOpen(false);
    };

    return (
        <DrawerWrapper
            isOpen={isOpen}
            onClose={handleClose}
            isV3
            title={t('accountBalanceFilter.title')}
            drawerSx={{
                '--Drawer-verticalSize': 'clamp(100vh - 150px, 60%, 100%)',
            }}
        >
            <Box>
                <Typography
                    level="title-md"
                    sx={{mb: 1, color: 'text.tertiary'}}
                >
                    {t('accountBalanceFilter.datesMobile')}
                </Typography>

                <Stack
                    direction={isMobileSize300 ? 'column' : 'row'}
                    alignItems={isMobileSize300 ? 'flex-start' : 'center'}
                    gap={2}
                >
                    <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={startDate}
                            maxDate={endDate}
                            onChange={value => {
                                setStartDate(value);
                            }}
                            renderInput={({inputProps, ...rest}) => {
                                return (
                                    <FormControl sx={{flex: 1}}>
                                        <FormFieldLabel
                                            label={t('accountBalanceFilter.from')}
                                            color="text.secondary"
                                        />
                                        <Input
                                            {...rest}
                                            {...inputProps}
                                            value={startDate && startDate.format('DD/MM/YYYY')}
                                            fullWidth
                                            placeholder="dd/mm/yyyy"
                                            color="neutral"
                                            sx={{
                                                'backgroundColor': 'background.body',
                                                '& > .MuiInput-input': {
                                                    width: '100%',
                                                },
                                            }}
                                        />
                                    </FormControl>
                                );
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
                        <DatePicker
                            value={endDate}
                            minDate={startDate}
                            onChange={value => {
                                setEndDate(value);
                            }}
                            renderInput={({inputProps, ...rest}) => {
                                return (
                                    <FormControl sx={{flex: 1}}>
                                        <FormFieldLabel
                                            label={t('accountBalanceFilter.to')}
                                            color="text.secondary"
                                        />
                                        <Input
                                            {...rest}
                                            {...inputProps}
                                            value={endDate && endDate.format('DD/MM/YYYY')}
                                            fullWidth
                                            placeholder="dd/mm/yyyy"
                                            color="neutral"
                                            sx={{
                                                'backgroundColor': 'background.body',
                                                '& > .MuiInput-input': {
                                                    width: '100%',
                                                },
                                            }}
                                        />
                                    </FormControl>
                                );
                            }}
                        />
                    </LocalizationProvider>
                </Stack>
            </Box>

            <Box>
                <Typography
                    level="title-md"
                    sx={{mb: 1, color: 'text.tertiary'}}
                >
                    {t('accountBalanceFilter.nonCategorized')}
                </Typography>

                <Typography
                    level="body-md"
                    component="label"
                    sx={{color: 'text.secondary'}}
                    startDecorator={(
                        <Switch
                            color={hasStatusFilterActive ? 'primary' : 'neutral'}
                            variant={hasStatusFilterActive ? 'solid' : 'outlined'}
                            checked={hasStatusFilterActive}
                            onChange={e => {
                                setHasStatusFilterActive(e.target.checked);
                            }}
                            sx={{mr: 1}}
                        />
                    )}
                >
                    {t('accountBalanceFilter.supportingDocumentsNotUploaded')}
                </Typography>
            </Box>

            <Box>
                <Typography
                    level="title-md"
                    sx={{mb: 1, color: 'text.tertiary'}}
                >
                    {t('accountBalanceFilter.types')}
                </Typography>

                <List sx={{py: 0, maxHeight: '60vh', overflow: 'auto'}}>
                    {FILTER_LIST_MAP.map(status => (
                        <ListItem key={status}>
                            <ListItemButton
                                dense
                                onClick={() => {
                                    if (types.indexOf(status) !== -1) {
                                        onRemovePaymentType(status);
                                    } else {
                                        onAddPaymentType(status);
                                    }
                                }}
                            >
                                <ListItemDecorator>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={types.indexOf(status) !== -1}
                                    />
                                </ListItemDecorator>
                                {t(`paymentType.${status}`)}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Divider sx={{my: 2}} />

            <Stack
                direction="row"
                alignItems="center"
                gap={3}
            >
                <Button
                    fullWidth
                    onClick={() => {
                        onFilterReset();
                    }}
                    variant="outlined"
                    size="md"
                    color="neutral"
                >
                    {t('accountBalanceFilter.reset')}
                </Button>

                <Button
                    fullWidth
                    onClick={() => {
                        onFilterApply();
                    }}
                    disabled={(startDate && !endDate) || (!startDate && endDate)}
                    color="primary"
                    size="md"
                    variant="solid"
                >
                    {t(`accountBalanceFilter.apply`)}
                </Button>
            </Stack>
        </DrawerWrapper>
    );
};

AccountBalanceMobileFilter.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};
