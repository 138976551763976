import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Typography} from '@mui/joy';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {EurAmount} from '../../../../components/eur-amount/EurAmount';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys as ModalKeys} from '../../../ui/utils/constants';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';

export const ResendBanner = () => {
    const {t} = useTranslation('vatDeclaration');
    const dispatch = useDispatch();
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);

    const handleClick = () => {
        dispatch(UiActions.setActiveModal(ModalKeys.VAT_DECLARATION_RESEND_DECLARATION, true));
    };

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_RESEND));

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                borderRadius: 'lg',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                boxShadow: 'lg',
                p: 2,
                bgcolor: 'background.surface',
                display: 'flex',
                alignItems: 'center',
                mr: 0.5,
                width: '600px',
            }}
        >
            <Typography level="title-md" textColor="text.secondary">
                {t('amountToBeDeclared')}
            </Typography>
            <Typography level="h2" sx={{marginRight: 'auto', marginLeft: 0.5}}>
                <EurAmount amount={declaration?.totalVatToDeclare || '0.00'} />
            </Typography>
            <Button
                variant="solid"
                color="primary"
                size="sm"
                endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                loading={isLoading}
                loadingPosition="end"
                onClick={handleClick}
            >
                {t('resendDeclaration')}
            </Button>
        </Box>
    );
};
