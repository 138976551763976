export const GET_DECLARATIONS = 'vat-declaration/GET_DECLARATIONS';
export const STORE_DECLARATIONS = 'vat-declaration/STORE_DECLARATIONS';
export const ACTIVATE_MANUAL_MODE = 'vat-declaration/ACTIVATE_MANUAL_MODE';
export const UPLOAD_DOCUMENTS = 'vat-declaration/UPLOAD_DOCUMENTS';
export const DELETE_DOCUMENT = 'vat-declaration/DELETE_DOCUMENT';
export const REPLACE_DOCUMENT = 'vat-declaration/REPLACE_DOCUMENT';

export const GET_CURRENT_BATCH_PROGRESS = 'vat-declaration/GET_BATCH_PROGRESS';
export const STORE_BATCH_PROGRESS = 'vat-declaration/STORE_BATCH_PROGRESS';

export const SEND_SINGLE_DECLARATION = 'vat-declaration/SEND_SINGLE_DECLARATION ';
export const SEND_DECLARATIONS_IN_BATCH = 'vat-declaration/SEND_DECLARATIONS_IN_BATCH';
export const SEND_ALL_DECLARATIONS_IN_BATCH = 'vat-declaration/SEND_ALL_DECLARATIONS_IN_BATCH';
export const GET_VAT_SUPPORTED_MONTHS = 'vat-declaration/GET_VAT_SUPPORTED_MONTHS';
export const STORE_VAT_SUPPORTED_MONTHS = 'vat-declaration/STORE_VAT_SUPPORTED_MONTHS';

export const GET_VAT_DECLARATION = 'vat-declaration/GET_VAT_DECLARATION';
export const STORE_VAT_DECLARATION = 'vat-declaration/STORE_VAT_DECLARATION';

export const GET_VAT_DECLARATION_DOCUMENTS = 'vat-declaration/GET_VAT_DECLARATION_DOCUMENTS';

export const RESEND_DECLARATION = 'vat-declaration/RESEND_DECLARATION';
export const VALIDATE_MANUAL_MODE = 'vat-declaration/VALIDATE_MANUAL_MODE';
export const SET_IS_EDITING_DECLARATION = 'vat-declaration/SET_IS_EDITING_DECLARATION';
export const DOCUMENTS_UPLOADED_SSE = 'vat-declaration/DOCUMENTS_UPLOADED_SSE';
export const DOCUMENT_DELETED_SSE = 'vat-declaration/DOCUMENT_DELETED_SSE';
export const DECLARATION_RESENT_SSE = 'vat-declaration/DECLARATION_RESENT_SSE';

export const DOWNLOAD_DOCUMENT = 'vat-declaration/DOWNLOAD_DOCUMENT';
export const GET_HISTORY_OVERVIEW = 'vat-declaration/GET_HISTORY_OVERVIEW';
export const STORE_HISTORY_OVERVIEW = 'vat-declaration/STORE_HISTORY_OVERVIEW';
export const GET_DECLARATION_HISTORY = 'vat-declaration/GET_DECLARATION_HISTORY';
export const STORE_DECLARATION_HISTORY = 'vat-declaration/STORE_DECLARATION_HISTORY';
export const DOWNLOAD_DECLARATION_DOCUMENTS = 'vat-declaration/DOWNLOAD_DECLARATION_DOCUMENTS';
export const STORE_MANUAL_VALIDATION_CUSTOM_ERROR = 'vat-declaration/STORE_MANUAL_VALIDATION_CUSTOM_ERROR';
