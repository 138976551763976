import {
    ACTIVATE_MANUAL_MODE,
    DECLARATION_RESENT_SSE,
    DELETE_DOCUMENT,
    DOCUMENTS_UPLOADED_SSE,
    DOCUMENT_DELETED_SSE,
    DOWNLOAD_DECLARATION_DOCUMENTS,
    DOWNLOAD_DOCUMENT,
    GET_CURRENT_BATCH_PROGRESS,
    GET_DECLARATIONS,
    GET_DECLARATION_HISTORY,
    GET_HISTORY_OVERVIEW,
    GET_VAT_DECLARATION,
    GET_VAT_DECLARATION_DOCUMENTS,
    GET_VAT_SUPPORTED_MONTHS,
    REPLACE_DOCUMENT,
    RESEND_DECLARATION,
    SEND_ALL_DECLARATIONS_IN_BATCH,
    SEND_DECLARATIONS_IN_BATCH,
    SEND_SINGLE_DECLARATION,
    SET_IS_EDITING_DECLARATION,
    STORE_BATCH_PROGRESS,
    STORE_DECLARATIONS,
    STORE_DECLARATION_HISTORY,
    STORE_HISTORY_OVERVIEW,
    STORE_MANUAL_VALIDATION_CUSTOM_ERROR,
    STORE_VAT_DECLARATION,
    STORE_VAT_SUPPORTED_MONTHS,
    UPLOAD_DOCUMENTS,
    VALIDATE_MANUAL_MODE,
} from './vat-declaration.action-type';

const getDeclarations = () => ({type: GET_DECLARATIONS});
const storeDeclarations = declarations => ({type: STORE_DECLARATIONS, payload: declarations});

const getCurrentBatchProgress = () => ({type: GET_CURRENT_BATCH_PROGRESS});
const storeBatchProgress = declarations => ({type: STORE_BATCH_PROGRESS, payload: declarations});

const sendSingleDeclaration = payload => ({
    type: SEND_SINGLE_DECLARATION,
    payload,
});

const sendDeclarationsInBatch = payload => ({
    type: SEND_DECLARATIONS_IN_BATCH,
    payload,
});

const activateManualMode = payload => ({type: ACTIVATE_MANUAL_MODE, payload});
const uploadDocuments = payload => ({type: UPLOAD_DOCUMENTS, payload});
const deleteDocument = payload => ({type: DELETE_DOCUMENT, payload});
const replaceDocument = payload => ({type: REPLACE_DOCUMENT, payload});
const resendDeclaration = payload => ({type: RESEND_DECLARATION, payload});
const validateManualMode = payload => ({type: VALIDATE_MANUAL_MODE, payload});
const setIsEditingDeclaration = payload => ({type: SET_IS_EDITING_DECLARATION, payload});
const documentsUploadedSSE = payload => ({type: DOCUMENTS_UPLOADED_SSE, payload});
const documentDeletedSSE = payload => ({type: DOCUMENT_DELETED_SSE, payload});
const declarationResentSSE = payload => ({type: DECLARATION_RESENT_SSE, payload});

const sendAllDeclarationsInBatch = payload => ({
    type: SEND_ALL_DECLARATIONS_IN_BATCH,
    payload,
});

const getVATSupportedMonths = payload => ({
    type: GET_VAT_SUPPORTED_MONTHS,
    payload,
});

const storeVATSupportedMonths = payload => ({
    type: STORE_VAT_SUPPORTED_MONTHS,
    payload,
});

const getVATDeclaration = payload => ({
    type: GET_VAT_DECLARATION,
    payload,
});

const storeVATDeclaration = payload => ({
    type: STORE_VAT_DECLARATION,
    payload,
});

const getVATDeclarationDocuments = payload => ({
    type: GET_VAT_DECLARATION_DOCUMENTS,
    payload,
});

const downloadDocument = payload => ({
    type: DOWNLOAD_DOCUMENT,
    payload,
});

const getHistoryOverview = payload => ({
    type: GET_HISTORY_OVERVIEW,
    payload,
});

const storeHistoryOverview = payload => ({
    type: STORE_HISTORY_OVERVIEW,
    payload,
});

const getDeclarationHistory = payload => ({
    type: GET_DECLARATION_HISTORY,
    payload,
});

const storeDeclarationHistory = payload => ({
    type: STORE_DECLARATION_HISTORY,
    payload,
});

const downloadDeclarationDocuments = payload => ({
    type: DOWNLOAD_DECLARATION_DOCUMENTS,
    payload,
});

const storeManualValidationCustomError = payload => ({
    type: STORE_MANUAL_VALIDATION_CUSTOM_ERROR,
    payload,
});

export const VATDeclarationActions = {
    getDeclarations,
    storeDeclarations,
    getCurrentBatchProgress,
    storeBatchProgress,
    sendSingleDeclaration,
    sendDeclarationsInBatch,
    sendAllDeclarationsInBatch,
    storeVATDeclaration,
    getVATDeclaration,
    getVATDeclarationDocuments,
    getVATSupportedMonths,
    storeVATSupportedMonths,
    activateManualMode,
    uploadDocuments,
    deleteDocument,
    replaceDocument,
    resendDeclaration,
    validateManualMode,
    setIsEditingDeclaration,
    documentDeletedSSE,
    documentsUploadedSSE,
    declarationResentSSE,
    downloadDocument,
    getHistoryOverview,
    storeHistoryOverview,
    storeDeclarationHistory,
    getDeclarationHistory,
    downloadDeclarationDocuments,
    storeManualValidationCustomError,
};
