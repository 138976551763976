import {faCalendarDays, faIdCardClip} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Link, generatePath} from 'react-router-dom';
import {LoadingSkeleton} from '../../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {RoutePaths} from '../../../lib/router/route-paths';

export const CompanyAndPeriod = ({companyId, freelancerId, companyName, declarationPeriod, isLoading}) => {
    const {t} = useTranslation('vatDeclaration');
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Typography level="h4" textColor="text.tertiary">
                {t('vatDeclaration')}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faIdCardClip} />
                <LoadingSkeleton isLoading={isLoading} placeholder="------------">
                    {!isLoading && (
                        <Typography
                            level="h3"
                            color="background.body"
                            component={Link}
                            to={generatePath(RoutePaths.COMPANY, {
                                freelancerId,
                                companyId,
                            })}
                            sx={{textDecoration: 'none'}}
                        >
                            {companyName}
                        </Typography>
                    )}
                </LoadingSkeleton>
            </Stack>
            <Divider orientation="vertical" sx={{bgcolor: 'text.secondary'}} />
            <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faCalendarDays} />
                <Typography level="h3" color="background.body">
                    <LoadingSkeleton placeholder="------------">{declarationPeriod}</LoadingSkeleton>
                </Typography>
            </Stack>
        </Stack>
    );
};

CompanyAndPeriod.propTypes = {
    companyId: PropTypes.string,
    freelancerId: PropTypes.string,
    companyName: PropTypes.string,
    declarationPeriod: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
};

CompanyAndPeriod.defaultProps = {
    companyId: undefined,
    freelancerId: undefined,
    companyName: undefined,
    declarationPeriod: undefined,
};
