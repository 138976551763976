import {faArrowRight, faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Link, Stack, Typography, useTheme} from '@mui/joy';
import DOMPurify from 'dompurify';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link as ReactRouterLink} from 'react-router-dom';
import JoyUIThemeProvider from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {ModalWrapper} from '../../../components/modal-wrapper/ModalWrapper';
import {RoutePaths} from '../../../lib/router/route-paths';
import {SigningActions} from '../../document/modules/signing/store/signing.action';
import {UiSelectors} from '../../ui/store/ui.selector';
import {ModalsKeys} from '../../ui/utils/constants';

export const UpdatedTermsModal = () => {
    const dispatch = useDispatch();
    const modalData = useSelector(UiSelectors.createModalDataSelector(ModalsKeys.UPDATED_TERMS_CONSENT_MODAL));
    const {t} = useTranslation('common');
    const theme = useTheme();

    const onConfirm = () => {
        dispatch(SigningActions.giveUpdatedTermsConsent([modalData.id]));
    };

    const cgvsChangelog = modalData?.changelogPoints;

    return (
        <JoyUIThemeProvider>
            <ModalWrapper
                isV3
                modalKey={ModalsKeys.UPDATED_TERMS_CONSENT_MODAL}
                hasShowClose={false}
                title={<Typography level="h1">{t('updatedTermsModal.title')}</Typography>}
                containerSx={{width: '595px'}}
                zIndex={theme.zIndex.modal + 1}
                buttons={() => (
                    <Stack direction="row" idth="100%" justifyContent="flex-end">
                        <Button
                            variant="solid"
                            color="primary"
                            size="lg"
                            onClick={onConfirm}
                            fullWidth
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                        >
                            {t('updatedTermsModal.dismissButton')}
                        </Button>
                    </Stack>
                )}
            >
                <Typography level="body-lg">
                    <Trans
                        t={t}
                        i18nKey={t('updatedTermsModal.description')}
                        components={{
                            linkText: (
                                <Link
                                    component={ReactRouterLink}
                                    textColor="text.primary"
                                    target="_blank"
                                    endDecorator={(
                                        <Box fontSize="12px" color="inherit">
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                        </Box>
                                      )}
                                    to={RoutePaths.CGVS}
                                />
                            ),
                        }}
                    />
                </Typography>
                {cgvsChangelog && (
                    <Box
                        sx={{
                            border: '1px solid',
                            borderColor: 'neutral.outlinedBorder',
                            borderRadius: 'sm',
                            bgcolor: 'background.level1',
                            maxHeight: {desktop: '400px', mobile: 'none'},
                            flexGrow: 1,
                            overflowY: 'auto',
                            px: 2,
                            mt: 2,
                        }}
                    >
                        <Typography level="h4" textColor="text.primary" mt={1} mb={1}>
                            {t('updatedTermsModal.changesTitle')}
                        </Typography>
                        <Box component="ul" pl="1em">
                            {cgvsChangelog.map((point, index) => (
                                <Typography
                                    key={index}
                                    component="li"
                                    level="body-lg"
                                    mb={2}
                                    sx={{
                                        'display': 'list-item',
                                        '& p': {
                                            mt: 0.5,
                                            mb: 0,
                                        },
                                    }}
                                >
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(point)}} />
                                </Typography>

                            ))}
                        </Box>
                    </Box>
                )}
            </ModalWrapper>
        </JoyUIThemeProvider>
    );
};
