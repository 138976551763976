import {Box} from '@mui/joy';
import React from 'react';
import {useSelector} from 'react-redux';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {DeclarationHistoryTable} from '../DeclarationHistoryTable';

export const YearlyDeclarationHistory = () => {
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);

    if (!declaration) {
        return null;
    }

    return (
        <JoyUIThemeProvider>
            <Box
                sx={{
                    minHeight: 'calc(100vh - 130px)',
                    my: 5,
                    mx: 3,
                    p: 2,
                    borderRadius: 'lg',
                    bgcolor: 'background.body',
                    border: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                }}
            >
                <DeclarationHistoryTable companyId={declaration.companyId} />
            </Box>
        </JoyUIThemeProvider>
    );
};
