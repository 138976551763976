import {Stack} from '@mui/joy';

export const Header = ({children}) => (
    <Stack
        direction="row"
        alignItems="center"
        sx={{
            py: 1,
            px: 2,
            mb: 5,
            bgcolor: 'common.black',
            justifyContent: 'space-between',
            color: 'background.body',
            position: 'sticky',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
        }}
    >
        {children}
    </Stack>
);
