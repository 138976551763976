export const STAGES = {
    PENDING_CONTRACT: 'PENDING_CONTRACT',
    PENDING_REGISTRATION: 'PENDING_REGISTRATION',
    PENDING_CAPITAL_DEPOSIT: 'PENDING_CAPITAL_DEPOSIT',
    PENDING_REGISTRATION_FILE: 'PENDING_REGISTRATION_FILE',
    PENDING_FINAL_DOCUMENTS: 'PENDING_FINAL_DOCUMENTS',
};

export const CAPITAL_DEPOSIT_TRANSFER_STATUS_ENUM = {
    WAITING_FOR_NOTARY_TRANSFER: 'WAITING_FOR_NOTARY_TRANSFER',
    COMPLETED: 'COMPLETED',
};

export const STAGES_WITH_HEADLINES = {
    STEP_1: {
        type: 'header',
        value: 'STEP_1',
        t: 'companiesStages.STEP_1',
    },
    INVITED: {
        type: 'value',
        location: 'companyStages',
        value: 'INVITED',
        stage: STAGES.PENDING_CONTRACT,
        t: 'contractStatus.INVITED',
    },
    CONTRACT_PENDING: {
        type: 'value',
        location: 'companyStages',
        value: 'CONTRACT_PENDING',
        stage: STAGES.PENDING_CONTRACT,
        t: 'contractStatus.CONTRACT_PENDING',
    },
    COMPLETED_1: {
        type: 'value',
        location: 'companyStages',
        key: 'COMPLETED_1',
        value: 'COMPLETED',
        stage: STAGES.PENDING_CONTRACT,
        t: 'companiesStages.completed_contractStatus',
    },

    STEP_2: {
        type: 'header',
        value: 'STEP_2',
        t: 'companiesStages.STEP_2',
    },
    PENDING_ENABLE_SETUP: {
        type: 'value',
        location: 'companyStages',
        value: 'PENDING_ENABLE_SETUP',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'registration.PENDING_ENABLE_SETUP',
    },
    PENDING_SETUP_START: {
        type: 'value',
        location: 'companyStages',
        value: 'PENDING_SETUP_START',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'registration.PENDING_SETUP_START',
    },
    PENDING_DATA_INPUT: {
        type: 'value',
        location: 'companyStages',
        value: 'PENDING_DATA_INPUT',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'registration.PENDING_DATA_INPUT',
    },
    READY_FOR_REVIEW: {
        type: 'value',
        location: 'companyStages',
        value: 'READY_FOR_REVIEW',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'registration.READY_FOR_REVIEW',
    },
    PENDING_EDITS: {
        type: 'value',
        location: 'companyStages',
        value: 'PENDING_EDITS',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'registration.PENDING_EDITS',
    },
    PENDING_INIT_SIGS: {
        type: 'value',
        location: 'companyStages',
        value: 'PENDING_INIT_SIGS',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'registration.PENDING_INIT_SIGS',
    },
    COMPLETED_2: {
        type: 'value',
        location: 'companyStages',
        key: 'COMPLETED_2',
        value: 'COMPLETED',
        stage: STAGES.PENDING_REGISTRATION,
        t: 'companiesStages.completed_registration',
    },

    STEP_3: {
        type: 'header',
        value: 'STEP_3',
        t: 'companiesStages.STEP_3',
    },
    PENDING: {
        type: 'value',
        location: 'companyStages',
        value: 'PENDING',
        stage: STAGES.PENDING_CAPITAL_DEPOSIT,
        t: 'capital_deposit.PENDING',
    },
    COMPLETED_3: {
        type: 'value',
        location: 'companyStages',
        key: 'COMPLETED_3',
        value: 'COMPLETED',
        stage: STAGES.PENDING_CAPITAL_DEPOSIT,
        t: 'companiesStages.completed_capital_deposit',
    },

    STEP_4: {
        type: 'header',
        value: 'STEP_4',
        t: 'companiesStages.STEP_4',
    },
    REGISTRATION_NOT_PREPARED: {
        type: 'value',
        location: 'companyStages',
        value: 'REGISTRATION_NOT_PREPARED',
        stage: STAGES.PENDING_REGISTRATION_FILE,
        t: 'immatriculation.REGISTRATION_NOT_PREPARED',
    },
    REGISTRATION_PARTIALLY_PREPARED: {
        type: 'value',
        location: 'companyStages',
        value: 'REGISTRATION_PARTIALLY_PREPARED',
        stage: STAGES.PENDING_REGISTRATION_FILE,
        t: 'immatriculation.REGISTRATION_PARTIALLY_PREPARED',
    },
    REGISTRATION_LAUNCHED: {
        type: 'value',
        location: 'companyStages',
        value: 'REGISTRATION_LAUNCHED',
        stage: STAGES.PENDING_REGISTRATION_FILE,
        t: 'immatriculation.REGISTRATION_LAUNCHED',
    },
    REGISTRATION_FINALIZATION: {
        type: 'value',
        location: 'companyStages',
        value: 'REGISTRATION_FINALIZATION',
        stage: STAGES.PENDING_REGISTRATION_FILE,
        t: 'immatriculation.REGISTRATION_FINALIZATION',
    },
    REGISTRATION_COMPLETED: {
        type: 'value',
        location: 'companyStages',
        value: 'REGISTRATION_COMPLETED',
        stage: STAGES.PENDING_REGISTRATION_FILE,
        t: 'immatriculation.REGISTRATION_COMPLETED',
    },

    STEP_5: {
        type: 'header',
        value: 'STEP_5',
        t: 'companiesStages.STEP_5',
    },
    PENDING_FINAL_SIGS_5: {
        type: 'value',
        location: 'companyStages',
        key: 'PENDING_FINAL_SIGS_5',
        value: 'PENDING_FINAL_SIGS',
        stage: STAGES.PENDING_FINAL_DOCUMENTS,
        t: 'final_docs.PENDING_FINAL_SIGS',
    },
    WAITING_FOR_NOTARY_TRANSFER: {
        type: 'value',
        location: 'companyStages',
        key: 'WAITING_FOR_NOTARY_TRANSFER',
        value: 'WAITING_FOR_NOTARY_TRANSFER',
        stage: STAGES.PENDING_FINAL_DOCUMENTS,
        t: 'final_docs.WAITING_FOR_NOTARY_TRANSFER',
    },
};

export const TIMELINE_INFO_STAGES = {
    'contract_state': 'contract_state',
    'company_setup_state': 'company_setup_state',
    'capital_deposit_state': 'capital_deposit_state',
    'company_registration_state': 'company_registration_state',
    'final_documents_signed_state': 'final_documents_signed_state',

    'capital_deposit_transfer_state': 'capital_deposit_transfer_state',
    'individual_shareholder_account_closed_state': 'individual_shareholder_account_closed_state',
};

export const STATUS_FILTERS = {
    ready_for_review: 'ready_for_review',
    pending_registration: 'pending_registration',
    is_company_registration_late: 'is_company_registration_late',
};

export const QUICK_FILTERS_DEFAULT = {
    ready_for_review: 0,
    is_company_registration_late: 0,
    pending_registration: 0,
};

export const LS_BO_PER_PAGE = 'LS_PER_PAGE';
export const PAGE_OPTIONS = {
    PER_PAGE_AVAILABLE_OPTIONS: [10, 25, 50],
    PAGE_DEFAULT_SIZE: localStorage.getItem(LS_BO_PER_PAGE)
        ? parseInt(localStorage.getItem(LS_BO_PER_PAGE), 10) : 10,
};

export const GRID_TEMPLATES = {
    MAIN_STEPS: '0.5fr repeat(4, 24px 1fr) 24px 0.5fr',
    TEXT_STEPS: 'repeat(5, 1fr)',
};

export const EmailReminderType = {
    CAPITAL_DEPOSIT_REMINDER: 'CAPITAL_DEPOSIT_REMINDER',
    PENDING_FINAL_SIGS_REMINDER: 'PENDING_FINAL_SIGS_REMINDER',
    AUTOMATIC_CAPITAL_DEPOSIT_REMINDER: 'AUTOMATIC_CAPITAL_DEPOSIT_REMINDER',
};
