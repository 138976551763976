import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import NumberFormat from 'react-number-format';
import {formatToStandardNumber} from '../../utils/number';

export const StandardNumberField = props => {
    const [value, setValue] = useState(props.value || '');
    const handlePaste = event => {
        event.preventDefault();
        const input = (event.clipboardData || window.clipboardData).getData('text');
        const formatted = formatToStandardNumber(input);
        setValue(formatted);
    };

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleValueChange = values => {
        props.onChange?.(values.value);
    };

    const additionalProps = {};

    // Custom implementation of removing leading zeros while typing
    if (!props.areLeadingZerosAllowed) {
        additionalProps.onInput = e => {
            if (value === '0') {
                e.target.value = e.target.value.replace(/^0+([0-9])/, '$1');
            }
        };
    }

    return (
        <NumberFormat
            customInput={props.customInput}
            decimalSeparator="."
            thousandSeparator={false}
            allowedDecimalSeparators={[',']}
            {...props}
            value={value}
            onPaste={handlePaste}
            onChange={handleChange}
            onValueChange={handleValueChange}
            {...additionalProps}
        />
    );
};

StandardNumberField.propTypes = {
    ...TextField.propTypes,
    ...NumberFormat.propTypes,
    areLeadingZerosAllowed: PropTypes.bool,

};
StandardNumberField.defaultProps = {
    ...TextField.defaultProps,
    ...NumberFormat.defaultProps,
    customInput: TextField,
    areLeadingZerosAllowed: true,
};
