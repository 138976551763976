import {generatePath} from 'react-router-dom';
import {all, call, fork, put, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {VATDeclarationActions} from './vat-declaration.action';
import {
    ACTIVATE_MANUAL_MODE,
    DECLARATION_RESENT_SSE,
    DELETE_DOCUMENT,
    DOCUMENTS_UPLOADED_SSE,
    DOCUMENT_DELETED_SSE,
    DOWNLOAD_DECLARATION_DOCUMENTS,
    DOWNLOAD_DOCUMENT,
    GET_CURRENT_BATCH_PROGRESS,
    GET_DECLARATIONS,
    GET_DECLARATION_HISTORY,
    GET_HISTORY_OVERVIEW,
    GET_VAT_DECLARATION,
    GET_VAT_SUPPORTED_MONTHS,
    REPLACE_DOCUMENT,
    RESEND_DECLARATION,
    SEND_ALL_DECLARATIONS_IN_BATCH,
    SEND_DECLARATIONS_IN_BATCH,
    SEND_SINGLE_DECLARATION,
    UPLOAD_DOCUMENTS, VALIDATE_MANUAL_MODE,
} from './vat-declaration.action-type';
import {VatDeclarationSelectors} from './vat-declaration.selector';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {routerActions} from '../../../lib/router/connected-router-saga/connected-router.actions';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import hiwayLocalStorage from '../../../v1/config/storage';
import {BankApi} from '../../bank/api/bank.api';
import {downloadDocumentFlow} from '../../document/modules/database/store/database.saga';
import {VAT_LOCAL_STORAGE_KEY} from '../../formalities/modules/vat-declaration/utils/consts';
import {LoadingActions, LoadingTypes} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {VATDeclarationApi} from '../api/vat-declaration.api';
import {getCurrentDeclarationPeriod} from '../utils';

export const vatDeclarationLoaderSaga = function* () {
    yield put(VATDeclarationActions.getDeclarations());
    yield put(VATDeclarationActions.storeManualValidationCustomError(null));
};

const mapSearchSortParam = param => {
    switch (param) {
        case 'company':
            return 'companyName';
    }
    return param;
};

const getPaginationAndSortParams = (search, queryPrefix, hasPagination = true) => {
    const storageKey = `${queryPrefix}-savedRowsPerPage`;
    const limit = search?.[`${queryPrefix}-rowsPerPage`]
        ?? (hiwayLocalStorage.has(storageKey) ? parseInt(hiwayLocalStorage.get(storageKey), 10) : 10);

    return {
        ...(hasPagination && {
            limit: limit,
            offset: search?.[`${queryPrefix}-page`] ? parseInt(search[`${queryPrefix}-page`], 10) * limit : 0,
        }),
        sortBy: search?.[`${queryPrefix}-sortBy`] ? mapSearchSortParam(search[`${queryPrefix}-sortBy`]) : undefined,
        sortOrder: search?.[`${queryPrefix}-sortBy`]
            ? search?.[`${queryPrefix}-sortDirection`]
                ? search?.[`${queryPrefix}-sortDirection`].toUpperCase()
                : 'DESC'
            : undefined,
    };
};

const getDeclarationsSaga = function* () {
    const location = yield select(selectRouterLocation);
    const search = location?.query;
    const queryPrefix = 'vatDeclaration';

    if (!search?.[`${queryPrefix}-month`] || !search?.[`${queryPrefix}-year`]) {
        return;
    }

    const paginationAndSortParams = getPaginationAndSortParams(search, queryPrefix);

    const statusFilter = search?.[`${queryPrefix}-statusFilter`];

    const params = {
        ...paginationAndSortParams,
        searchQuery: search?.['vatDeclaration-searchTerm'],
        filterQuery: {
            month: Number(search?.['vatDeclaration-month']),
            year: Number(search?.['vatDeclaration-year']),
            ...(statusFilter && {status: statusFilter?.split(encodeURIComponent(','))}),
        },
    };

    yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS, true));

    try {
        const declarations = yield call(VATDeclarationApi.getDeclarations, params);
        const freelancerIds = declarations.items.map(item => item.freelancer.id);
        if (freelancerIds.length > 0) {
            yield fork(connectBankIntegrationsWithDeclarations, freelancerIds);
        }
        yield put(VATDeclarationActions.storeDeclarations(declarations));
    } catch (error) {
        Debug.error('vat-declaration', error.message, {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS, false));
    }
};

const connectBankIntegrationsWithDeclarations = function* (freelancerIds) {
    yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS, true));

    try {
        const bankDetails = yield call(BankApi.getBankDetails, freelancerIds);
        const declarations = yield select(VatDeclarationSelectors.selectDeclarations);

        const newDeclarationsData = yield declarations?.items?.map(item => {
            const {bankAccountOverview} = bankDetails?.find(bank => bank?.freelancerId === item?.freelancer?.id);
            return {
                ...item,
                bankIntegrations: bankAccountOverview?.overviewPerAccount,
                totalUncategorizedTransactions: bankAccountOverview?.totalUncategorizedTransactions,
            };
        });

        yield put(VATDeclarationActions.storeDeclarations({...declarations, items: newDeclarationsData}));
        yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS, false));
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
        yield put(LoadingActions.setLoading(LoadingTypes.CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS, false));
    }
};

/* Batch progress updates will come via SSE events, however, we want to use this
 * to get the inital progress in order to setup the correct state of the page if
 * refreshed or closed and reopened.
 * */
const getCurrentBatchProgressFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_GET_BATCH_PROGRESS, true));

        const response = yield call(VATDeclarationApi.getBatchProgress);
        yield put(VATDeclarationActions.storeBatchProgress(response));

        const oldData = hiwayLocalStorage.get(VAT_LOCAL_STORAGE_KEY) ?? {};
        hiwayLocalStorage.set(VAT_LOCAL_STORAGE_KEY, {
            ...oldData,
            batchId: response.pendingEventId,
        });
    } catch (e) {
        /* We ignore 404 errors this means there are no batches currently in process. */
        if (e?.response?.status === 404) {
            // Good time to cleanup any processing leftovers
            yield put(VATDeclarationActions.storeBatchProgress({}));
        } else {
            Debug.error('VAT getCurrentBatchProgressFlow', 'Error: ', {e});
            Toast.error('genericError');
        }
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_GET_BATCH_PROGRESS, false));
    }
};

const sendDeclarationsInBatchFlow = function* (declarationIds) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS, true));

        const {pendingEventId} = yield call(VATDeclarationApi.sendDeclarations, {declarationIds});
        hiwayLocalStorage.set(VAT_LOCAL_STORAGE_KEY, {
            initialisedBatchId: pendingEventId,
            hasDismissedNotification: false,
        });

        if (declarationIds.length > 1) {
            yield put(UiActions.setActiveModal(ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION, true));
            yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS, false));
        }
    } catch (e) {
        Debug.error('VAT sendDeclarationsInBatchFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const sendAllDeclarationsInBatchFlow = function* (month, year) {
    try {
        // NOTE: Loading is updated in the SSE event handler
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATIONS_SEND_IN_PROGRESS, true));

        const {pendingEventId} = yield call(VATDeclarationApi.sendAllDeclarations, {month, year});
        hiwayLocalStorage.set(VAT_LOCAL_STORAGE_KEY, {
            initialisedBatchId: pendingEventId,
            hasDismissedNotification: false,
        });

        // Open correct modal for processing info
        yield put(UiActions.setActiveModal(ModalsKeys.SEND_VAT_DECLARATIONS, false));
        yield put(UiActions.setActiveModal(ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION, true));
    } catch (e) {
        Debug.error('VAT sendSingleDeclarationFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const getVATSupportedMonthsFlow = function* (companyId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_SUPPORTED_MONTHS, true));
        const result = yield call(VATDeclarationApi.getSupportedMonths, {companyId});
        yield put(VATDeclarationActions.storeVATSupportedMonths({...result.supportedMonths}));
    } catch (error) {
        Debug.error('VAT getVATSupportedMonthsFlow ', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_SUPPORTED_MONTHS, false));
    }
};

const getVATDeclarationFlow = function* (declarationId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DETAILS, true));
        const result = yield call(VATDeclarationApi.getVATDeclaration, {declarationId});
        yield put(VATDeclarationActions.storeVATDeclaration(result));
        yield put(VATDeclarationActions.getHistoryOverview({
            companyId: result.companyId, year: getCurrentDeclarationPeriod().year(),
        }));
    } catch (error) {
        Debug.error('VAT getVATDeclarationFlow ', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DETAILS, false));
    }
};

const getVATSupportedMonthsSaga = function* ({payload}) {
    const {companyId} = payload;
    yield call(getVATSupportedMonthsFlow, companyId);
};

const getVATDeclarationSaga = function* ({payload}) {
    const {declarationId} = payload;
    yield put(VATDeclarationActions.setIsEditingDeclaration(false));
    yield call(getVATDeclarationFlow, declarationId);
};

export const vatDeclarationResultLoaderSaga = function* ({payload}) {
    const {declarationId} = payload.params;
    yield put(VATDeclarationActions.storeVATDeclaration(null));
    yield put(VATDeclarationActions.setIsEditingDeclaration(false));
    yield put(VATDeclarationActions.getVATDeclaration({declarationId}));
    yield put(VATDeclarationActions.storeManualValidationCustomError(null));
};

const getCurrentBatchProgressSaga = function* () {
    yield call(getCurrentBatchProgressFlow);
};

const sendSingleDeclarationSaga = function* ({payload}) {
    const {declarationId} = payload;
    yield call(sendDeclarationsInBatchFlow, [declarationId]);
};

const sendDeclarationsInBatchSaga = function* ({payload}) {
    const {declarationIds} = payload;
    yield call(sendDeclarationsInBatchFlow, declarationIds);
};

const sendAllDeclarationsInBatchSaga = function* ({payload}) {
    const {month, year} = payload;
    yield call(sendAllDeclarationsInBatchFlow, month, year);
};

const activateManualModeSaga = function* ({payload}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_ACTIVATE_MANUAL_MODE, true));
        const result = yield call(VATDeclarationApi.activateManualMode, payload);
        yield put(VATDeclarationActions.storeVATDeclaration(result));
        yield put(
            routerActions.replace(
                generatePath(RoutePaths.VAT_DECLARATION_RESULTS, {
                    declarationId: result.id,
                }),
            ),
        );
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_ACTIVATE_MANUAL_MODE, false));
        yield put(UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_SWITCH_TO_MANUAL_MODE, false));
    }
};

const uploadDocumentsAndWaitForSSE = function* ({declarationId, files}) {
    yield fork(VATDeclarationApi.uploadDocuments, {declarationId, files});
    yield take(action => action.type === DOCUMENTS_UPLOADED_SSE && action.payload.declarationId === declarationId);
};

const uploadDocumentsSaga = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, true));
    try {
        const {declarationId} = payload;
        yield call(uploadDocumentsAndWaitForSSE, payload);
        const declaration = yield call(VATDeclarationApi.getVATDeclaration, {declarationId});
        yield put(VATDeclarationActions.storeVATDeclaration(declaration));
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, false));
    }
};

const deleteDocumentAndWaitForSSE = function* ({declarationId, documentId}) {
    yield fork(VATDeclarationApi.deleteDocument, {declarationId, documentId});
    yield take(action => action.type === DOCUMENT_DELETED_SSE && action.payload.documentId === documentId);
};

const deleteDocumentSaga = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, true));
    try {
        yield call(deleteDocumentAndWaitForSSE, payload);
        const declaration = yield call(VATDeclarationApi.getVATDeclaration, {declarationId: payload.declarationId});
        yield put(VATDeclarationActions.storeVATDeclaration(declaration));
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, false));
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, false));
    }
};

const replaceDocumentSaga = function* ({payload}) {
    try {
        const {oldDocument, newDocument, declarationId} = payload;
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, true));

        yield call(deleteDocumentAndWaitForSSE, {declarationId, documentId: oldDocument.id});
        yield call(uploadDocumentsAndWaitForSSE, {declarationId, files: [newDocument]});

        const updatedDeclaration = yield call(VATDeclarationApi.getVATDeclaration, {declarationId});
        yield put(VATDeclarationActions.storeVATDeclaration(updatedDeclaration));
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOCUMENTS, false));
    }
};

const resendDeclarationAndWaitForSSE = function* ({declarationId}) {
    yield fork(VATDeclarationApi.resendDeclaration, {declarationId});
    yield take(DECLARATION_RESENT_SSE);
};

const resendDeclarationSaga = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_RESEND, true));
    try {
        yield call(resendDeclarationAndWaitForSSE, payload);

        yield call(getVATDeclarationFlow, payload.declarationId);
        const updatedDeclaration = yield select(VatDeclarationSelectors.selectVATDeclaration);

        if (updatedDeclaration.childDeclarationId) {
            yield put(
                routerActions.replace(
                    generatePath(RoutePaths.VAT_DECLARATION_RESULTS, {
                        declarationId: updatedDeclaration.childDeclarationId,
                    }),
                ),
            );
        }
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_RESEND, false));
        yield put(UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_RESEND_DECLARATION, false));
    }
};

const validateManualModeSaga = function* ({payload}) {
    // TODO: Consider adding this to `api-type-errors.js`
    const customErrors = ['VatCreditToCarryForwardValidationError', 'DebitCreditMismatchError'];
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_VALIDATE_MANUAL, true));
        yield put(VATDeclarationActions.storeManualValidationCustomError(null));
        const result = yield call(VATDeclarationApi.validateManualMode, payload);
        yield put(VATDeclarationActions.setIsEditingDeclaration(false));
        yield put(
            routerActions.replace(
                generatePath(RoutePaths.VAT_DECLARATION_RESULTS, {
                    declarationId: result.id,
                }),
            ),
        );
    } catch (error) {
        Debug.error('vatDeclaration', 'Error: ', {error});
        const customError = error?.response?.data?.error;
        if (customError && customErrors.includes(customError)) {
            yield put(VATDeclarationActions.storeManualValidationCustomError(customError));
        } else {
            Toast.error('genericError');
        }
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_VALIDATE_MANUAL, false));
        yield put(UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_VALIDATE_CONFIRMATION, false));
    }
};

export const handleSSEBatchInfoComplete = function* ({data}) {
    try {
        const event = JSON.parse(data);
        const currentDeclaration = yield select(VatDeclarationSelectors.selectVATDeclaration);
        const declarationId = currentDeclaration.id;

        if (event.data.batchSize === 1 && event.data.declarationIds[0] === declarationId) {
            yield put(VATDeclarationActions.declarationResentSSE({declarationId}));
        }
    } catch (error) {
        Toast.error('genericError');
        Debug.error('vatDeclaration', 'Error: ', {error});
    }
};

const downloadDocumentSaga = function* ({payload}) {
    try {
        const {declaration, documentId, isDownloading} = payload;
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOWNLOAD_DOCUMENT, true));
        yield call(downloadDocumentFlow, {
            documentId,
            freelancerId: declaration.freelancer.id,
            companyId: declaration.companyId,
            isDownloading,
        });
    } catch (error) {
        Toast.error('genericError');
        Debug.error('vatDeclaration', 'Error: ', {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOWNLOAD_DOCUMENT, false));
    }
};

const downloadDeclarationDocumentsSaga = function* ({payload}) {
    try {
        const {declarationId} = payload;
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOWNLOAD_DOCUMENT, true));
        const {signedUrl} = yield call(VATDeclarationApi.getVATDeclarationDocuments, {
            declarationId,
        });

        yield call(async () => {
            const response = await fetch(signedUrl);
            const blob = await response.blob();
            // TODO: Make this reusable instead of copy-paste (from database.saga.js and many other places)
            const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
            const filename = matchedGroups[2];
            window.saveAs(blob, decodeURI(filename));
        });
    } catch (error) {
        Toast.error('genericError');
        Debug.error('vatDeclaration', 'Error: ', {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_DOWNLOAD_DOCUMENT, false));
    }
};

const getDeclarationHistorySaga = function* ({payload}) {
    try {
        const queryPrefix = 'vatDeclarationHistory';
        const location = yield select(selectRouterLocation);
        const search = location?.query;
        const paginationAndSortParams = getPaginationAndSortParams(search, queryPrefix, payload.hasPagination);

        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_HISTORY, true));
        const declarations = yield call(VATDeclarationApi.getDeclarations, {
            ...paginationAndSortParams,
            sortBy: paginationAndSortParams.sortBy || 'createdAt',
            sortOrder: paginationAndSortParams.sortOrder || 'DESC',
            filterQuery: {
                latestOnly: false,
                companyId: payload.companyId,
                ...(payload.year && {year: payload.year}),
            },
        });
        yield put(VATDeclarationActions.storeDeclarationHistory(declarations));
    } catch (error) {
        Toast.error('genericError');
        Debug.error('vatDeclaration', 'Error: ', {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_HISTORY, false));
    }
};

const getHistoryOverviewSaga = function* ({payload}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_HISTORY_OVERVIEW, true));
        const historyOverview = yield call(VATDeclarationApi.getHistoryOverview, payload);
        yield put(VATDeclarationActions.storeHistoryOverview(historyOverview));
    } catch (error) {
        Toast.error('genericError');
        Debug.error('vatDeclaration', 'Error: ', {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.VAT_DECLARATION_HISTORY_OVERVIEW, false));
    }
};

export const vatDeclarationsSaga = function* () {
    yield all([
        takeEvery(GET_DECLARATIONS, getDeclarationsSaga),
        takeEvery(GET_VAT_SUPPORTED_MONTHS, getVATSupportedMonthsSaga),
        takeEvery(GET_VAT_DECLARATION, getVATDeclarationSaga),
        takeEvery(GET_CURRENT_BATCH_PROGRESS, getCurrentBatchProgressSaga),
        takeEvery(SEND_SINGLE_DECLARATION, sendSingleDeclarationSaga),
        takeEvery(SEND_DECLARATIONS_IN_BATCH, sendDeclarationsInBatchSaga),
        takeEvery(SEND_ALL_DECLARATIONS_IN_BATCH, sendAllDeclarationsInBatchSaga),
        takeLatest(ACTIVATE_MANUAL_MODE, activateManualModeSaga),
        takeLatest(UPLOAD_DOCUMENTS, uploadDocumentsSaga),
        takeLatest(DELETE_DOCUMENT, deleteDocumentSaga),
        takeLatest(REPLACE_DOCUMENT, replaceDocumentSaga),
        takeLatest(RESEND_DECLARATION, resendDeclarationSaga),
        takeLatest(VALIDATE_MANUAL_MODE, validateManualModeSaga),
        takeLatest(DOWNLOAD_DOCUMENT, downloadDocumentSaga),
        takeLatest(GET_HISTORY_OVERVIEW, getHistoryOverviewSaga),
        takeLatest(GET_DECLARATION_HISTORY, getDeclarationHistorySaga),
        takeLatest(DOWNLOAD_DECLARATION_DOCUMENTS, downloadDeclarationDocumentsSaga),
    ]);
};
