import {
    faChevronDown,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {AccountsMenuItemIcon} from './AccountsMenuItemIcon';
import {BANK_TYPES} from '../../../bridge-api/utils/constants';

export const AccountsMenuHandler = ({
    label,
    value,
    hasError,
    isHiway,
    isMobile,
    onMenuOpen,
}) => {
    const isArchive = value === BANK_TYPES.archived;

    return (
        <Button
            fullWidth={isMobile}
            onClick={onMenuOpen}
            startDecorator={(
                <AccountsMenuItemIcon
                    value={value}
                    isHiway={isHiway}
                    hasError={hasError}
                    isInHandler
                />
            )}
            endDecorator={(
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    {hasError && (
                        <Box color="neutral.softColor">
                            <FontAwesomeIcon icon={isMobile ? faChevronRight : faChevronDown} />
                        </Box>
                    )}
                    {!hasError && (
                        <FontAwesomeIcon icon={isMobile ? faChevronRight : faChevronDown} />
                    )}
                </Stack>
            )}
            color={hasError ? 'warning' : 'neutral'}
            size="lg"
            variant="outlined"
            sx={{
                p: 2,
                backgroundColor: 'background.body',
                boxShadow: 'xs',
                ...(isHiway ? {
                    '& .MuiButton-startDecorator': {
                        mr: 1,
                    },
                } : {
                    '& .MuiButton-startDecorator': {
                        color: isArchive
                            ? 'neutral.softColor'
                            : hasError
                                ? 'warning.plainColor'
                                : 'text.icon',
                    },
                }),
                ...(isArchive ? {
                    color: 'neutral.softColor',
                } : {
                    'color': 'primary.plainColor',
                    '& .MuiButton-endDecorator': {
                        color: 'text.icon',
                    },
                }),
                minHeight: '2.25rem',
                height: '2.25rem',
                py: 1,
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                flexGrow={1}
                sx={hasError ? {color: 'neutral.softColor'} : {}}
            >
                {label}
            </Stack>
        </Button>
    );
};

AccountsMenuHandler.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    hasError: PropTypes.bool,
    isHiway: PropTypes.bool,
    isMobile: PropTypes.bool,
    onMenuOpen: PropTypes.func.isRequired,
};

AccountsMenuHandler.defaultProps = {
    label: null,
    value: null,
    hasError: false,
    isHiway: false,
    isMobile: false,
};
