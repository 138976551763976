import {Box, Sheet} from '@mui/joy';
import React from 'react';
import BankAccountStatements from './BankAccountStatements';
import DetailsSection from './DetailsSection';
import RibPreviewAndDownload from './RibPreviewAndDownload';
import useMediaQuery from '../../../../../components/joy-ui/mui-legacy/useMediaQuery';

const RibAndDocuments = () => {
    const isMobileSize = useMediaQuery(`(max-width:1280px)`);

    return (
        <Box
            sx={{
                px: {xs: 2, lg: 0},
                ...(isMobileSize && {
                    px: 2,
                }),
            }}
        >
            <Sheet
                sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    gap: 2,
                    mb: 2,
                    borderRadius: 'lg',
                }}
            >
                <DetailsSection />
                <RibPreviewAndDownload />
            </Sheet>
            <BankAccountStatements />
        </Box>
    );
};

export default RibAndDocuments;
