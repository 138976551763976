import {faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, CircularProgress, Stack, Typography} from '@mui/joy';
import {filesize} from 'filesize';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {Trans, useTranslation} from 'react-i18next';
import {UploadedDocument} from './UploadedDocument';
import JoyUIThemeProvider from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';

export const DocumentsDropZone = ({
    maxFiles,
    fileTypes,
    maxSize,
    isDisabled,
    onChange,
    files,
    isUploading,
    fileTypesDescription,
    renderFileActions,
    dropzoneRef,
    onFileDialogCancel,
    onDropRejected,
    isMultiple,
}) => {
    const {t} = useTranslation('dropzone');
    const remainingFiles = maxFiles - files.length;

    const dropzone = useDropzone({
        maxFiles: remainingFiles,
        accept: fileTypes,
        maxSize,
        disabled: isDisabled,
        multiple: isMultiple,
        onFileDialogCancel,
        onDropRejected,
    });

    dropzoneRef.current = dropzone;

    const {acceptedFiles, getRootProps, getInputProps, fileRejections, isDragActive} = dropzone;

    const hasRejections = fileRejections.length > 0;
    const formattedFileSize = filesize(maxSize, {locale: 'en'});

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            onChange(acceptedFiles);
        }
        // Excluding `onChange` from the deps array on purpose
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    return (
        <JoyUIThemeProvider>
            <Stack spacing={2}>
                <Box
                    {...getRootProps()}
                    sx={{
                        p: 2,
                        bgcolor: isDragActive ? 'neutral.100' : hasRejections ? 'danger.50' : 'neutral.softBg',
                        borderRadius: 'sm',
                        border: '1px dashed',
                        borderColor: 'divider',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        ...(isDisabled && {pointerEvents: 'none'}),
                    }}
                >
                    <input {...getInputProps()} disabled={isDisabled} />
                    {isUploading ? (
                        <Stack alignItems="center" spacing={0.5}>
                            <CircularProgress variant="solid" color="neutral" sx={{'--_root-size': '60px'}} />
                            <Typography level="body-md" textColor="text.secondary">
                                {t('v3.uploading')}
                            </Typography>
                        </Stack>
                    ) : (
                        <>
                            <Button
                                variant="solid"
                                color="neutral"
                                size="sm"
                                disabled={isDisabled}
                                startDecorator={<FontAwesomeIcon icon={faPaperclip} />}
                            >
                                {t('v3.buttonText')}
                            </Button>
                            {hasRejections ? (
                                <Typography level="body-md" textColor="danger.plainColor" textAlign="center" mt={1}>
                                    <Trans t={t} i18nKey="v3.rejection" values={{size: formattedFileSize}} components={{br: <br />}} />
                                </Typography>
                            ) : (
                                <>
                                    <Typography level="body-md" textColor="text.tertiary" mt={1}>
                                        {maxSize && t('v3.fileSize', {value: formattedFileSize})}
                                        {maxSize && fileTypesDescription && ', '}
                                        {fileTypesDescription}
                                    </Typography>
                                    <Typography level="body-md" textColor="text.tertiary">
                                        {t('v3.maxFiles', {value: maxFiles})}
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </Box>
                {files.map?.(file => (
                    <UploadedDocument
                        isDisabled={isUploading}
                        key={file.id}
                        name={file.originalFilename}
                        actions={renderFileActions(file)}
                    />
                ))}
            </Stack>
        </JoyUIThemeProvider>
    );
};

DocumentsDropZone.propTypes = {
    maxFiles: PropTypes.number.isRequired,
    fileTypes: PropTypes.string,
    maxSize: PropTypes.number,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    files: PropTypes.array,
    isUploading: PropTypes.bool,
    fileTypesDescription: PropTypes.string,
    renderFileActions: PropTypes.func.isRequired,
    dropzoneRef: PropTypes.object,
    onFileDialogCancel: PropTypes.func,
    onDropRejected: PropTypes.func,
    isMultiple: PropTypes.bool.isRequired,
};

DocumentsDropZone.defaultProps = {
    fileTypes: undefined,
    isDisabled: false,
    files: [],
    isUploading: false,
    maxSize: undefined,
    fileTypesDescription: undefined,
    dropzoneRef: {},
    onFileDialogCancel: undefined,
    onDropRejected: undefined,
};
