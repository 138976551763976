import {InsuranceActionTypes} from './insurance.action-type';

export const InsuranceActions = {
    getInsurance: payload => ({
        type: InsuranceActionTypes.GET_INSURANCE,
        payload,
    }),
    setInsurance: payload => ({
        type: InsuranceActionTypes.SET_INSURANCE,
        payload,
    }),
    storeInsurance: payload => ({
        type: InsuranceActionTypes.STORE_INSURANCE,
        payload,
    }),
    skipInsurance: payload => ({
        type: InsuranceActionTypes.SKIP_INSURANCE,
        payload,
    }),
    finalizeInsurance: () => ({
        type: InsuranceActionTypes.FINALIZE_INSURANCE,
    }),
    generateInsuranceDocument: payload => ({
        type: InsuranceActionTypes.GENERATE_INSURANCE_DOCUMENT,
        payload,
    }),
    loadInsuranceDocument: payload => ({
        type: InsuranceActionTypes.LOAD_INSURANCE_DOCUMENT,
        payload,
    }),
    signInsurancePostOnboarding: () => ({
        type: InsuranceActionTypes.SIGN_INSURANCE_POST_ONBOARDING,
    }),
};
