import moment from 'moment';
import {MyActivityParams, MyHomeParams, OwnIncomeParams, SpouseIncomeParams} from './consts';
import {BE_DATE_FORMAT} from '../../../../../utils/constants';
import {getWorkingDaysInRange} from '../../../../../utils/date';
import {isPublicHoliday} from '../../../../../v1/utils';

export const extractSimulationRelevantData = rawData => {
    return {
        /* Home */
        [MyHomeParams.numberOfAdults]: rawData[MyHomeParams.numberOfAdults],
        [MyHomeParams.fiscalParts]: rawData[MyHomeParams.fiscalParts],

        /* Activity */
        [MyActivityParams.dailyRate]: rawData[MyActivityParams.dailyRate],
        // TODO: Calculate default value based on info in epic
        [MyActivityParams.daysWorked]: rawData[MyActivityParams.daysWorked],
        [MyActivityParams.meanMonthlyExpenses]: rawData[MyActivityParams.meanMonthlyExpenses],

        /* My Own Income */
        [OwnIncomeParams.ownWageIncome]: rawData[OwnIncomeParams.ownWageIncome],
        [OwnIncomeParams.ownFranceTravail]: rawData[OwnIncomeParams.ownFranceTravail],
        [OwnIncomeParams.ownManagerIncome]: rawData[OwnIncomeParams.ownManagerIncome],
        [OwnIncomeParams.ownMicroenterpriseIncomeBIC]: rawData[OwnIncomeParams.ownMicroenterpriseIncomeBIC],
        [OwnIncomeParams.ownMicroenterpriseIncomeBNC]: rawData[OwnIncomeParams.ownMicroenterpriseIncomeBNC],
        [OwnIncomeParams.ownMicroenterpriseIncomeBICPurchaseSale]:
                rawData[OwnIncomeParams.ownMicroenterpriseIncomeBICPurchaseSale],
        [OwnIncomeParams.ownPER]: rawData[OwnIncomeParams.ownPER],

        /* My Spouse Income */
        [SpouseIncomeParams.spouseWageIncome]: rawData[SpouseIncomeParams.spouseWageIncome],
        [SpouseIncomeParams.spouseFranceTravail]: rawData[SpouseIncomeParams.spouseFranceTravail],
        [SpouseIncomeParams.spouseManagerIncome]: rawData[SpouseIncomeParams.spouseManagerIncome],
        [SpouseIncomeParams.spouseMicroenterpriseIncomeBIC]:
                rawData[SpouseIncomeParams.spouseMicroenterpriseIncomeBIC],
        [SpouseIncomeParams.spouseMicroenterpriseIncomeBNC]:
                rawData[SpouseIncomeParams.spouseMicroenterpriseIncomeBNC],
        [SpouseIncomeParams.spouseMicroenterpriseIncomeBICPurchaseSale]:
                rawData[SpouseIncomeParams.spouseMicroenterpriseIncomeBICPurchaseSale],
    };
};

const getDefaultDaysWorked = companyStartDate => {
    const startDate = moment(companyStartDate, BE_DATE_FORMAT);

    const yearStart = moment(new Date()).startOf('year');
    const yearEnd = moment(new Date()).endOf('year');

    if (startDate.isBefore(yearStart)) {
        /* This value comes from HPD-5287 specification */
        return 218;
    }

    /* Calculate working days in a year */
    const workingDays = getWorkingDaysInRange(
        startDate,
        yearEnd,
        date => !isPublicHoliday(date),
    );

    return workingDays.length;
};

export const getIncomeTaxFormDefaultValues = companyStartDate => {
    return {
        /* My Home */
        [MyHomeParams.numberOfAdults]: 1,
        [MyHomeParams.fiscalParts]: 1,

        /* My Activity */
        [MyActivityParams.dailyRate]: 0,
        // TODO: Calculate default value based on info in epic
        [MyActivityParams.daysWorked]: getDefaultDaysWorked(companyStartDate),
        [MyActivityParams.meanMonthlyExpenses]: 1100,

        /* My Own Income */
        [OwnIncomeParams.ownWageIncome]: 0,
        [OwnIncomeParams.ownFranceTravail]: 0,
        [OwnIncomeParams.ownManagerIncome]: 0,
        [OwnIncomeParams.ownMicroenterpriseIncomeBIC]: 0,
        [OwnIncomeParams.ownMicroenterpriseIncomeBNC]: 0,
        [OwnIncomeParams.ownMicroenterpriseIncomeBICPurchaseSale]: 0,
        [OwnIncomeParams.ownPER]: 0,

        /* My Spouse */
        [SpouseIncomeParams.spouseWageIncome]: 0,
        [SpouseIncomeParams.spouseFranceTravail]: 0,
        [SpouseIncomeParams.spouseManagerIncome]: 0,
        [SpouseIncomeParams.spouseMicroenterpriseIncomeBIC]: 0,
        [SpouseIncomeParams.spouseMicroenterpriseIncomeBNC]: 0,
        [SpouseIncomeParams.spouseMicroenterpriseIncomeBICPurchaseSale]: 0,
    };
};
