import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDynamicIcon} from '../../../../../../../../hooks/useDynamicIcon';

const DesktopPopularCategory = ({faIcon, name, onClick}) => {
    const dynamicIcon = useDynamicIcon(faIcon);

    return (
        <Box
            onClick={onClick}
            sx={{
                p: 1,
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                backgroundColor: 'v2.light.primary.shades12',
                borderRadius: 2,
                cursor: 'pointer',
            }}
        >
            <FontAwesomeIcon icon={dynamicIcon} />
            <Typography
                component="span"
                sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    lineHeight: theme => theme.typography.pxToRem(18),
                    letterSpacing: '0.17px',
                    fontWeight: 400,
                    color: 'text_v2.secondary',
                    flex: '1 0 0',
                }}
            >
                {name}
            </Typography>
        </Box>
    );
};

DesktopPopularCategory.propTypes = {
    faIcon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default DesktopPopularCategory;
