import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useConfirmationDialogStyles} from './styles';
import CloseButton from '../../../../components/buttons/CloseButton';

const ConfirmationDialog = props => {
    const {t} = useTranslation('common');

    const styles = useConfirmationDialogStyles();

    const {
        onCancel,
        onConfirm,
        isOpen,
        cancelButtonLabel,
        confirmButtonLabel,
        isCancelButtonDisabled,
        isConfirmButtonDisabled,
        isLoading,
        title,
        message,
        isFromModal,
        hasArrows,
    } = props;

    const handleClose = useCallback((event, reason) => {
        if (reason === 'backdropClick' && (isCancelButtonDisabled || isLoading)) {
            return;
        }
        onCancel(event);
    }, [isCancelButtonDisabled, isLoading, onCancel]);

    useEffect(() => {
        if (isOpen) {
            document.getElementById('app-content').style.filter = 'blur(12px)';
        }

        return () => {
            document.getElementById('app-content').style.filter = '';
        };
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            classes={{
                paper: styles.paper,
            }}
            elevation={24}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(241, 244, 246, 0.1)',
                },
            }}
            disableEscapeKeyDown={isLoading}
            disableBackdropClick
            disableRestoreFocus
            sx={isFromModal ? {
                '&.MuiDialog-root': {
                    backgroundColor: 'rgba(241, 244, 246, 0.9)',
                },
            } : {}}
        >
            <Box sx={{position: 'absolute', right: '8px', top: '8px'}}>
                <CloseButton onClose={onCancel} isDisabled={isCancelButtonDisabled || isLoading} />
            </Box>

            <DialogTitle id="alert-dialog-title" className={styles.title}>
                {title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText component="div" id="alert-dialog-description">
                    <Typography className={styles.messageLabel}>
                        {message}
                    </Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions
                classes={{
                    spacing: styles.dialogActionsSpacing,
                }}
            >
                <Button
                    onClick={onCancel}
                    size="large"
                    variant="contained"
                    color="blueGray"
                    disableElevation
                    disabled={isCancelButtonDisabled || isLoading}
                    startIcon={hasArrows ? <ArrowBackIcon /> : null}
                >
                    {cancelButtonLabel || t('common:cancel')}
                </Button>

                <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="large"
                    loading={isLoading}
                    disabled={isConfirmButtonDisabled}
                    endIcon={hasArrows ? <ArrowForwardIcon /> : null}
                    onClick={onConfirm}
                    disableElevation
                    autoFocus
                >
                    {confirmButtonLabel || t('common:confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    cancelButtonLabel: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    isCancelButtonDisabled: PropTypes.bool,
    isConfirmButtonDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isFromModal: PropTypes.bool,
    hasArrows: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
    cancelButtonLabel: '',
    confirmButtonLabel: '',
    isCancelButtonDisabled: false,
    isConfirmButtonDisabled: false,
    isLoading: false,
    isFromModal: false,
    hasArrows: false,
};

export default ConfirmationDialog;
