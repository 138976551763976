import PropTypes from 'prop-types';
import {Amount} from './Amount';
import {EmptyState} from './EmptyState';
import {isBiAnnual} from '../../utils';

export const NonCategorized = ({rowData}) => {
    const isOverLimit = rowData.totalUncategorized > 3000; // TODO: This logic should come from backend
    const styles = isOverLimit ? {color: 'warning_v2.dark'} : {};
    if (isBiAnnual(rowData)) {
        return <EmptyState />;
    }
    return <Amount sx={styles} amount={rowData.totalUncategorized} />;
};

NonCategorized.propTypes = {
    rowData: PropTypes.object.isRequired,
};
