import moment from 'moment';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';
import {BE_DATE_FORMAT} from '../../../utils/constants';
import {UserRoles} from '../../../utils/user-roles';

const MyBid = function (dto) {
    this.id = dto.id;
    this.accepted = dto.accepted;
    this.clientName = dto.client_name;
    this.createdAt = dto.created_at;
    this.freelancerId = dto.freelancer_id;
    this.freelancerWonBidding = dto.freelancer_won_bidding;
    this.jobSpecializations = dto.job_specializations;
    this.jobType = dto.job_type;
    this.opportunityId = dto.opportunity_id;
    this.updatedAt = dto.updated_at;
};

const Bids = function (dto) {
    this.bidId = dto.bid_id;
    this.accepted = dto.accepted;
    this.email = dto.email;
    this.firstName = dto.first_name;
    this.freelancerId = dto.freelancer_id;
    this.lastName = dto.last_name;
    this.opportunityId = dto.opportunity_id;
    this.phone = dto.phone;
};

const Opportunity = function (dto) {
    this.id = dto.id;
    this.accepted = dto.accepted;
    this.freelancerId = dto.freelancer_id;
    this.freelancerBid = dto.freelancer_bid;
    this.additionalMissionDocIds = dto.additional_mission_doc_ids;
    this.additionalMissionDocs = dto.additional_mission_docs;
    this.clientName = dto.client_name;
    this.createdAt = dto.created_at;
    this.creatorCompanyName = dto.creator_company_name;
    this.creatorFirstName = dto.creator_first_name;
    this.creatorLastName = dto.creator_last_name;
    this.dailyRateHigh = dto.daily_rate_high;
    this.dailyRateLow = dto.daily_rate_low;
    this.jobLocation = dto.job_location;
    this.jobSpecializations = dto.job_specializations; //
    this.jobType = dto.job_type;
    this.missionDescription = dto.mission_description;
    this.missionDescriptionDoc = dto.mission_description_doc;
    this.missionDescriptionDocId = dto.mission_description_doc_id;
    this.remoteJobType = dto.remote_job_type;
    this.startDate = dto.start_date;
    this.updatedAt = dto.updated_at;

    this.numberOfBids = dto.number_of_bids;
    this.bids = Array.isArray(dto.bids) && dto.bids[0] ? dto.bids.map(bid => new Bids(bid)) : dto.bids;
};

const getFreelancerOpportunityCluster = ({id, params}) => {
    return hiwayApiInstance({
        method: 'GET',
        params,
        url: `/v1/freelancers/${id}/opportunities`,
    }).then(({data}) => {
        const {data: records, totalRecords} = data ?? {};
        return {
            ids: records.map(dto => dto.id),
            totalRecords,
            entities: records.reduce((accumulator, current) => {
                accumulator[current.id] = new Opportunity(current);

                return accumulator;
            }, {}),
        };
    });
};

const getFreelancerOpportunityList = ({id, params}) => {
    return hiwayApiInstance({
        method: 'GET',
        params,
        url: `/v1/freelancers/${id}/opportunities`,
    }).then(({data}) => {
        const {data: records, totalRecords} = data ?? {};
        return {
            items: records.map(current => new Opportunity(current)),
            totalRecords,
        };
    });
};

const getFreelancerOtherOpportunityList = ({id, params}) => {
    return hiwayApiInstance({
        method: 'GET',
        params,
        url: `/v1/freelancers/${id}/other-freelancers-opportunities`,
    }).then(({data}) => {
        const {data: records, totalRecords} = data ?? {};
        return {
            items: records.map(current => new Opportunity(current)),
            totalRecords,
        };
    });
};

const getMyBidsList = id => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${id}/bids`,
    }).then(({data}) => data.map(bid => new MyBid(bid)));
};

const submitOpportunityCluster = ({freelancerId, data}) => {
    const formData = new FormData();

    if (data.files && data.files.length > 0) {
        data.files.forEach(file => {
            formData.append('additional_mission_docs', file);
        });
    }

    formData.append('client_name', data.clientName);

    if (data.venue) {
        formData.append('job_location', data.venue);
    }

    if (data.description) {
        formData.append('mission_description', data.description);
    }

    data.requiredProfile.forEach(profile => {
        formData.append('job_type[]', profile);
    });
    if (data.specialisations?.length > 0) {
        data.specialisations.forEach(specialisation => {
            formData.append('job_specializations[]', specialisation);
        });
    }

    if (data.beforeTaxes) {
        const beforeTaxes = data.beforeTaxes.split('-');
        formData.append('daily_rate_low', beforeTaxes.length === 1 ? 0 : beforeTaxes[0]);
        formData.append('daily_rate_high', beforeTaxes.length === 1 ? beforeTaxes[0] : beforeTaxes[1]);
    }

    if (data.startMonth) {
        const startDate = moment(data.startMonth, BE_DATE_FORMAT).endOf('month');
        formData.append('start_date', startDate.format(BE_DATE_FORMAT));
    }

    if (data.remote) {
        formData.append('remote_job_type', data.remote);
    }

    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/opportunities`,
        data: formData,
    }).then(result => result.data);
};

const getSpecialisations = () => {
    return hiwayApiInstance.get('/v1/freelancers/profession-details', {
        params: {
            role: UserRoles.FREELANCER,
        },
    }).then(result => {
        return result.data;
    });
};

const applyFreelancerOpportunity = (freelancerId, opportunityId) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/opportunities/${opportunityId}/bid`,
    }).then(result => {
        return result;
    });
};

const unapplyFreelancerOpportunity = (freelancerId, opportunityId) => {
    return hiwayApiInstance({
        method: 'DELETE',
        url: `/v1/freelancers/${freelancerId}/opportunities/${opportunityId}/bid`,
    }).then(result => {
        return result;
    });
};

const getAdminCluster = ({params}) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/managed-opportunities`,
        params,
    }).then(({data}) => {
        const {data: records, totalRecords} = data ?? {};
        return {
            ids: records.map(dto => dto.id),
            totalRecords,
            entities: records.reduce((accumulator, current) => {
                accumulator[current.id] = new Opportunity(current);

                return accumulator;
            }, {}),
        };
    });
};

const searchFreelancer = (text, limit) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/search?search_query=${text}&limit=${limit}`,
    }).then(result => {
        return result.data;
    });
};

// eslint-disable-next-line camelcase
const toggleAccept = ({freelancerId, opportunityId, bidId}) => {
    return hiwayApiInstance({
        method: 'PUT',
        // eslint-disable-next-line camelcase
        url: `/v1/freelancers/${freelancerId}/opportunities/${opportunityId}/bid/${bidId}/toggle-accept`,
    }).then(result => {
        return result.data;
    });
};

export const OpportunityApi = {
    getFreelancerOpportunityList,
    getFreelancerOtherOpportunityList,
    getMyBidsList,
    getFreelancerOpportunityCluster,
    submitOpportunityCluster,
    getSpecialisations,
    applyFreelancerOpportunity,
    unapplyFreelancerOpportunity,
    getAdminCluster,
    searchFreelancer,
    toggleAccept,
};
