import {SigningActionTypes} from './signing.action-type';

export const SigningActions = {
    setCurrentDocumentUrl: url => ({
        type: SigningActionTypes.SET_CURRENT_DOCUMENT_URL,
        payload: url,
    }),
    setCurrentDocumentId: id => ({
        type: SigningActionTypes.SET_CURRENT_DOCUMENT_ID,
        payload: id,
    }),
    setCurrentDocumentIsSigned: isSigned => ({
        type: SigningActionTypes.SET_CURRENT_DOCUMENT_IS_SIGNED,
        payload: isSigned,
    }),
    openNextDocument: () => ({
        type: SigningActionTypes.OPEN_NEXT_DOCUMENT,
    }),
    openNextTrainingDocument: () => ({
        type: SigningActionTypes.OPEN_NEXT_TRAINING_DOCUMENT,
    }),
    signContract: () => ({
        type: SigningActionTypes.SIGN_CONTRACT,
    }),
    setCurrentCompanyDocument: documentId => ({
        type: SigningActionTypes.SET_CURRENT_COMPANY_DOCUMENT,
        payload: documentId,
    }),
    setIsLoadingCurrentDocument: isLoading => ({
        type: SigningActionTypes.SET_IS_LOADING_CURRENT_DOCUMENT,
        payload: isLoading,
    }),
    signCurrentDocument: () => ({
        type: SigningActionTypes.SIGN_CURRENT_DOCUMENT,
    }),
    setIsSigningCurrentDocument: isSigning => ({
        type: SigningActionTypes.SET_IS_SIGNING_CURRENT_DOCUMENT,
        payload: isSigning,
    }),
    setIsSigningAdditionalDocuments: isSigningAdditionalDocuments => ({
        type: SigningActionTypes.SET_IS_SIGNING_ADDITIONAL_DOCUMENTS,
        payload: isSigningAdditionalDocuments,
    }),
    giveUpdatedTermsConsent: termIds => ({type: SigningActionTypes.GIVE_UPDATED_TERMS_CONSENT, payload: termIds}),
};
