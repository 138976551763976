import {Box, Option, Select, Typography} from '@mui/joy';
import {TableCell, TableFooter, TablePagination, TableRow} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useTranslation} from 'react-i18next';
import {JoyActionsComponent} from './JoyActionsComponent';
import {useDataTableFooterStyles} from './styles';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import useMediaQuery from '../../../../components/joy-ui/mui-legacy/useMediaQuery';

const DataTableFooter = ({
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    count,
    rowsPerPageOptions,
    isJoyTheme,
}) => {
    const {t} = useTranslation('common');
    const styles = useDataTableFooterStyles();
    const isMobileSize = useMediaQuery(`(max-width:625px)`);

    const currentRows = 1 + (page * rowsPerPage);
    const totalRows = Math.min(1 + (page * rowsPerPage) + rowsPerPage, count);

    if (isJoyTheme) {
        return (
            <TableFooter className={styles.root}>
                <TableRow sx={{borderBottom: 'none'}}>
                    <TableCell colSpan={100} sx={{borderBottom: 'none'}}>
                        <JoyUIThemeProvider>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    py: 1,
                                    ...(isMobileSize && {
                                        gap: 2,
                                        flexDirection: 'column',
                                    }),
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        ...(isMobileSize && {
                                            width: '100%',
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 0.6fr 0.4fr',
                                        }),
                                    }}
                                >
                                    <Typography level="body-md" textColor="text.secondary">
                                        {t('table.rowsPerPage')}
                                    </Typography>
                                    <Select
                                        variant="outlined"
                                        color="neutral"
                                        value={rowsPerPage}
                                        defaultValue={rowsPerPage}
                                        onChange={(_, value) => {
                                            // We need to form event object with target
                                            // for backwards compatibility, until we replace
                                            // all MUI with Joy
                                            onRowsPerPageChange({target: {value}});
                                        }}
                                    >
                                        {rowsPerPageOptions.map(rowsCount => {
                                            return (
                                                <Option key={rowsCount} value={rowsCount}>{rowsCount}</Option>
                                            );
                                        })}
                                    </Select>
                                    {isMobileSize && (
                                        <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5, width: '100%'}}>
                                            <Typography level="body-md" textColor="text.secondary">
                                                {currentRows}-{totalRows}
                                            </Typography>
                                            <Typography level="body-md" textColor="text.secondary">
                                                {t('of')}
                                            </Typography>
                                            <Typography level="body-md" textColor="text.secondary">
                                                {count}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
                                    {!isMobileSize && (
                                        <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                                            <Typography level="body-md" textColor="text.secondary">
                                                {currentRows}-{totalRows}
                                            </Typography>
                                            <Typography level="body-md" textColor="text.secondary">
                                                {t('of')}
                                            </Typography>
                                            <Typography level="body-md" textColor="text.secondary">
                                                {count}
                                            </Typography>
                                        </Box>
                                    )}
                                    <JoyActionsComponent
                                        page={page}
                                        onPageChange={onPageChange}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                    />
                                </Box>
                            </Box>
                        </JoyUIThemeProvider>
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }

    return (
        <TableFooter className={styles.root}>
            <TableRow>
                <TablePagination
                    className={styles.pagination}
                    count={count}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelRowsPerPage={t('pagination.rowsPerPage')}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    {...(isJoyTheme && {ActionsComponent: JoyActionsComponent})}
                    sx={{
                        'p, div': {fontFamily: 'Avantt', fontSize: '16px', fontWeight: 500},
                        '.MuiTablePagination-spacer': {display: 'none'},
                        '.MuiTablePagination-displayedRows': {
                            'marginLeft': 'auto',
                        },
                        '.MuiTablePagination-select': {
                            background: 'var(--joy-palette-background-surface)',
                            boxShadow: 'var(--joy-shadow-xs)',
                        },
                    }}
                />
            </TableRow>
        </TableFooter>
    );
};

DataTableFooter.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    isJoyTheme: PropTypes.bool,
};

DataTableFooter.defaultProps = {
    rowsPerPageOptions: [10, 25, 50],
    isJoyTheme: false,
};

export default DataTableFooter;
