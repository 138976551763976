import {
    faCheckDouble,
    faCircleExclamation,
    faDownload,
    faEdit,
    faFileLines,
    faGamepad,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Box, Button, Skeleton, Stack, Typography} from '@mui/joy';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {VatDeclarationManualEdit} from './components/manual-mode/VatDeclarationManualEdit';
import {DeclarationHistoryOverview} from './components/results/DeclarationHistoryOverview';
import {ResendBanner} from './components/results/ResendBanner';
import {ResendModal} from './components/results/ResendModal';
import {StatusAlert} from './components/results/StatusAlert';
import {UploadedDocument} from './components/UploadedDocument';
import {VatDeclarationSection} from './components/VatDeclarationSection';
import {VatDeclarationRefusalCode, VatDeclarationStatus} from './constants';
import {VATDeclarationActions} from './store/vat-declaration.action';
import {VatDeclarationSelectors} from './store/vat-declaration.selector';
import {EurAmount} from '../../components/eur-amount/EurAmount';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {importLocaleBundle} from '../../lib/i18next';
import {LoadingSelectors, LoadingTypes} from '../loading';

importLocaleBundle('vatDeclaration');

const RefusalAlert = ({refusalReason}) => {
    const {t} = useTranslation('vatDeclaration');
    const refusalDescription = [
        VatDeclarationRefusalCode.UNKNOWN_ERROR,
        VatDeclarationRefusalCode.REFUSED_IMMEDIATELY,
    ].includes(refusalReason.internalCode)
        ? t('resultsRefusedDGFIP', {message: refusalReason.integrationMessage})
        : t(`refusalMessages.${refusalReason.internalCode}`);

    return (
        <StatusAlert
            color="danger"
            icon={faTriangleExclamation}
            title={t('resultsRefusedTitle')}
            description={refusalDescription}
        />
    );
};

RefusalAlert.propTypes = {
    refusalReason: PropTypes.shape({
        internalCode: PropTypes.string.isRequired,
        integrationMessage: PropTypes.string.isRequired,
    }).isRequired,
};

export const VatDeclarationResults = () => {
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const {t} = useTranslation('vatDeclaration');
    const submittedAt = declaration?.submittedAt;
    const date = moment(submittedAt).format('DD/MM');
    const time = moment(submittedAt).format('HH[h]mm');
    const dispatch = useDispatch();
    const isEditingManually = useSelector(VatDeclarationSelectors.selectIsEditingDeclaration);
    const isManualMode = useSelector(VatDeclarationSelectors.selectIsManualMode);
    const isDocumentDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_DOWNLOAD_DOCUMENT),
    );

    useEffect(() => {
        if (isManualMode && declaration?.status === VatDeclarationStatus.TODO) {
            dispatch(VATDeclarationActions.setIsEditingDeclaration(true));
        }
    }, [isManualMode, declaration?.status, dispatch]);

    if (!declaration) {
        return (
            <JoyUIThemeProvider>
                <Stack spacing={2} width="600px" margin="0 auto">
                    {Array(4)
                        .fill(0)
                        .map((_, i) => (
                            <Skeleton key={i} variant="rectangular" height="100px" />
                        ))}
                </Stack>
            </JoyUIThemeProvider>
        );
    }

    if (isEditingManually) {
        return <VatDeclarationManualEdit />;
    }

    const amount = <EurAmount amount={declaration.totalVatToDeclare || '0.00'} />;

    const isResendAllowed = [
        VatDeclarationStatus.REFUSED,
        VatDeclarationStatus.ERROR,
        VatDeclarationStatus.PENDING_VERIFICATION,
    ].includes(declaration.status) && !declaration.isManual && !declaration.childDeclarationId;

    const handleModify = () => {
        dispatch(VATDeclarationActions.setIsEditingDeclaration(true));
    };

    const handleViewDocument = doc => {
        dispatch(
            VATDeclarationActions.downloadDocument({
                documentId: doc.id,
                declaration,
                isDownloading: false,
            }),
        );
    };

    const handleDownloadDocument = doc => {
        dispatch(
            VATDeclarationActions.downloadDocument({
                documentId: doc.id,
                declaration,
                isDownloading: true,
            }),
        );
    };

    const shouldShowStatusSection = submittedAt || declaration?.documents?.length > 0;

    return (
        <JoyUIThemeProvider>
            <ResendModal />
            <Stack spacing={2} maxWidth="600px" margin="0 auto">
                {declaration.status === VatDeclarationStatus.VALIDATED && declaration.isManual && (
                    <Alert
                        variant="outlined"
                        color="primary"
                        size="sm"
                        startDecorator={(
                            <Box fontSize="14px">
                                <FontAwesomeIcon icon={faGamepad} />
                            </Box>
                          )}
                        sx={{bgcolor: 'transparent', alignItems: 'baseline'}}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                            <Box sx={{fontSize: '14px', fontWeight: 500}}>
                                <Trans t={t} i18nKey="declarationMadeInManualMode" components={{strong: <strong />}} />
                            </Box>
                            <Button
                                variant="plain"
                                color="primary"
                                size="sm"
                                startDecorator={<FontAwesomeIcon icon={faEdit} />}
                                onClick={handleModify}
                            >
                                {t('modify')}
                            </Button>
                        </Stack>
                    </Alert>
                )}
                {declaration?.status === VatDeclarationStatus.PENDING_VERIFICATION
                    && declaration.validationResults.errors.map(errorKey => (
                        <StatusAlert
                            key={errorKey}
                            color="warning"
                            icon={faTriangleExclamation}
                            title={t('resultsWarningTitle')}
                            description={t(`validationErrors.${errorKey}`)}
                        />
                    ))}
                {declaration?.status === VatDeclarationStatus.REFUSED && (
                    <RefusalAlert refusalReason={declaration.refusalReason} />
                )}
                {declaration?.status === VatDeclarationStatus.ERROR && (
                    <StatusAlert
                        color="danger"
                        icon={faCircleExclamation}
                        title={t('resultsErrorTitle')}
                        description={t('resultsErrorDescription', {message: declaration?.internalError?.message})}
                    />
                )}
                {shouldShowStatusSection && (
                    <VatDeclarationSection title={t('declarationStatus')}>
                        <Stack spacing={2}>
                            {submittedAt && (
                                <Alert color="success" startDecorator={<FontAwesomeIcon icon={faCheckDouble} />}>
                                    <Typography level="body-md" textColor="success.softColor">
                                        <Trans
                                            t={t}
                                            i18nKey="declarationSentMessage"
                                            values={{date, time}}
                                            components={{amount}}
                                        />
                                    </Typography>
                                </Alert>
                            )}
                            {declaration.documents?.map(doc => (
                                <UploadedDocument
                                    key={doc.id}
                                    name={doc.originalFilename}
                                    actions={(
                                        <>
                                            <Button
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                startDecorator={<FontAwesomeIcon icon={faFileLines} />}
                                                fullWidth
                                                onClick={() => handleViewDocument(doc)}
                                                disabled={isDocumentDownloading}
                                            >
                                                {t('view')}
                                            </Button>
                                            <Button
                                                size="sm"
                                                color="primary"
                                                variant="solid"
                                                startDecorator={<FontAwesomeIcon icon={faDownload} />}
                                                fullWidth
                                                onClick={() => handleDownloadDocument(doc)}
                                                disabled={isDocumentDownloading}
                                            >
                                                {t('download')}
                                            </Button>
                                        </>
                                      )}
                                />
                            ))}
                        </Stack>
                    </VatDeclarationSection>
                )}
                <DeclarationHistoryOverview />
            </Stack>
            {isResendAllowed && <ResendBanner />}
        </JoyUIThemeProvider>
    );
};
