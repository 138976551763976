import {faArrowRight, faCalendarDays} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack, Typography} from '@mui/joy';
import {ThemeProvider} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {Status} from './Status';
import {Amount} from './table/Amount';
import {EmptyState} from './table/EmptyState';
import {NonCategorized} from './table/NonCategorized';
import JoyUIThemeProvider from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {RoutePaths} from '../../../lib/router/route-paths';
import {theme} from '../../../lib/theme';
import DataTable from '../../../v1/components/ui-kit/DataTable/DataTable';
import {LoadingSelectors, LoadingTypes} from '../../loading';
import {VATDeclarationActions} from '../store/vat-declaration.action';
import {VatDeclarationSelectors} from '../store/vat-declaration.selector';
import {canViewDeclaration, getCurrentDeclarationPeriod, getDeclarationPeriodString, isBiAnnual} from '../utils';

const getHistoryTableConfig = ({t, onView, isCompanyPage}) => {
    return [
        {
            key: 'month',
            title: t(isCompanyPage ? 'table.date' : 'table.month'),
            component: ({rowData}) => (
                <JoyUIThemeProvider>
                    <Stack direction="row" spacing={1}>
                        <Box sx={{color: 'neutral.400', fontSize: '12px'}}>
                            <FontAwesomeIcon icon={faCalendarDays} fixedWidth />
                        </Box>
                        <Typography level="body-sm" textColor="text.primary" fontWeight={700}>
                            {getDeclarationPeriodString(rowData)}
                        </Typography>
                    </Stack>
                </JoyUIThemeProvider>
            ),
        },
        {
            key: 'totalCollectedVat',
            title: t('table.tvaCollected'),
            component: ({rowData}) => {
                return isBiAnnual(rowData) ? <EmptyState /> : <Amount amount={rowData.totalCollectedVat} />;
            },
            isSortable: true,
        },
        {
            key: 'totalDeductibleVat',
            title: t('table.tvaDeductible'),
            component: ({rowData}) => {
                return isBiAnnual(rowData) ? <EmptyState /> : <Amount amount={rowData.totalDeductibleVat} />;
            },
            isSortable: true,
        },
        {
            key: 'totalUncategorized',
            title: t('table.nonCategorized'),
            component: NonCategorized,
            isSortable: true,
        },
        {
            key: 'totalVatToDeclare',
            title: t('table.amountDeclared'),
            component: ({rowData}) => {
                return isBiAnnual(rowData) ? <EmptyState /> : <Amount amount={rowData.totalVatToDeclare} />;
            },
            isSortable: true,
        },
        {
            key: 'status',
            title: t('table.status'),
            component: ({rowData}) => (
                <JoyUIThemeProvider>
                    <Stack alignItems="flex-start">
                        <Stack alignItems="center">
                            <Status status={rowData.status} />
                            {rowData.isManual && (
                                <Typography level="body-xs" textColor="text.tertiary" lineHeight={1.66} mb={-1}>
                                    {t('manualModeRowItemIndicator')}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </JoyUIThemeProvider>
            ),
            isSortable: true,
        },
        {
            key: 'view',
            title: '',
            component: ({rowData}) => (
                <JoyUIThemeProvider>
                    <Button
                        disabled={!canViewDeclaration(rowData)}
                        endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                        size="sm"
                        color="primary"
                        variant="solid"
                        component={Link}
                        onClick={onView}
                        to={generatePath(RoutePaths.VAT_DECLARATION_RESULTS, {declarationId: rowData.id})}
                    >
                        {t('view')}
                    </Button>
                </JoyUIThemeProvider>
            ),
        },
    ];
};

export const DeclarationHistoryTable = ({companyId, isCompanyPage, onView}) => {
    const {t} = useTranslation('vatDeclaration');
    const columnConfig = useMemo(() => getHistoryTableConfig({t, onView, isCompanyPage}), [t, onView, isCompanyPage]);
    const dataSelector = VatDeclarationSelectors.createDeclarationHistorySelector;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            VATDeclarationActions.getDeclarationHistory({
                companyId,
                year: isCompanyPage ? null : getCurrentDeclarationPeriod().year(),
                hasPagination: isCompanyPage,
            }),
        );
    }, [dispatch, companyId, isCompanyPage]);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_HISTORY));

    if (!companyId) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <DataTable
                tableId="vatDeclarationHistory"
                dataSelector={dataSelector}
                columnConfig={columnConfig}
                hasPaper={false}
                isLoading={isLoading}
                isJoyTheme
                hasPagination={isCompanyPage}
                rowsPerPageOptions={[10, 25]}
                defaultRowsPerPage={10}
            />
        </ThemeProvider>
    );
};

DeclarationHistoryTable.propTypes = {
    companyId: PropTypes.string.isRequired,
    onView: PropTypes.func,
    isCompanyPage: PropTypes.bool,
};

DeclarationHistoryTable.defaultProps = {
    onView: undefined,
    isCompanyPage: false,
};
