import {
    faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Chip, ChipDelete} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';
import {TransactionsActions} from '../../../store/transactions.action';
import {TRANSACTION_CATEGORIZATION_SCREENS, TVA_ENUM} from '../../../util/constants';

const Vat = ({data, isEditable}) => {
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();

    const vatList = data.vat;
    const isSingle = vatList.length === 1 && vatList[0] !== TVA_ENUM.NO_VAT;

    const translated = vatList.map(vat => {
        if (vat === TVA_ENUM.TVA_CUSTOM) {
            return t(`vat.TVA_CUSTOM_IN_TABLE`);
        }
        return t(`vat.${vat}`);
    }).join(' • ');

    if (!translated) {
        return null;
    }

    const onVATClick = e => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(TransactionsActions.setCategorizationScreenInfo({
            screen: TRANSACTION_CATEGORIZATION_SCREENS.TVA,
            transactionId: data?.id,
        }));

        dispatch(TransactionsActions.addToCategorisationList({
            transactionId: data?.id,
            hasSpecialHandlingIfCategorised: true,
        }));
    };

    const onDocumentClick = e => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(TransactionsActions.setCategorizationScreenInfo({
            screen: TRANSACTION_CATEGORIZATION_SCREENS.DOCUMENT,
            transactionId: data?.id,
        }));

        dispatch(TransactionsActions.addToCategorisationList({
            transactionId: data?.id,
            hasSpecialHandlingIfCategorised: true,
        }));
    };

    return (
        <Chip
            onClick={onVATClick}
            color="neutral"
            size="md"
            variant="plain"
            sx={{
                'border': '1px solid',
                'borderColor': 'background.level1',
                'backgroundColor': 'neutral.solidColor',
                'px': 1,
                '&:hover': {
                    borderColor: isSingle && isEditable ? 'neutral.outlinedBorder' : 'transparent',
                },
                '& > .MuiChip-action:hover': {
                    backgroundColor: 'neutral.solidColor',
                },
            }}
            endDecorator={data?.documents?.length ? (
                <ChipDelete
                    color="neutral"
                    variant="plain"
                    onClick={onDocumentClick}
                    sx={{
                        '&.MuiChipDelete-root:hover': {
                            backgroundColor: 'neutral.solidColor',
                        },
                    }}
                >
                    <ConditionalTooltip
                        tooltip={`${data.documents.length} document`}
                        isV3
                    >
                        <Box
                            fontSize="md"
                            sx={{
                                'color': 'text.tertiary',
                                '&:hover': {
                                    color: 'text.cocoa',
                                },
                            }}

                        >
                            <FontAwesomeIcon
                                icon={faPaperclip}
                                fixedWidth
                            />
                        </Box>
                    </ConditionalTooltip>
                </ChipDelete>
            ) : null}
        >
            {t('accountBalance.vat')}
            <Box
                component="span"
                sx={{
                    fontWeight: 'xl',
                    color: 'inherit',
                }}
            >
                {translated}
            </Box>
        </Chip>
    );
};

Vat.propTypes = {
    data: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

export default Vat;
