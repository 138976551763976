/* eslint-disable no-console */
import {CONFIG} from '../../../config';

export const loggerMiddleware = store => next => action => {
    if (!CONFIG.NO_LOGGING) {
        console.group(action.type);
        console.debug('[LoggerMiddleware] Dispatching: ', action);

        if (action?.payload) {
            console.info('Payload: ', action.payload);
        }
    }

    const result = next(action);

    if (!CONFIG.NO_LOGGING) {
        console.debug('[LoggerMiddleware] Next state: ', store.getState());
        console.groupEnd();
    }

    return result;
};
