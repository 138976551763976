import Yup from '../../../../../lib/yup/yup';
import {DefaultNumericFormat} from '../../../../../utils/numeric-format-adapters';

export const INCOME_TAX_PARAMETERS = {
    ADULTS: 'ADULTS',
    TAX_SHARES: 'TAX_SHARES',
    OTHER_INCOME: 'OTHER_INCOME',
    JOINT_INCOME: 'JOINT_INCOME',
};

export const INCOME_TAX_MOBILE_SIZE = '767px';

export const IncomeTaxParamSteps = {
    MyHome: 'MyHome',
    MyActivity: 'MyActivity',
    OwnIncome: 'OwnIncome',
    SpouseIncome: 'SpouseIncome',
};

export const MyHomeParams = {
    numberOfAdults: 'numberOfAdults',
    fiscalParts: 'fiscalParts',
};

export const MyActivityParams = {
    dailyRate: 'dailyRate',
    daysWorked: 'daysWorked',
    caForecast: 'caForecast', // calculated locally
    meanMonthlyExpenses: 'meanMonthlyExpenses',
    annualForecast: 'annualForecast', // calculated locally
    estimatedBNC: 'estimatedBNC', // calculated locally
};

export const OwnIncomeParams = {
    ownWageIncome: 'ownWageIncome',
    ownFranceTravail: 'ownFranceTravail',
    otherIncome: 'otherIncome', // calculated locally
    ownManagerIncome: 'ownManagerIncome',
    ownMicroenterpriseIncomeBIC: 'ownMicroenterpriseIncomeBIC',
    ownMicroenterpriseIncomeBNC: 'ownMicroenterpriseIncomeBNC',
    ownMicroenterpriseIncomeBICPurchaseSale: 'ownMicroenterpriseIncomeBICPurchaseSale',
    ownPER: 'ownPER',
};

export const SpouseIncomeParams = {
    spouseWageIncome: 'spouseWageIncome',
    spouseFranceTravail: 'spouseFranceTravail',
    spouseOtherIncome: 'spouseOtherIncome', // calculated locally
    spouseManagerIncome: 'spouseManagerIncome',
    spouseMicroenterpriseIncomeBIC: 'spouseMicroenterpriseIncomeBIC',
    spouseMicroenterpriseIncomeBNC: 'spouseMicroenterpriseIncomeBNC',
    spouseMicroenterpriseIncomeBICPurchaseSale: 'spouseMicroenterpriseIncomeBICPurchaseSale',
};

export const ME_OPTIONS = {
    BIC: 'BIC',
    BNC: 'BNC',
    PURCHASE_RESALE: 'PURCHASE_RESALE',
};

export const incomeTaxSimulationOutputEmpty = {
    monthlyTaxAmounts: Array(12).fill(0),
    totalTaxHousehold: 0,
};

export const FISCAL_PARTS_STEP = 0.5;
export const MAX_NUMBER_INPUT_VALUE = 10_000_000;

export const createSimulatorValidationSchema = () => Yup.object().shape({
    /* Home */
    [MyHomeParams.numberOfAdults]: Yup.number().min(1).max(2).required(),
    [MyHomeParams.fiscalParts]: Yup
        .number()
        .when(MyHomeParams.numberOfAdults, numOfAdults => {
            return Yup.number().min(numOfAdults).max(20).test(value => value % FISCAL_PARTS_STEP === 0);
        })
        .required(),

    /* My Activity */
    [MyActivityParams.dailyRate]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [MyActivityParams.daysWorked]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [MyActivityParams.meanMonthlyExpenses]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),

    /* My own income */
    [OwnIncomeParams.ownWageIncome]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [OwnIncomeParams.ownFranceTravail]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [OwnIncomeParams.ownManagerIncome]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [OwnIncomeParams.ownMicroenterpriseIncomeBIC]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [OwnIncomeParams.ownMicroenterpriseIncomeBNC]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [OwnIncomeParams.ownMicroenterpriseIncomeBICPurchaseSale]:
    Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [OwnIncomeParams.ownPER]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),

    /* My Spouse */
    [SpouseIncomeParams.spouseWageIncome]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [SpouseIncomeParams.spouseFranceTravail]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [SpouseIncomeParams.spouseManagerIncome]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [SpouseIncomeParams.spouseMicroenterpriseIncomeBIC]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [SpouseIncomeParams.spouseMicroenterpriseIncomeBNC]: Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
    [SpouseIncomeParams.spouseMicroenterpriseIncomeBICPurchaseSale]:
    Yup.number().min(0).max(MAX_NUMBER_INPUT_VALUE).required(),
});

export const inputSlotProps = {
    input: {
        component: DefaultNumericFormat,
        min: 0,
        max: MAX_NUMBER_INPUT_VALUE,
        allowNegative: false,
        allowLeadingZeros: false,
    },
};
