export const SigningActionTypes = {
    SET_CURRENT_DOCUMENT_URL: 'document.signing/SET_CURRENT_DOCUMENT_URL',
    SET_CURRENT_DOCUMENT_ID: 'document.signing/SET_CURRENT_DOCUMENT_ID',
    SET_CURRENT_DOCUMENT_IS_SIGNED: 'document.signing/SET_CURRENT_DOCUMENT_IS_SIGNED',
    SIGN_CONTRACT: 'document.signing/SIGN_CONTRACT',
    OPEN_NEXT_DOCUMENT: 'document.signing/OPEN_NEXT_DOCUMENT',
    OPEN_NEXT_TRAINING_DOCUMENT: 'document.signing/OPEN_NEXT_TRAINING_DOCUMENT',
    SET_CURRENT_COMPANY_DOCUMENT: 'document.signing/SET_CURRENT_COMPANY_DOCUMENT',
    SIGN_CURRENT_DOCUMENT: 'document.signing/SIGN_CURRENT_DOCUMENT',
    STORE_CURRENT_DOCUMENT_URL: 'document.signing/STORE_CURRENT_DOCUMENT_URL',
    SET_IS_LOADING_CURRENT_DOCUMENT: 'document.signing/SET_IS_LOADING_CURRENT_DOCUMENT',
    SET_IS_SIGNING_CURRENT_DOCUMENT: 'document.signing/SET_IS_SIGNING_CURRENT_DOCUMENT',
    SET_IS_SIGNING_ADDITIONAL_DOCUMENTS: 'document.signing/SET_IS_SIGNING_ADDITIONAL_DOCUMENTS',
    GIVE_UPDATED_TERMS_CONSENT: 'document.signing/GIVE_UPDATED_TERMS_CONSENT',
};
