import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {Box, CircularProgress, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDynamicIcon} from '../../../../../../../../hooks/useDynamicIcon';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {emptyFunction} from '../../../../util/constants';
import {findCategoryByEnum} from '../../utils/category';

const FakeReimbursementTransaction = ({
    details,
    category,
    setIsFakeReimbursementOpen,
    amount,
    hasDelete,
}) => {
    const dispatch = useDispatch();
    const categoryList = useSelector(TransactionsSelector.selectCategories);

    const isInProgress = useLoadingState(LoadingTypes.BANK_TRANSACTION_FIELD);

    const categoryFromList = findCategoryByEnum(categoryList, category);

    const isSelected = true;
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const onOpen = () => {
        setIsFakeReimbursementOpen(true);
    };

    const onDelete = () => {
        dispatch(TransactionsActions.saveTransactionField({
            id: details.id,
            fieldKey: 'fakeReimbursedTransactions',
            fieldValue: [],
            accountId: details?.bankAccountId,
        }));
    };

    const dynamicIcon = useDynamicIcon(categoryFromList.faIcon);

    if (!categoryFromList) {
        return null;
    }

    return (
        <Box
            sx={{
                p: 1,
                py: isMobileSize ? 2 : 1,
                color: isSelected ? '#fff' : 'text_v2.secondary',
                backgroundColor: isSelected ? 'primary_v2.main' : 'transparent',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: hasDelete ? 'default' : 'pointer',
                width: '100%',
                [`&:hover`]: {
                    backgroundColor: isSelected ? 'primary_v2.main' : 'rgba(25, 118, 210, 0.04)',
                },
            }}
            onClick={hasDelete ? emptyFunction : onOpen}
        >
            <Box sx={{
                'display': 'flex',
                'gap': 2,
                'alignItems': 'center',
            }}
            >
                <FontAwesomeIcon icon={dynamicIcon} />
                <Typography
                    sx={{
                        fontWeight: 500,
                        color: isSelected ? '#fff' : 'text_v2.secondary',
                    }}
                >
                    {categoryFromList[language].categoryName} {hasDelete && (
                        <>
                            • {amount} €
                        </>
                    )}
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
            }}
            >
                {isInProgress && (
                    <CircularProgress
                        size={20}
                        sx={{
                            color: 'white',
                        }}
                    />
                )}
                {!isInProgress && (
                    <>
                        {hasDelete ? (
                            <CloseIcon
                                sx={{
                                    cursor: 'pointer',
                                }}
                                onClick={onDelete}
                            />
                        ) : (
                            <EditIcon />
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

FakeReimbursementTransaction.propTypes = {
    details: PropTypes.object,
    category: PropTypes.string.isRequired,
    setIsFakeReimbursementOpen: PropTypes.func,
    amount: PropTypes.string.isRequired,
    hasDelete: PropTypes.bool.isRequired,
};

FakeReimbursementTransaction.defaultProps = {
    details: {},
    setIsFakeReimbursementOpen: emptyFunction,
};

export default FakeReimbursementTransaction;
