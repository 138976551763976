import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Box, CircularProgress} from '@mui/joy';
import {Button} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {UiActions} from '../../../../features/ui/store/ui.action';
import {ModalsKeys} from '../../../../features/ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {isUserCareOrFreelancer} from '../../../../utils/user-roles';
import PageHeader from '../../app/PageHeader/PageHeader';
import UploadDocumentsModal from '../../ui-kit/UploadDocumentsModal/UploadDocumentsModal';
import DocumentsQuickAccess from '../documents-quick-access/DocumentsQuickAccess';
import DocumentsTable from '../DocumentsTable/DocumentsTable';

const DocumentsView = () => {
    const {t} = useTranslation('documents');

    const dispatch = useDispatch();
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const handleUploadButtonClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_DOCUMENTS, true));
    };

    if (!loggedInUser) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" minHeight={250}>
                <CircularProgress size="md" />
            </Box>
        );
    }

    return (
        <>
            <PageHeader
                title={t('documents:documentsDatabase')}
                buttonComponent={(
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={handleUploadButtonClick}
                        startIcon={<AddCircleIcon />}
                        sx={{height: '36px', borderRadius: 2}}
                    >
                        {t('uploadDocuments')}
                    </Button>
                )}
            />
            {(isUserCareOrFreelancer(loggedInUser) && loggedInUser?.isFullyActive) && <DocumentsQuickAccess />}

            <DocumentsTable />

            <UploadDocumentsModal />
        </>
    );
};

export default DocumentsView;
