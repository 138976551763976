import {faReceipt} from '@fortawesome/free-solid-svg-icons';
import {useEffect, useState} from 'react';
import {capitalizeFirstLetter} from '../features/dashboard-v3/utils/constants';

const getFaIcon = async ({type, icon}) => {
    if (!type || !icon) {
        return null;
    }

    const faIconName = `fa${icon.split('-').reduce((name, part) => `${name}${capitalizeFirstLetter(part)}`, '')}`;
    const faIconType = type ?? 'solid';

    const {[faIconName]: importedIcon} = await import(`@fortawesome/free-${faIconType}-svg-icons`);

    return importedIcon;
};

const useDynamicIcon = iconData => {
    const [faIcon, setFaIcon] = useState(null);

    useEffect(() => {
        getFaIcon({type: iconData?.type, icon: iconData?.icon}).then(importedIcon => {
            setFaIcon(importedIcon);
        });
    }, [iconData]);

    return faIcon ?? faReceipt;
};

export {
    useDynamicIcon,
};
