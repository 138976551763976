import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneIcon from '@mui/icons-material/Done';
import EuroIcon from '@mui/icons-material/Euro';
import {LoadingButton, LocalizationProvider} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import {
    Alert,
    Autocomplete,
    Box, Button,
    CircularProgress, FormControl,
    FormControlLabel,
    InputAdornment, InputLabel,
    MenuItem, Select, Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import debounce from 'lodash.debounce';
import moment from 'moment';
import momentTz from 'moment-timezone';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ExternalInvoiceFileDetails from './ExternalInvoiceFIleDetails';
import {DEFAULT_TIME_ZONE} from '../../../../../../../../utils/constants';
import {selectCompany} from '../../../../../../../../v1/app/company/company.selectors';
import {countryCodes} from '../../../../../../../../v1/utils/country-codes';
import {InvoiceActions} from '../../../../../../../invoicing/store/invoice.actions';
import {InvoiceSelector} from '../../../../../../../invoicing/store/invoice.selector';
import {
    VALUE_ADDED_TAX_OPTIONS,
    VALUE_ADDED_TAX_OPTIONS_MULTIPLIER,
} from '../../../../../../../invoicing/util/constants';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {UiActions} from '../../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';
import {SECTION_STATUS} from '../../utils/constants';
import {createExternalInvoiceValidationSchema, externalInvoiceFormElements} from '../../utils/validation';
import CategorisationSection from '../header/CategorisationSection';
import ConfirmExternalInvoiceClose from '../modals/ConfirmExternalInvoiceClose';

const defaultValues = {
    'name': '',
    'countryCode': '',
    'vatType': '',
    'docNumber': '',
    'date': '',
    'dueDate': '',
    'totalAmount': '',
    'hasFee': false,
    'totalFeeAmount': 0,
    'feeVatType': VALUE_ADDED_TAX_OPTIONS[0],
    'feePartnerName': '',
    'feePartnerInvoiceDocNumber': '',
};

const ExternalInvoiceForm = ({details, setIsExternalOpen}) => {
    const accountId = details?.bankAccountId;
    const transactionId = details?.id;

    const dispatch = useDispatch();
    const {t} = useTranslation('invoicingNew');

    const menuItemsVAT = VALUE_ADDED_TAX_OPTIONS.map(type => (
        <MenuItem
            value={type}
            key={type}
        >
            {t(`vatOptions.${type}`)}
        </MenuItem>
    ));

    const [fileList, setFileList] = useState([]);
    const [feeFileList, setFeeFileList] = useState([]);

    const [isLastExternalShown, setIsLastExternalShown] = useState(true);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const company = useSelector(selectCompany);

    const lastExternalInvoice = useSelector(InvoiceSelector.selectLastExternalInvoice);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.CREATE_EXTERNAL_INVOICE),
    );

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors, isDirty},
        watch,
        setValue,
        reset,
        trigger,
    } = useForm({
        resolver: yupResolver(createExternalInvoiceValidationSchema(t)),
        defaultValues,
        mode: 'onChange',
    });

    useEffect(() => {
        dispatch(InvoiceActions.getLastExternalInvoices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isLoading && isValid) {
            setIsExternalOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const onExternalImport = () => {
        reset({
            name: lastExternalInvoice?.client.name,
            countryCode: lastExternalInvoice?.client.countryCode,
            vatType: lastExternalInvoice?.client.vatType,
            docNumber: defaultValues?.docNumber,
            date: defaultValues?.date,
            dueDate: defaultValues?.dueDate,
            totalAmount: defaultValues?.totalAmount,
            hasFee: !!lastExternalInvoice.feeData,
            feeVatType: lastExternalInvoice?.feeData?.vatType,
            feePartnerName: lastExternalInvoice?.feeData?.partnerName,
        });

        setIsLastExternalShown(false);
    };

    const handleSubmit = data => {
        const {date, dueDate} = data;

        if (details?.isCategorized) {
            return;
        }

        dispatch(InvoiceActions.createExternalInvoice({
            companyId: company?.id,
            ...data,
            date: moment(date).format('YYYY-MM-DD'),
            dueDate: moment(dueDate).format('YYYY-MM-DD'),
            transactionId,
            invoiceDocument: fileList[0],
            partnerInvoiceDocument: feeFileList[0],
            accountId,
            autoAttachDetails: details,
        }));
    };

    const form = watch();

    const vatType = form[externalInvoiceFormElements.vatType];
    const countryCode = form[externalInvoiceFormElements.countryCode];
    const totalAmount = form[externalInvoiceFormElements.totalAmount];
    const date = form[externalInvoiceFormElements.date];
    const dueDate = form[externalInvoiceFormElements.dueDate];

    // For sticky header
    const docNumber = form[externalInvoiceFormElements.docNumber];
    const clientName = form[externalInvoiceFormElements.name];

    // Fee
    const hasFee = form[externalInvoiceFormElements.hasFee];
    const totalFeeAmount = form[externalInvoiceFormElements.totalFeeAmount];
    const feeVatType = form[externalInvoiceFormElements.feeVatType];
    const feePartnerName = form[externalInvoiceFormElements.feePartnerName];
    const label = t('externalInvoice.title');

    useEffect(() => {
        return () => {
            dispatch(InvoiceActions.storeInvoiceHeaderData({}));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(InvoiceActions.storeInvoiceHeaderData({
            label,
            docNumber,
            clientName,
        }));
        // eslint-disable-next-line
    }, [docNumber, clientName, label]);

    useEffect(() => {
        if (countryCode === 'FR' || countryCode === 'MC') {
            setValue(
                externalInvoiceFormElements.vatType,
                VALUE_ADDED_TAX_OPTIONS[2],
                {shouldValidate: true},
            );
        } else if (countryCode === 'GP' || countryCode === 'MQ' || countryCode === 'RE') {
            setValue(
                externalInvoiceFormElements.vatType,
                VALUE_ADDED_TAX_OPTIONS[1],
                {shouldValidate: true},
            );
        } else {
            setValue(
                externalInvoiceFormElements.vatType,
                VALUE_ADDED_TAX_OPTIONS[0],
                {shouldValidate: true},
            );
        }
        // eslint-disable-next-line
    }, [countryCode]);

    useEffect(() => {
        if (totalFeeAmount) {
            trigger('totalFeeAmount');
        }
    }, [trigger, totalAmount, totalFeeAmount]);

    useEffect(() => {
        if (moment(dueDate).isValid()) {
            trigger('dueDate');
        }
    }, [trigger, date, dueDate]);

    const excludingTax = useMemo(() => {
        if (!vatType || !totalAmount) {
            return '';
        }
        const percent = VALUE_ADDED_TAX_OPTIONS_MULTIPLIER[vatType];
        const value = Number(totalAmount / percent);
        return Number.isFinite(value) ? value.toFixed(2) : '';
    }, [totalAmount, vatType]);

    const feeExcludingTax = useMemo(() => {
        if (!feeVatType || !totalFeeAmount) {
            return '';
        }
        const percent = VALUE_ADDED_TAX_OPTIONS_MULTIPLIER[feeVatType];
        const value = Number(totalFeeAmount / percent);
        return Number.isFinite(value) ? value.toFixed(2) : '';
    }, [totalFeeAmount, feeVatType]);

    const fieldParamsSx = {
        '& .MuiInputBase-root': {
            backgroundColor: '#fff',
        },
        '&.MuiInputBase-root': {
            backgroundColor: '#fff',
        },
    };

    const isSubmitDisabled = !isValid
        || !isDirty
        || !fileList.length
        || (hasFee && !feeFileList.length);

    const [options, setOptions] = useState([]);
    const clientSuggestions = useSelector(InvoiceSelector.selectClientSuggestions);
    const isLoadingClientSuggestions = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.GET_CLIENT_SUGGESTIONS,
    ));

    useEffect(() => {
        setOptions(clientSuggestions);
    }, [clientSuggestions]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchClientSuggestions = useCallback(debounce(clientNamePartial => {
        dispatch(InvoiceActions.getClientSuggestions(clientNamePartial));
    }, 1000), []);

    // Customer name
    const getOptionString = option => {
        return option?.name ?? clientName ?? '';
    };

    const selectedClientChanged = (_, newValue) => {
        if (!newValue) {
            setOptions([]);
            return;
        }

        const {name, zipCode} = newValue;

        let countryCode = 'FR';
        let vatType = VALUE_ADDED_TAX_OPTIONS[2];

        if (/^971\d\d$/.test(zipCode)) {
            countryCode = 'GP';
            vatType = VALUE_ADDED_TAX_OPTIONS[1];
        }
        if (/^972\d\d$/.test(zipCode)) {
            countryCode = 'MQ';
            vatType = VALUE_ADDED_TAX_OPTIONS[1];
        }
        if (/^974\d\d$/.test(zipCode)) {
            countryCode = 'RE';
            vatType = VALUE_ADDED_TAX_OPTIONS[1];
        }

        setValue(
            externalInvoiceFormElements.name,
            name ?? '',
            {shouldDirty: true, shouldValidate: true},
        );
        setValue(
            externalInvoiceFormElements.countryCode,
            countryCode,
            {shouldDirty: true, shouldValidate: true},
        );
        setValue(
            externalInvoiceFormElements.vatType,
            vatType,
            {shouldValidate: true},
        );

        setOptions([]);
    };

    const clientNamePartialChanged = (event, newNamePartial) => {
        if (event?.type === 'change') {
            setValue(externalInvoiceFormElements.name, newNamePartial ?? '', {shouldDirty: true, shouldValidate: true});
            if (newNamePartial.trim().length > 2) {
                setOptions([]);
                fetchClientSuggestions(newNamePartial);
            }
        }
    };

    // Fee Partner Name
    const getPartnerOptionString = option => {
        return option?.name ?? feePartnerName ?? '';
    };

    const selectedPartnerChanged = (_, newValue) => {
        if (!newValue) {
            setOptions([]);
            return;
        }

        const {name, zipCode} = newValue;

        let feeVatType = VALUE_ADDED_TAX_OPTIONS[2];

        if (/^971\d\d$/.test(zipCode)) {
            feeVatType = VALUE_ADDED_TAX_OPTIONS[1];
        }
        if (/^972\d\d$/.test(zipCode)) {
            feeVatType = VALUE_ADDED_TAX_OPTIONS[1];
        }
        if (/^974\d\d$/.test(zipCode)) {
            feeVatType = VALUE_ADDED_TAX_OPTIONS[1];
        }

        setValue(
            externalInvoiceFormElements.feePartnerName,
            name ?? '',
            {shouldDirty: true, shouldValidate: true},
        );
        setValue(
            externalInvoiceFormElements.feeVatType,
            feeVatType,
            {shouldDirty: true, shouldValidate: true},
        );

        setOptions([]);
    };

    const partnerNamePartialChanged = (event, newNamePartial) => {
        if (event?.type === 'change') {
            setValue(
                externalInvoiceFormElements.feePartnerName,
                newNamePartial ?? '',
                {shouldDirty: true, shouldValidate: true},
            );
            if (newNamePartial.trim().length > 2) {
                setOptions([]);
                fetchClientSuggestions(newNamePartial);
            }
        }
    };


    return (
        <>
            <ConfirmExternalInvoiceClose />

            <Typography variant="h5">
                {t('externalInvoice.categorisationFormTitle')}
            </Typography>

            {lastExternalInvoice && isLastExternalShown && (
                <Alert
                    icon={<CopyAllIcon />}
                    sx={{
                        'mb': 1,
                        'fontSize': theme => theme.typography.pxToRem(14),
                        'letterSpacing': '0.17px',
                        '.MuiAlert-icon': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                    variant="filled"
                    severity="info"
                    action={(
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            width: '280px',
                        }}
                        >
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={onExternalImport}
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    py: 0.6,
                                }}
                                startIcon={<AutoFixHighIcon />}
                            >
                                {t('externalInvoice.importExternalFactureButton')}
                            </Button>
                        </Box>
                    )}
                >
                    {t('externalInvoice.importExternalFacture', {docNumber: lastExternalInvoice.docNumber})}
                </Alert>
            )}
            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <CategorisationSection
                    status={SECTION_STATUS.REQUIRED}
                    hasNoElevation={true}
                    sx={{border: '1px solid #ECEFF1'}}
                    title={t('externalInvoice.addFacture')}
                    isActive
                >

                    <ExternalInvoiceFileDetails
                        fileList={fileList}
                        setFileList={setFileList}
                        label={t('externalInvoice.addFile')}
                        chooseFileLabel={t('externalInvoice.uploadFile')}
                        transactionId={transactionId}
                    />

                </CategorisationSection>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                }}
                >
                    <CategorisationSection
                        status={SECTION_STATUS.REQUIRED}
                        hasNoElevation={true}
                        sx={{border: '1px solid #ECEFF1'}}
                        title={t('externalInvoice.clientInformation')}
                        isActive
                    >
                        <Controller
                            control={control}
                            name={externalInvoiceFormElements.name}
                            render={({field}) => (
                                <Autocomplete
                                    {...field}
                                    freeSolo
                                    getOptionLabel={getOptionString}
                                    filterOptions={options => options}
                                    options={options}
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOptions
                                    noOptionsText={t('externalInvoice.noResults')}
                                    onChange={selectedClientChanged}
                                    onInputChange={clientNamePartialChanged}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box component="li" {...props} key={option.id}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Typography sx={{
                                                        fontSize: theme => theme.typography.pxToRem(16),
                                                        fontWeight: theme => theme.typography.fontWeightBold,
                                                    }}
                                                    >
                                                        {option.name}
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontSize: theme => theme.typography.pxToRem(12),
                                                        letterSpacing: '0.1px',
                                                    }}
                                                    >
                                                        {option.fullAddress}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                    renderInput={params => {
                                        return (
                                            <TextField
                                                label={t('externalInvoice.clientName')}
                                                variant="outlined"
                                                disabled={!!isLoadingClientSuggestions}
                                                error={!!errors[externalInvoiceFormElements.name]}
                                                helperText={errors[externalInvoiceFormElements.name]?.message}
                                                fullWidth
                                                sx={fieldParamsSx}
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {isLoadingClientSuggestions
                                                                ? <CircularProgress color="inherit" size={20} />
                                                                : null
                                                        }
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                />
                            )}
                        />

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                flex: 1,
                            }}
                            >
                                <Controller
                                    control={control}
                                    name={externalInvoiceFormElements.countryCode}
                                    render={({field}) => {
                                        const value = field.value
                                            ? countryCodes.find(country => country.alpha2Code === field.value)
                                            : null;

                                        return (
                                            <Autocomplete
                                                fullWidth
                                                sx={{
                                                    ...fieldParamsSx,
                                                }}
                                                groupBy={option => {
                                                    return option.alpha2Code === 'FR'
                                                        ? 'France'
                                                        : (option.alpha2Code === 'GP'
                                                    || option.alpha2Code === 'MQ'
                                                    || option.alpha2Code === 'RE')
                                                            ? 'DOM-TOM'
                                                            : 'Other';
                                                }}
                                                disableClearable
                                                options={countryCodes}
                                                value={value}
                                                getOptionLabel={country => (country === ''
                                                    ? ''
                                                    : (language === 'fr'
                                                        ? country.frenchShortName
                                                        : country.englishShortName))
                                                }
                                                onChange={(_, value) => {
                                                    field.onChange(value.alpha2Code);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        label={t('externalInvoice.country')}
                                                        variant="outlined"
                                                        disabled={isLoading}
                                                        error={!!errors[externalInvoiceFormElements.countryCode]}
                                                        helperText={
                                                            errors[externalInvoiceFormElements.countryCode]?.message
                                                        }
                                                        fullWidth={true}
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />

                                <Box sx={{
                                    width: '72px',
                                }}
                                >
                                    <TextField
                                        label={t('externalInvoice.vat')}
                                        variant="outlined"
                                        value={vatType ? t(`vatOptionsField.${vatType}`) : ''}
                                        disabled
                                        sx={fieldParamsSx}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{
                                flex: 1,
                            }}
                            >
                                <Controller
                                    control={control}
                                    name={externalInvoiceFormElements.docNumber}
                                    render={({field}) => (
                                        <TextField
                                            label={t('externalInvoice.docNumber')}
                                            variant="outlined"
                                            disabled={isLoading}
                                            error={!!errors[externalInvoiceFormElements.docNumber]}
                                            helperText={errors[externalInvoiceFormElements.docNumber]?.message}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">N°</InputAdornment>
                                                ),
                                            }}
                                            sx={fieldParamsSx}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                        >
                            <Controller
                                control={control}
                                name={externalInvoiceFormElements.date}
                                render={({field}) => (
                                    <LocalizationProvider
                                        dateLibInstance={moment}
                                        dateAdapter={AdapterMoment}
                                    >
                                        <DatePicker
                                            {...field}
                                            error={!!errors?.[externalInvoiceFormElements.date]}
                                            helperText={errors?.[externalInvoiceFormElements.date]?.message}
                                            openTo="day"
                                            format={moment.localeData().longDateFormat('L')}
                                            views={['year', 'month', 'day']}
                                            InputProps={{
                                                endAdornment: <DateRangeIcon sx={{color: 'v2.light.action.active'}} />,
                                            }}
                                            onChange={val => {
                                                const tzValue = momentTz.tz(val.format('MM/DD/YYYY'), DEFAULT_TIME_ZONE);
                                                tzValue.set('hour', 8);

                                                field.onChange(tzValue);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    label={t('externalInvoice.date')}
                                                    error={!!errors?.[externalInvoiceFormElements.date]}
                                                    helperText={
                                                        errors?.[externalInvoiceFormElements.date]?.message
                                                    }
                                                    disabled={isLoading}
                                                    sx={fieldParamsSx}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />

                            <Controller
                                control={control}
                                name={externalInvoiceFormElements.dueDate}
                                render={({field}) => (
                                    <LocalizationProvider
                                        dateLibInstance={moment}
                                        dateAdapter={AdapterMoment}
                                    >
                                        <DatePicker
                                            {...field}
                                            error={!!errors?.[externalInvoiceFormElements.dueDate]}
                                            helperText={errors?.[externalInvoiceFormElements.dueDate]?.message}
                                            openTo="day"
                                            format={moment.localeData().longDateFormat('L')}
                                            views={['year', 'month', 'day']}
                                            minDate={date ? moment(date) : undefined}
                                            InputProps={{
                                                endAdornment: <DateRangeIcon sx={{color: 'v2.light.action.active'}} />,
                                            }}
                                            onChange={val => {
                                                const tzValue = momentTz.tz(val.format('MM/DD/YYYY'), DEFAULT_TIME_ZONE);
                                                tzValue.set('hour', 17);

                                                field.onChange(tzValue);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    disabled={isLoading}
                                                    label={t('externalInvoice.dueDate')}
                                                    variant="outlined"
                                                    error={!!errors?.[externalInvoiceFormElements.dueDate]}
                                                    helperText={
                                                        errors?.[externalInvoiceFormElements.dueDate]?.message
                                                    }
                                                    sx={fieldParamsSx}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                        >
                            <Controller
                                control={control}
                                name={externalInvoiceFormElements.totalAmount}
                                render={({field}) => (
                                    <TextField
                                        label={t('externalInvoice.totalAmount')}
                                        variant="outlined"
                                        disabled={isLoading}
                                        error={!!errors[externalInvoiceFormElements.totalAmount]}
                                        helperText={errors[externalInvoiceFormElements.totalAmount]?.message}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EuroIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={fieldParamsSx}
                                        {...field}
                                    />
                                )}
                            />

                            <TextField
                                name="excludingTax"
                                label={t('externalInvoice.excludingTax')}
                                variant="outlined"
                                disabled
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EuroIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={fieldParamsSx}
                                value={excludingTax}
                            />
                        </Box>
                    </CategorisationSection>

                    <Controller
                        control={control}
                        name="hasFee"
                        render={({field}) => (
                            <FormControlLabel
                                sx={{
                                    mb: 2,
                                    ml: 1,
                                }}
                                disabled={isLoading}
                                control={(
                                    <Switch
                                        {...field}
                                        checked={field.value}
                                    />
                                )}
                                label={t('externalInvoice.feeSwitchLabel')}
                            />
                        )}
                    />
                </Box>

                {hasFee && (
                    <>
                        <Box>
                            <CategorisationSection
                                status={SECTION_STATUS.REQUIRED}
                                hasNoElevation={true}
                                sx={{border: '1px solid #ECEFF1'}}
                                title="Facture de commission"
                                isActive
                            >
                                <ExternalInvoiceFileDetails
                                    fileList={feeFileList}
                                    setFileList={setFeeFileList}
                                    label={t('externalInvoice.feeAddFile')}
                                    text={t('externalInvoice.feeChooseFileText')}
                                    chooseFileLabel={t('externalInvoice.chooseFile')}
                                />
                            </CategorisationSection>

                            <Box sx={{
                                my: 2,
                            }}
                            >
                                <CategorisationSection
                                    status={SECTION_STATUS.REQUIRED}
                                    hasNoElevation={true}
                                    sx={{border: '1px solid #ECEFF1'}}
                                    title={t('externalInvoice.feeTitle')}
                                    isActive
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            name={externalInvoiceFormElements.totalFeeAmount}
                                            render={({field}) => (
                                                <TextField
                                                    label={t('externalInvoice.totalFeeAmount')}
                                                    variant="outlined"
                                                    disabled={isLoading}
                                                    error={!!errors[externalInvoiceFormElements.totalFeeAmount]}
                                                    helperText={
                                                        errors[externalInvoiceFormElements.totalFeeAmount]?.message
                                                    }
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={fieldParamsSx}
                                                    {...field}
                                                    value={!field.value ? '' : field.value}
                                                />
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name={externalInvoiceFormElements.feeVatType}
                                            render={({field}) => (
                                                <FormControl
                                                    fullWidth
                                                >
                                                    <InputLabel
                                                        fullWidth
                                                        id={field.id}
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                        }}
                                                    >
                                                        {t('externalInvoice.feeVatType')}
                                                    </InputLabel>
                                                    <Select
                                                        {...field}
                                                        variant="outlined"
                                                        error={!!errors[externalInvoiceFormElements.feeVatType]}
                                                        fullWidth
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                        sx={fieldParamsSx}
                                                    >
                                                        {menuItemsVAT}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />

                                        <TextField
                                            name="feeExcludingTax"
                                            label={t('externalInvoice.feeExcludingTax')}
                                            variant="outlined"
                                            disabled
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={fieldParamsSx}
                                            value={feeExcludingTax}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            name={externalInvoiceFormElements.feePartnerName}
                                            render={({field}) => (
                                                <Autocomplete
                                                    {...field}
                                                    fullWidth
                                                    freeSolo
                                                    getOptionLabel={getPartnerOptionString}
                                                    filterOptions={options => options}
                                                    options={options}
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    noOptionsText={t('externalInvoice.noResults')}
                                                    onChange={selectedPartnerChanged}
                                                    onInputChange={partnerNamePartialChanged}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <Box component="li" {...props} key={option.id}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-start',
                                                                        justifyContent: 'left',
                                                                    }}
                                                                >
                                                                    <Typography sx={{
                                                                        fontSize: theme => theme.typography.pxToRem(16),
                                                                        fontWeight:
                                                                            theme => theme.typography.fontWeightBold,
                                                                    }}
                                                                    >
                                                                        {option.name}
                                                                    </Typography>
                                                                    <Typography sx={{
                                                                        fontSize: theme => theme.typography.pxToRem(12),
                                                                        letterSpacing: '0.1px',
                                                                    }}
                                                                    >
                                                                        {option.fullAddress}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        );
                                                    }}
                                                    renderInput={params => {
                                                        return (
                                                            <TextField
                                                                label={t('externalInvoice.feePartnerName')}
                                                                variant="outlined"
                                                                disabled={!!isLoadingClientSuggestions}
                                                                error={
                                                                    !!errors[externalInvoiceFormElements.feePartnerName]
                                                                }
                                                                helperText={
                                                                    // eslint-disable-next-line max-len
                                                                    errors[externalInvoiceFormElements.feePartnerName]?.message
                                                                }
                                                                fullWidth
                                                                sx={fieldParamsSx}
                                                                {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <>
                                                                            {isLoadingClientSuggestions
                                                                                ? <CircularProgress color="inherit" size={20} />
                                                                                : null
                                                                        }
                                                                            {params.InputProps.endAdornment}
                                                                        </>
                                                                    ),
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name={externalInvoiceFormElements.feePartnerInvoiceDocNumber}
                                            render={({field}) => (
                                                <TextField
                                                    label={t('externalInvoice.feePartnerInvoiceDocNumber')}
                                                    variant="outlined"
                                                    disabled={isLoading}
                                                    error={
                                                        !!errors[externalInvoiceFormElements.feePartnerInvoiceDocNumber]
                                                    }
                                                    helperText={
                                                        // eslint-disable-next-line max-len
                                                        errors[externalInvoiceFormElements.feePartnerInvoiceDocNumber]?.message
                                                    }
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">N°</InputAdornment>
                                                        ),
                                                    }}
                                                    sx={fieldParamsSx}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>

                                </CategorisationSection>
                            </Box>
                        </Box>
                    </>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                    }}
                >
                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            dispatch(UiActions.setModalData(ModalsKeys.CATEGORISATION_CLOSE_EXTERNAL_INVOICE, {
                                setIsExternalOpen,
                            }));
                            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORISATION_CLOSE_EXTERNAL_INVOICE, true));
                        }}
                    >
                        {t('externalInvoice.cancel')}
                    </Button>

                    <LoadingButton
                        loading={isLoading}
                        fullWidth
                        color="error"
                        type="submit"
                        variant="contained"
                        disabled={isSubmitDisabled}
                        startIcon={<DoneIcon />}
                        {...(details?.isCategorized && !isSubmitDisabled && {
                            endIcon: (
                                <Tooltip title={t('externalInvoice.alreadyCategorized')}>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Tooltip>
                            ),
                            sx: {
                                cursor: 'not-allowed',
                            },

                        })}
                    >
                        {transactionId ? t('externalInvoice.create') : t('externalInvoice.save')}
                    </LoadingButton>
                </Box>
            </form>
        </>
    );
};

ExternalInvoiceForm.propTypes = {
    details: PropTypes.object.isRequired,
    setIsExternalOpen: PropTypes.func.isRequired,
};

export default ExternalInvoiceForm;
