import hiwayLocalStorage from '../../../../v1/config/storage';

export const ARIA_PARTNER_NAME = 'ARIA';

export const ARIA_DEADLINE = {
    DAYS_30: 'DAYS_30',
    DAYS_45: 'DAYS_45',
    DAYS_60: 'DAYS_60',
};

export const ARIA_PAYMENT_DELAY = 2; // 48 business hours = 2 business days

export const ARIA_DEFAULT_AMOUNT = 10000;

export const ARIA_MIN_AMOUNT = 100;
export const ARIA_MAX_AMOUNT = 20000;

// NOTE: this constant should not be less then value of the
// ARIA_DAYS[ARIA_DEADLINE.DAYS_30] - now it's 30 days
export const ARIA_DAYS_MINIMUM = 7;

export const ARIA_DAYS = {
    [ARIA_DEADLINE.DAYS_30]: 30,
    [ARIA_DEADLINE.DAYS_45]: 45,
    [ARIA_DEADLINE.DAYS_60]: 60,
};

export const ARIA_PERCENT = {
    [ARIA_DEADLINE.DAYS_30]: 2.2,
    [ARIA_DEADLINE.DAYS_45]: 2.8,
    [ARIA_DEADLINE.DAYS_60]: 3.5,
};

export const ARIA_VAT = 20;

export const ARIA_CONTRACT_STATUS = {
    NOT_SENT: 'notSent',
    PENDING: 'pending',
    SIGNED: 'signed',
    COMPLETED: 'completed',
};

export const ARIA_KYC_STATUS = {
    UNNEEDED: 'UNNEEDED',
    EMPTY: 'EMPTY',
    PENDING: 'PENDING',
    VALID: 'VALID',
    INVALID: 'INVALID',
};

export const ARIA_KYC_COMPLETED_STATUSES = [
    ARIA_KYC_STATUS.UNNEEDED,
    ARIA_KYC_STATUS.VALID,
];

export const ARIA_ACTIVATED_STATUSES = {
    INITIAL: 0,
    NOT_SEEN: 1,
};

export const ARIA_ACTIVATED_STATUS = 'ARIA_ACTIVATED_STATUS';
export const ARIA_ACTIVATED_STATUS_LS = hiwayLocalStorage.get(ARIA_ACTIVATED_STATUS)
    ?? ARIA_ACTIVATED_STATUSES.INITIAL;

export const CLIENT_LENDING_ELIGIBILITY_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REFUSED: 'REFUSED',
};

export const ARIA_DEBTOR_IDENTIFIER_TYPE = {
    SIRET: 'siret', // FR+
    SIREN: 'siren', // FR+
    VAT_NUMBER: 'vat-number', // BE, ES, DE
    SWISS_SINGLE_REG_NUM: 'chrn', // CH
    COMPANY_REGISTRATION_ORG: 'cro', // GB
};

export const ARIA_FRANCE_PLUS = [
    'FR', // France
    'GP', // Guadeloupe
    'MQ', // Martinique
    'RE', // Reunion
    'GF', // French Guiana
    'PF', // French Polynesia
    'TF', // French Southern Territories
    'MF', // Saint Martin (French part)
    'PM', // Saint Pierre and Miquelon
    'NC', // New Caledonia
    'YT', // Mayotte
    'WF', // Wallis and Futuna
];

const ARIA_SIRET_NON_FRANCE_COUNTRIES = [
    'CH', // Switzerland
    'GB', // United Kingdom
];

export const ARIA_SIRET_COUNTRIES = [
    ...ARIA_FRANCE_PLUS,
    ...ARIA_SIRET_NON_FRANCE_COUNTRIES,
];

export const ARIA_VAT_COUNTRIES = [
    'ES', // Spain
    'DE', // Germany
    'BE', // Belgium
];

export const ARIA_COUNTRIES = [
    ...ARIA_SIRET_COUNTRIES,
    ...ARIA_VAT_COUNTRIES,
];

export const GENERIC_EMAIL_NAMES = [
    'compta',
    'comptabilite',
    'secretariat',
    'gestion',
    'paie',
    'rh',
    'contact',
    'marketing',
    'accueil',
    'info',
    'information',
    'facturation',
    'support',
    'facture',
    'finance',
    'contact-ext',
    'supplier-invoice',
];

export const RegExpVAT = {
    'FR': /^FR\d{2}\d{9}$/, // 13 characters
    'CH': /^CHE\d{9}(?:MWST|TVA|IVA)?$/,
    'GB': /^GB(\d{9}(\d{3})?|[A-Z]{2}\d{3})$/,
    'DE': /^DE\d{9}$/, // 11 characters
    'ES': /^ES(?:\d|[A-Z])\d{7}(?:\d|[A-Z])$/, // 11 characters
    'BE': /^BE\d{8}\d{2}$/, // 12 characters
};

export const RegExpSiret = {
    'FR': /^\d+$/, // 14 characters
    'CH': /^CHRN-[A-Z]{2}-\d{8}$/, // 16 characters
    'GB': /^(?:([A-Z]{2}|\d{2})\d{6})$/, // 8 characters
};
