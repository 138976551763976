import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {UserRoles} from '../../../../utils/user-roles';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {SidebarSelectors} from '../../store/sidebar.selector';
import {
    PayrollAdministratorSidebarCareNavigation,
    PayrollAdministratorSidebarNavigation,
    // AdministratorSidebarPortageNavigation, // HPD-6127 - Disabled portage
} from '../../utils/constants';
import CommonSidebar from '../Common/CommonSidebar';
import {SegmentSwitch} from '../Common/SegmentSwitch';

export const PayrollAdministratorSidebar = () => {
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const selectedRole = useSelector(SidebarSelectors.selectSelectedRole);

    const sidebarData = useMemo(() => {
        switch (selectedRole) {
            case UserRoles.FREELANCER:
                return PayrollAdministratorSidebarNavigation;
            case UserRoles.CARE:
                return PayrollAdministratorSidebarCareNavigation;
            // HPD-6127 - Disabled portage
            // case UserRoles.PORTAGE:
            //     return AdministratorSidebarPortageNavigation;
            default:
                return PayrollAdministratorSidebarNavigation;
        }
    }, [selectedRole]);

    if (!user) {
        return;
    }

    return (
        <>
            <CommonSidebar
                user={user}
                sidebarData={sidebarData}
                sx={{pb: 4}}
                SegmentSwitchComponent={(<SegmentSwitch />)}
            />
        </>
    );
};
