import {RoutePaths} from '../../../lib/router/route-paths';
import {CompanyVariant} from '../../../utils/company-utils';
import {UNCATEGORIZED_FILTER_PARAM_NAME} from '../../bank/modules/account-balance/util/constants';

export const NavItems = {
    OVERVIEW: {name: 'overview'},
    REMUNERATION: {name: 'remuneration'},
    ACTIVITY: {name: 'activity'},
    TREASURY: {name: 'treasury'},
    TAX_AND_SOCIAL_MANAGEMENT: {name: 'taxAndSocialManagement'},
    INCOME_TAX: {name: 'incomeTax'},
    BILLING: {name: 'billing', isComingSoon: true},
};

export const TreasuryIndicatorStates = {
    OK: 'OK',
    INTERMEDIARY: 'INTERMEDIARY',
    ALERTING: 'ALERTING',
    NO_CONNECTED_BANK: 'NO_CONNECTED_BANK',
    ACCOUNT_BALANCE_ZERO: 'ACCOUNT_BALANCE_ZERO',
};

export const TreasuryDisplayStates = {
    EMPTY: 'EMPTY',
    SECURED: 'SECURED',
    NOT_SECURED: 'NOT_SECURED',
};

export const InvoicingIndicatorStates = {
    OK: 'OK',
    INTERMEDIARY: 'INTERMEDIARY',
    ALERTING: 'ALERTING',
    NO_INVOICES_YET: 'NO_INVOICES_YET',
};
export const CategorizationIndicatorStates = {
    OK: 'OK',
    INTERMEDIARY: 'INTERMEDIARY',
    ALERTING: 'ALERTING',
    NO_CONNECTED_BANK: 'NO_CONNECTED_BANK',
    NO_TRANSACTIONS_YET: 'NO_TRANSACTIONS_YET',
};

export const chartSettings = {
    lineColor: 'rgba(99, 107, 115, 0.15)',
    chartHeight: 360,
    barRadius: [6, 6, 0, 0],
    barWidth: 12,
    barWidthMobile: 6,
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const activityPeriod = {
    YEAR: 'YEAR',
    MONTH: 'MONTH',
};

export const TABLE_MAX_ROWS = {
    MOBILE: 3,
    DESKTOP: 6,
};

export const UNCATEGORIZED_TRANSACTIONS_PATH = `${RoutePaths.BANK_TRANSACTION_LIST}?${UNCATEGORIZED_FILTER_PARAM_NAME}`;

export const TREASURY_MOBILE_SIZE = '767px';

export const indicatorToDisplayStateMap = {
    [TreasuryIndicatorStates.NO_CONNECTED_BANK]: TreasuryDisplayStates.EMPTY,
    [TreasuryIndicatorStates.ACCOUNT_BALANCE_ZERO]: TreasuryDisplayStates.SECURED,
    [TreasuryIndicatorStates.OK]: TreasuryDisplayStates.SECURED,
    [TreasuryIndicatorStates.INTERMEDIARY]: TreasuryDisplayStates.NOT_SECURED,
    [TreasuryIndicatorStates.ALERTING]: TreasuryDisplayStates.NOT_SECURED,
};

export const YearPickerKeys = {
    REVENUE_DETAILS: 'REVENUE_DETAILS',
    ACTIVITY: 'ACTIVITY',
    INCOME_TAX: 'INCOME_TAX',
};

export const newDashboardCompanyVariants = [
    CompanyVariant.SASU_IR,
    CompanyVariant.EI_BNC,
    CompanyVariant.SASU_IS,
    CompanyVariant.EURL_IS,
    CompanyVariant.EURL_HOLDING,
];

export const companyVariantsWithIncomeTax = [CompanyVariant.SASU_IR, CompanyVariant.EI_BNC];
