import PropTypes from 'prop-types';
import React from 'react';
import NumericFormat from 'react-number-format';
import {MathUtils} from './math';

export const DefaultNumericFormat = React.forwardRef(
    (props, ref) => {
        const {onChange, min, max, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={values => {
                    const {value} = values;

                    // Allow empty string
                    if (value === '') {
                        onChange({
                            target: {
                                value,
                            },
                        });

                        return;
                    }

                    const numValue = MathUtils.clamp(Number(value), min, max);

                    onChange({
                        target: {
                            value: numValue,
                        },
                    });
                }}
                onBlur={() => {
                    const numValue = MathUtils.clamp(Number(props.value), min, max);
                    onChange({
                        target: {
                            value: numValue,
                        },
                    });
                }}
                thousandSeparator=" "
            />
        );
    },
);

DefaultNumericFormat.defaultProps = {
    min: Number.NEGATIVE_INFINITY,
    max: Number.POSITIVE_INFINITY,
};

DefaultNumericFormat.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    precision: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
};
