export const ROLES = {
    FREELANCER: 'FREELANCER',
    // HPD-2013 - Hide coach/accountant
    // COACH: 'COACH',
    // ACCOUNTANT: 'ACCOUNTANT',
    ADMINISTRATOR: 'ADMINISTRATOR',
    PAYROLL_ADMINISTRATOR: 'PAYROLL_ADMINISTRATOR',
    LOGGED_IN_USER: 'LOGGED_IN_USER', // requirement is that the user must be logged in
    PUBLIC: 'PUBLIC', // anyone can access
    CARE: 'CARE',
};
