export const BankActionTypes = {
    CREATE_BANK_ACCOUNT: 'bank/CREATE_BANK_ACCOUNT',
    CREATE_BANK_ACCOUNT_ONBOARDING: 'bank/CREATE_BANK_ACCOUNT_ONBOARDING',
    FINALIZE_BANK_ACCOUNT: 'bank/FINALIZE_BANK_ACCOUNT',
    STORE_ACTIVE_INTEGRATION_DATA: 'bank/STORE_ACTIVE_INTEGRATION_DATA',
    STORE_ARCHIVED_INTEGRATION_DATA: 'bank/STORE_ARCHIVED_INTEGRATION_DATA',
    STORE_ALL_INTEGRATION_DATA: 'bank/STORE_ALL_INTEGRATION_DATA',
    GET_USER_INTEGRATION_DATA: 'bank/GET_USER_INTEGRATION_DATA',
    STORE_BANK_MENU_IS_FULLY_ACTIVE: 'bank/STORE_BANK_MENU_IS_FULLY_ACTIVE',
    FILL_MISSING_DATA: 'bank/FILL_MISSING_DATA',
    DOWNLOAD_RIB: 'bank/DOWNLOAD_RIB',
    START_KYC: 'bank/START_KYC',
    UPLOAD_FILE_QR_CODE_EVENT: 'bank/UPLOAD_FILE_QR_CODE_EVENT',
    START_CAPITAL_DEPOSIT: 'bank/START_CAPITAL_DEPOSIT',
    SET_CAPITAL_DEPOSIT_ERROR: 'bank/SET_CAPITAL_DEPOSIT_ERROR',
    GET_CAPITAL_DEPOSIT: 'bank/GET_CAPITAL_DEPOSIT',
    STORE_CAPITAL_DEPOSIT: 'bank/STORE_CAPITAL_DEPOSIT',
    TRANSITION_CAPITAL_DEPOSIT_STATUS: 'bank/TRANSITION_CAPITAL_DEPOSIT_STATUS',
    CLOSE_BANK_ACCOUNT: 'bank/CLOSE_BANK_ACCOUNT',
    DELETE_ACCOUNT: 'bank/DELETE_ACCOUNT',
    ARCHIVE_ACCOUNT: 'bank/ARCHIVE_ACCOUNT',
    UPDATE_REFUSED_DOCUMENTS: 'bank/UPDATE_REFUSED_DOCUMENTS',
};
