import {Box} from '@mui/joy';
import {Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {BackButton} from './components/BackButton';
import {CompanyAndPeriod} from './components/CompanyAndPeriod';
import {Header} from './components/Header';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {LoadingSkeletonProvider} from '../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {SuspenseContainer} from '../../components/suspense/Suspense';
import {VatDeclarationSelectors} from '../../features/vat-declaration/store/vat-declaration.selector';
import {getCurrentDeclarationPeriod} from '../../features/vat-declaration/utils';
import {importLocaleBundle} from '../../lib/i18next';
import {RoutePaths} from '../../lib/router/route-paths';

importLocaleBundle('vatDeclaration');

export const VatDeclarationYearlyHistoryLayout = () => {
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const declarationPeriod = getCurrentDeclarationPeriod().year();

    return (
        <JoyUIThemeProvider>
            <Box sx={{bgcolor: 'background.body', minHeight: '100vh'}}>
                <LoadingSkeletonProvider isLoading={!declaration} pulseBg="var(--joy-palette-common-black)">
                    <Header>
                        <BackButton fallbackRoute={RoutePaths.VAT_DECLARATION} />
                        <CompanyAndPeriod
                            isLoading={!declaration}
                            companyId={declaration?.companyId}
                            freelancerId={declaration?.freelancer.id}
                            companyName={declaration?.company.name}
                            declarationPeriod={declarationPeriod.toString()}
                        />
                        <div />
                        {/* empty div for layout purpose */}
                    </Header>
                </LoadingSkeletonProvider>
                <Box sx={{color: 'primary.main'}}>
                    <Suspense fallback={<SuspenseContainer />}>
                        <Outlet />
                    </Suspense>
                </Box>
            </Box>
        </JoyUIThemeProvider>
    );
};
