import {faFileLines, faInfoCircle, faRepeat, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Box, Button, Stack, Typography} from '@mui/joy';
import {ThemeProvider} from '@mui/material';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {theme} from '../../../../lib/theme';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {VATDeclarationActions} from '../../store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {getDeclarationPeriodString} from '../../utils';
import {DocumentsDropZone} from '../DocumentsDropZone';
import {VatDeclarationSection} from '../VatDeclarationSection';

const maxFiles = 3;

export const DocumentUploadSection = () => {
    const {t} = useTranslation('vatDeclaration');
    const isDocumentsLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_DOCUMENTS),
    );
    const isDeclarationValidating = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_VALIDATE_MANUAL),
    );
    const isDocumentDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_DOWNLOAD_DOCUMENT),
    );
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const declarationId = declaration.id;
    const uploadedFiles = useMemo(() => declaration?.documents || [], [declaration?.documents]);
    const dispatch = useDispatch();

    const [replacingFile, setReplacingFile] = useState(null);
    const dropzoneRef = useRef(null);

    const handleChange = useCallback(
        newFiles => {
            if (replacingFile) {
                const oldDocument = replacingFile;
                const newDocument = newFiles[0];
                dispatch(VATDeclarationActions.replaceDocument({declarationId, oldDocument, newDocument}));
                setReplacingFile(null);
            } else {
                dispatch(VATDeclarationActions.uploadDocuments({declarationId, files: newFiles}));
            }
        },
        [replacingFile, dispatch, declarationId],
    );

    const handleDocumentDelete = document => {
        dispatch(VATDeclarationActions.deleteDocument({declarationId, documentId: document.id}));
    };

    const handleDocumentReplace = oldDocument => {
        setReplacingFile(oldDocument);
        setTimeout(() => {
            dropzoneRef.current.open();
        });
    };

    const handleDocumentView = async file => {
        dispatch(VATDeclarationActions.downloadDocument({
            documentId: file.id,
            declaration,
            isDownloading: false,
        }));
    };

    const isDisabled = isDeclarationValidating
        || isDocumentsLoading
        || (uploadedFiles.length >= maxFiles && !replacingFile);

    return (
        <VatDeclarationSection title={t('documents')} isRequired>
            <Stack spacing={2}>
                <Box>
                    <Typography level="body-md" textColor="text.secondary" mb={1}>
                        {t('associatedDocuments')}
                    </Typography>
                    <Alert
                        variant="soft"
                        color="neutral"
                        size="sm"
                        startDecorator={(
                            <Box fontSize="16px" mx={1}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </Box>
                          )}
                        sx={{fontSize: '14px'}}
                    >
                        {t('uploadDocumentsInfo', {declarationPeriod: getDeclarationPeriodString(declaration)})}
                    </Alert>
                </Box>
                <ThemeProvider theme={theme}>
                    <DocumentsDropZone
                        files={uploadedFiles}
                        isMultiple={replacingFile ? false : maxFiles - uploadedFiles.length > 1}
                        onChange={handleChange}
                        maxFiles={maxFiles}
                        maxSize={10_000_000}
                        fileTypesDescription={t('pdfOrCsv')}
                        rejectionDescription={t('filePickerRejection')}
                        fileTypes=".pdf,.csv"
                        isDisabled={isDisabled}
                        isUploading={isDocumentsLoading}
                        dropzoneRef={dropzoneRef}
                        onFileDialogCancel={() => setReplacingFile(null)}
                        onDropRejected={() => setReplacingFile(null)}
                        renderFileActions={file => (
                            <>
                                <Button
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    startDecorator={<FontAwesomeIcon icon={faFileLines} />}
                                    fullWidth
                                    onClick={() => handleDocumentView(file)}
                                    loading={isDocumentDownloading}
                                    loadingPosition="end"
                                >
                                    {t('view')}
                                </Button>
                                <Button
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    startDecorator={<FontAwesomeIcon icon={faRepeat} />}
                                    fullWidth
                                    onClick={() => handleDocumentReplace(file)}
                                >
                                    {t('replace')}
                                </Button>
                                <Button
                                    size="sm"
                                    color="danger"
                                    variant="solid"
                                    startDecorator={<FontAwesomeIcon icon={faTrash} />}
                                    fullWidth
                                    onClick={() => handleDocumentDelete(file)}
                                >
                                    {t('delete')}
                                </Button>
                            </>
                        )}
                    />
                </ThemeProvider>
            </Stack>
        </VatDeclarationSection>
    );
};
