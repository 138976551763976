import {Amplify, Auth} from 'aws-amplify';
import {generatePath} from 'react-router-dom';
import {all, call, delay, fork, put, select, take, takeLatest} from 'redux-saga/effects';

import {LOGOUT, SUBMIT_NEW_PASSWORD_FORM} from './auth.actions';
import {selectCurrentCognitoUser, selectUser} from './auth.selectors';
import {forceEmailVerification, getIsEmailVerificationRequired} from './emailVerification.sagas';
import {watchEmailVerificationFormSagas} from './emailVerificationForm/emailVerificationForm.sagas';
import {getAndStoreCurrentUserInfo} from './getAndStoreCurrentUserInfo';
import {isUserLoggedIn} from './isUserLoggedIn.saga';
import {watchLoginFormSagas} from './loginForm/loginForm.sagas';
import {storeNewPasswordFormError, storeNewPasswordFormSuccess} from './newPasswordForm/newPasswordForm.actions';
import {newPasswordFormErrorCodes} from './newPasswordForm/newPasswordForm.constants';
import {
    SUBMIT_CONFIRM_RESET_PASSWORD_FORM,
    SUBMIT_RESET_PASSWORD_FORM,
    setIsLoadingResetPasswordForm,
    storeCodeSentSuccess,
    storeResetPasswordFormCodeError,
    storeResetPasswordFormPasswordError,
    storeResetPasswordUsernameError,
} from './resetPasswordForm/resetPasswordForm.actions';
import {resetPasswordFormErrorCodes} from './resetPasswordForm/resetPasswordForm.constants';
import {
    selectResetPasswordFormUsername,
} from './resetPasswordForm/resetPasswordForm.selectors';
import {CONFIG} from '../../../config';
import {END_SIDE_EFFECTS_RUNNING, push, replace, selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {COOKIE_NAMES, deleteCookie, getCookie} from '../../../utils/cookie';
import {UserRoles, userRoleLocalStorageKey} from '../../../utils/user-roles';
import {STORE_LOGGED_IN_USER, getLoggedInUser} from '../../../v1/app/user/user.actions';
import {USER_STATUSES} from '../../../v1/app/user/user.constants';
import {getConfig} from '../../../v1/config';
import HiwayLocalStorage from '../../../v1/config/storage';
import {AnimationActions} from '../../animations/store/animations.action';
import {FreelancerSelectors} from '../../freelancer';
import {SseActions} from '../../server-side-events/store/sse.actions';

export const initializeAuth = function* () {
    yield call([Amplify, Amplify.configure], {
        Auth: {
            mandatorySignIn: true,
            region: CONFIG.AWS_AMPLIFY.COGNITO.REGION,
            userPoolId: CONFIG.AWS_AMPLIFY.COGNITO.USER_POOL_ID,
            userPoolWebClientId: CONFIG.AWS_AMPLIFY.COGNITO.CLIENT_ID,
        },
    });
};

/**
 * Since, at the moment, we need to redirect the user to login page before we clear the tokens and reset the redux
 * store, this should serve as a hotfix. Once the auth flow is written in a better way, this will get removed.
 *
 * @see https://d-f.atlassian.net/browse/HPD-1544
 */
let isLoggingOut = false;

const submitNewPasswordFormSaga = function* submitNewPasswordFormSaga({payload}) {
    try {
        yield put(AnimationActions.setInitialAnimation(true));

        const user = yield select(selectUser);

        const {newPassword} = payload;

        yield call([Auth, Auth.completeNewPassword], user, newPassword, {});

        yield put(AnimationActions.setInitialAnimation(false));

        yield put(storeNewPasswordFormSuccess());

        // Delay message so it shows at the end of animation
        yield delay(300);
        Toast.success('newPasswordSaved');
    } catch (error) {
        switch (error.code) {
            // case 'InvalidPasswordException':
            //     yield put(storeNewPasswordFormError(newPasswordFormErrorCodes.INVALID_PASSWORD));
            //
            //     break;

            case 'NotAuthorizedException':
                Toast.error('initialLoginRequired');

                break;
            default:
                yield put(storeNewPasswordFormError(newPasswordFormErrorCodes.UNKNOWN_ERROR));

                break;
        }

        yield put(AnimationActions.setInitialAnimation(false));
    }
};

const logoutSaga = function* logoutSaga() {
    isLoggingOut = true;

    yield put(SseActions.disconnectTokens());

    yield put(push({
        pathname: getConfig().ROUTE_PATHS.LOGIN,
        state: null,
    }));

    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

    if (cookieToken) {
        deleteCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
    } else {
        yield call([Auth, Auth.signOut]);
    }

    // Clear on logout
    HiwayLocalStorage.delete(userRoleLocalStorageKey);

    yield put({
        type: 'LOGOUT_SUCCESS',
    });

    yield put(AnimationActions.setInitialAnimation(false));
};

const submitResetPasswordFormSaga = function* submitResetPasswordFormSaga() {
    try {
        const username = yield select(selectResetPasswordFormUsername);

        const result = yield call([Auth, Auth.forgotPassword], username);

        if (result && result.hasOwnProperty('CodeDeliveryDetails')) {
            // TODO:LOW Not sure if this is needed at the moment, but there's way to get this data, so leaving it here.
            // const attributeName = result.CodeDeliveryDetails.AttributeName; // "email"
            // const deliveryMedium = result.CodeDeliveryDetails.DeliveryMedium; // "EMAIL"
            // const destination = result.CodeDeliveryDetails.Destination; // "d***@w***.rs"
            yield put(storeCodeSentSuccess());
        }
    } catch (error) {
        switch (error.code) {
            case 'InvalidParameterException':
                yield put(storeResetPasswordUsernameError(resetPasswordFormErrorCodes.INVALID_USERNAME));

                break;

            case 'UserNotFoundException':
                yield put(storeResetPasswordUsernameError(resetPasswordFormErrorCodes.USERNAME_NOT_FOUND));

                break;

            case 'LimitExceededException':
                Toast.error('attemptLimitExceeded');

                break;

            case 'NotAuthorizedException':
                Toast.error('initialLoginRequired');

                break;

            default:
                Toast.error('anErrorOccurred');

                break;
        }
    } finally {
        yield put(setIsLoadingResetPasswordForm(false));
    }
};

const submitConfirmResetPasswordFormSaga = function* submitConfirmResetPasswordFormSaga({payload}) {
    try {
        const {verificationCode, password} = payload;

        const username = yield select(selectResetPasswordFormUsername);

        yield call([Auth, Auth.forgotPasswordSubmit], username, verificationCode, password);

        Toast.success('newPasswordSaved');

        yield put(replace(getConfig().ROUTE_PATHS.LOGIN));
    } catch (error) {
        switch (error.code) {
            case 'InvalidParameterException':
                if (error.message.includes('Value at \'password\' failed to satisfy constraint')) {
                    yield put(storeResetPasswordFormPasswordError(resetPasswordFormErrorCodes.INVALID_PASSWORD));

                    break;
                }

                break;

            case 'UserNotFoundException':
                yield put(storeResetPasswordUsernameError(resetPasswordFormErrorCodes.USERNAME_NOT_FOUND));

                break;

            case 'LimitExceededException':
                Toast.error('attemptLimitExceeded');

                break;

            case 'CodeMismatchException':
                yield put(storeResetPasswordFormCodeError(resetPasswordFormErrorCodes.INVALID_VERIFICATION_CODE));

                break;

            case 'NotAuthorizedException':
                Toast.error('initialLoginRequired');

                break;

            default:
                Toast.error('anErrorOccurred');

                break;
        }
    } finally {
        yield put(setIsLoadingResetPasswordForm(false));
    }
};

export const accessControl = function* accessControl({payload}) {
    const isUserSignedIn = yield call(isUserLoggedIn);
    const {roles, isPrivate, shouldRequireContractSignature = true} = payload;

    if (!isUserSignedIn) {
        const location = yield select(selectRouterLocation);

        if (isPrivate) {
            // Private route, user is not logged in, redirect to login...
            yield put(push({
                pathname: getConfig().ROUTE_PATHS.LOGIN,
                state: {
                    pathname: location.pathname,
                    search: location.search,
                },
            }));

            return END_SIDE_EFFECTS_RUNNING;
        }

        // Public route, user is not logged in, no action needed...
        return;
    }

    const getIsEmailVerificationResult = yield call(getIsEmailVerificationRequired);

    if (getIsEmailVerificationResult) {
        yield call(forceEmailVerification);

        return END_SIDE_EFFECTS_RUNNING;
    }

    // Public route, user is signed in.
    if (!isPrivate) {
        yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));

        return END_SIDE_EFFECTS_RUNNING;
    }

    yield put(getLoggedInUser());

    const {payload: userAccount} = yield take(STORE_LOGGED_IN_USER);
    if (userAccount.status === USER_STATUSES.CONTRACT_PENDING && shouldRequireContractSignature) {
        const location = yield select(selectRouterLocation);
        const freelancer = yield select(FreelancerSelectors.selectAccount);

        if (location.pathname === '/freelance-onboarding'
            || location.pathname === generatePath(RoutePaths.DOCUMENTS, {
                companyId: freelancer?.defaultCompanyId,
            })) {
            return;
        }

        yield put(push('/freelance-onboarding'));

        return END_SIDE_EFFECTS_RUNNING;
    }

    // Get currently logged in user (from Cognito)
    yield call(getAndStoreCurrentUserInfo);

    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    // Private route, user is signed in, determine if the user has access...
    let hasAccess = false;

    if (!roles.length) {
        hasAccess = true;
    }

    if (roles.includes(UserRoles.FREELANCER) && currentCognitoUser.role === UserRoles.FREELANCER) {
        hasAccess = true;
    }

    if (roles.includes(UserRoles.CARE) && currentCognitoUser.role === UserRoles.CARE) {
        hasAccess = true;
    }

    if (roles.includes('COACH') && currentCognitoUser.role === 'COACH') {
        hasAccess = true;
    }

    if (roles.includes('ACCOUNTANT') && currentCognitoUser.role === 'ACCOUNTANT') {
        hasAccess = true;
    }

    if (roles.includes(UserRoles.ADMINISTRATOR) && currentCognitoUser.role === UserRoles.ADMINISTRATOR) {
        hasAccess = true;
    }

    if (
        roles.includes(UserRoles.PAYROLL_ADMINISTRATOR)
        && currentCognitoUser.role === UserRoles.PAYROLL_ADMINISTRATOR
    ) {
        hasAccess = true;
    }

    if (!hasAccess) {
        yield put(push(getConfig().ROUTE_PATHS.ACCESS_DENIED));

        Toast.error('accessDenied');

        return END_SIDE_EFFECTS_RUNNING;
    }
};

export const loginPageLoader = function* () {
    if (isLoggingOut) {
        isLoggingOut = false;

        return;
    }

    const isUserSignedIn = yield call(isUserLoggedIn);

    if (isUserSignedIn) {
        yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));
    }

    // Check for cookie
    const authCookie = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

    if (authCookie) {
        yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));
    }
};

export const watchAuthSagas = function* watchAuthSagas() {
    yield all([
        takeLatest(SUBMIT_NEW_PASSWORD_FORM, submitNewPasswordFormSaga),
        takeLatest(SUBMIT_RESET_PASSWORD_FORM, submitResetPasswordFormSaga),
        takeLatest(SUBMIT_CONFIRM_RESET_PASSWORD_FORM, submitConfirmResetPasswordFormSaga),
        takeLatest(LOGOUT, logoutSaga),
        fork(watchLoginFormSagas),
        fork(watchEmailVerificationFormSagas),
    ]);
};
