import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import {Box, CircularProgress, Typography, useMediaQuery} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CategoriesSearch from './CategoriesSearch';
import PopularCategoriesSection from './PopularCategoriesSection';
import EmptySearchSvg from '../../../../../../../../assets/svg/bank/empty-search.svg';
import {useDynamicIcon} from '../../../../../../../../hooks/useDynamicIcon';
import {createUnaccentedString} from '../../../../../../../../utils/string';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {COLORS_PALETTE} from '../../../../util/constants';

export const CategoriesList = ({
    selectedCategory,
    setSelectedCategory,
    side,
    text,
    isSubscribed,
    hasMultipleArticles,
}) => {
    const plainCategoryList = useSelector(TransactionsSelector.selectCategories);
    const subscribedCategoryList = useSelector(TransactionsSelector.selectCategoriesWithSubscriptionSupport);
    const articlesCategoryList = useSelector(TransactionsSelector.selectCategoriesWithArticlesSupport);

    const categoryList = hasMultipleArticles
        ? articlesCategoryList : (isSubscribed ? subscribedCategoryList : plainCategoryList);

    const [filteredCategories, setFilteredCategories] = useState([]);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    useEffect(() => {
        if (categoryList && categoryList.length > 0) {
            setFilteredCategories(categoryList);
        }
    }, [categoryList]);

    const filterCategories = search => {
        const upperCaseSearch = createUnaccentedString(search.trim().toUpperCase());

        const filteredList = categoryList.map(categoryParent => {
            let hasCategory = false;
            const mccGroupItems = categoryParent.mccGroupItems.map(category => {
                const hasFoundKeyword = category.uppercase[language]
                    .keywords.find(keyword => keyword.includes(upperCaseSearch))
                    || category.uppercase[language].categoryName.includes(upperCaseSearch);

                if (hasFoundKeyword) {
                    hasCategory = true;
                    return category;
                }

                return null;
            }).filter(Boolean);

            return hasCategory ? {...categoryParent, mccGroupItems} : null;
        }).filter(Boolean);

        setFilteredCategories(filteredList);
    };

    const onCategorySelect = category => {
        setSelectedCategory(category);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
            <CategoriesSearch
                onSetValue={filterCategories}
                text={text}
            />

            <PopularCategoriesSection
                onCategorySelect={onCategorySelect}
                side={side}
                isSubscribed={isSubscribed}
                hasMultipleArticles={hasMultipleArticles}
            />

            <CategoryList
                isLoadingCategories={false}
                filteredCategories={filteredCategories}
                selectedCategory={selectedCategory}
                onCategorySelect={onCategorySelect}
                language={language}
                categoryList={categoryList}
                side={side}
                isExpandedByDefault={!selectedCategory}
            />
        </Box>
    );
};

CategoriesList.propTypes = {
    selectedCategory: PropTypes.string,
    setSelectedCategory: PropTypes.func.isRequired,
    side: PropTypes.string.isRequired,
    text: PropTypes.string,
    isSubscribed: PropTypes.bool.isRequired,
    hasMultipleArticles: PropTypes.bool,
};

CategoriesList.defaultProps = {
    selectedCategory: '',
    text: '',
    hasMultipleArticles: false,
};

let categoryListEmpty = {};

export const CategoryList = ({
    isLoadingCategories,
    filteredCategories,
    selectedCategory,
    onCategorySelect,
    language,
    categoryList,
    isExpandedByDefault,
}) => {
    const {t} = useTranslation('bank');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const [expanded, setExpanded] = React.useState({});

    const hasFilter = categoryList?.length !== filteredCategories?.length;

    const handleChange = key => {
        if (hasFilter) {
            setExpanded({...expanded, [key]: !expanded[key]});
        } else {
            // eslint-disable-next-line
            if (expanded[key]) {
                setExpanded({...categoryListEmpty, [key]: false});
            } else {
                setExpanded({...categoryListEmpty, [key]: true});
            }
        }
    };

    useEffect(() => {
        if (categoryList.length > 0) {
            const expandedList = {};
            categoryList.forEach(categoryParent => {
                expandedList[categoryParent.mccGroupKey] = isExpandedByDefault;
            });
            categoryListEmpty = {...expandedList};
            setExpanded(expandedList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryList]);

    useEffect(() => {
        if (categoryList?.length > 0 && filteredCategories?.length > 0) {
            if (hasFilter) {
                const newExpanded = {};
                Object.keys(categoryListEmpty).forEach(key => { newExpanded[key] = true; });
                setExpanded(newExpanded);
            } else {
                setExpanded({...categoryListEmpty});
            }
        }
        // eslint-disable-next-line
    }, [hasFilter]);

    if (filteredCategories?.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh',
                width: '100%',
            }}
            >
                <img src={EmptySearchSvg} alt="empty search" />
                <Typography sx={{
                    maxWidth: '480px',
                    textAlign: 'center',
                    color: 'v2.light.text.disabled',
                }}
                >
                    {t('categorisation.noCategories')}
                </Typography>
            </Box>
        );
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
            }}
        >
            {isLoadingCategories && <CircularProgress />}
            {!isLoadingCategories
            && filteredCategories
            && filteredCategories.map((categoryParent, index) => {
                return (
                    <Accordion
                        expanded={expanded[categoryParent.mccGroupKey]}
                        onChange={() => {
                            handleChange(categoryParent.mccGroupKey);
                        }}
                        key={categoryParent.mccGroupKey}
                        sx={{
                            'borderRadius': '32px !important',
                            'background': 'white',
                            'boxShadow': 'none',
                            '&:before': {
                                display: 'none',
                            },
                        }}
                        disableGutters={true}
                    >
                        <Box sx={{
                            'background': 'white',
                            'position': 'sticky',
                            'top': 0,
                        }}
                        >
                            <AccordionSummary
                                sx={{
                                    'background': COLORS_PALETTE[categoryParent.mccGroupKey],
                                    'borderRadius': '32px',
                                    'zIndex': `${index + 2}`,
                                }}
                                expandIcon={<KeyboardArrowDownTwoToneIcon />}
                                aria-controls="panel1a-content"
                            >
                                <Typography sx={{
                                    fontWeight: 500,
                                    color: 'text_v2.secondary',
                                    top: '20px',
                                }}
                                >
                                    {categoryParent?.mccGroupName?.[language]}
                                </Typography>
                            </AccordionSummary>
                        </Box>
                        <AccordionDetails sx={{
                            background: 'white',
                            p: 0,
                            mt: 1,
                            display: 'flex',
                            flexDirection: isMobileSize ? 'column' : 'row',
                            flexWrap: isMobileSize ? 'nowrap' : 'wrap',
                        }}
                        >
                            {categoryParent.mccGroupItems.map(category => {
                                return (
                                    <SingleCategory
                                        key={category.mccKey}
                                        category={category}
                                        selectedCategory={selectedCategory}
                                        onCategorySelect={onCategorySelect}
                                        language={language}
                                    />
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

CategoryList.propTypes = {
    isLoadingCategories: PropTypes.bool,
    filteredCategories: PropTypes.array,
    selectedCategory: PropTypes.string,
    onCategorySelect: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    categoryList: PropTypes.array,
    isExpandedByDefault: PropTypes.bool,
};

CategoryList.defaultProps = {
    filteredCategories: [],
    categoryList: [],
    selectedCategory: '',
    isLoadingCategories: false,
    isExpandedByDefault: false,
};

const SingleCategory = ({category, selectedCategory, onCategorySelect, language}) => {
    const isSelected = category.mccKey === selectedCategory;
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const faIcon = useDynamicIcon(category.faIcon);

    return (
        <Box
            key={category.mccKey}
            sx={{
                'p': 1,
                'py': isMobileSize ? 2 : 1,
                'color': isSelected ? '#fff' : 'text_v2.secondary',
                'backgroundColor': isSelected ? 'primary_v2.main' : 'transparent',
                'borderRadius': '8px',
                'display': 'flex',
                'cursor': 'pointer',
                'minWidth': isMobileSize ? 'auto' : '25%',
                'maxWidth': isMobileSize ? 'auto' : '25%',
                '&:hover': {
                    backgroundColor: isSelected ? 'primary_v2.main' : 'rgba(25, 118, 210, 0.04)',
                },
                'alignItems': 'center',
            }}
            onClick={() => {
                onCategorySelect(category);
            }}
        >
            {faIcon && (<FontAwesomeIcon icon={faIcon} />)}
            <Typography sx={{
                ml: 2,
                fontWeight: 500,
                color: isSelected ? '#fff' : 'text_v2.secondary',
            }}
            >
                {category[language].categoryName}
            </Typography>
        </Box>
    );
};

SingleCategory.propTypes = {
    category: PropTypes.object.isRequired,
    selectedCategory: PropTypes.string,
    onCategorySelect: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
};

SingleCategory.defaultProps = {
    selectedCategory: '',
};

export default CategoriesList;
