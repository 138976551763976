import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Divider, Grid, Typography, useMediaQuery} from '@mui/material';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Document from './Document/Document';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../../features/ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import {Toast} from '../../../../lib/toast';
import {range} from '../../../../utils/number';
import {isUserCareOrFreelancer} from '../../../../utils/user-roles';
import {createCompanyDocumentsSelector} from '../../../app/company/companyDocuments/companyDocuments.selectors';
import {
    setUploadDocumentsStep,
    storeUploadDocumentsData,
    uploadDocuments,
} from '../../../app/company/companyDocuments/uploadDocuments/uploadDocuments.actions';
import {
    selectActiveUploadDocumentsStep,
    selectIsUploadingDocuments,
    selectUploadDocumentsData,
} from '../../../app/company/companyDocuments/uploadDocuments/uploadDocuments.selectors';
import {
    DOCUMENT_TYPES,
    DOCUMENT_TYPES_BY_CATEGORY,
    DOCUMENT_TYPES_BY_CATEGORY_FOR_FREELANCER,
    FIRST_YEAR,
    UPLOADABLE_DOCUMENT_CATEGORIES,
    UPLOADABLE_DOCUMENT_CATEGORIES_FOR_FREELANCER,
    UPLOAD_DOCUMENTS_STEPS,
} from '../../../config/constants/documentConstants';
import ButtonWithCircularProgress from '../ButtonWithCircularProgress';
import UploadDocuments from '../UploadDocuments';

const modalKey = ModalsKeys.UPLOAD_DOCUMENTS;

const shouldItemHaveYear = type => [
    DOCUMENT_TYPES.BALANCE_SHEET,
    DOCUMENT_TYPES.LIASSE_FISCALE,
].includes(type);

const UploadDocumentsModal = () => {
    const {t} = useTranslation('documents');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dispatch = useDispatch();

    const documents = useSelector(createCompanyDocumentsSelector());
    const activeStep = useSelector(selectActiveUploadDocumentsStep);
    const isUploading = useSelector(selectIsUploadingDocuments);
    const data = useSelector(selectUploadDocumentsData);
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const params = useParams();

    const handleAddFiles = useCallback(addedFiles => {
        const newFiles = addedFiles.filter(addedFile => {
            if (!data.find(item => item.file.name === addedFile.name)) {
                return true;
            }

            Toast.error('fileAlreadyAdded', {
                translationValues: {
                    fileName: addedFile.name,
                },
            });

            return false;
        });

        dispatch(storeUploadDocumentsData([
            ...data,
            ...newFiles.map(newFiles => ({
                file: newFiles,
                type: isUserCareOrFreelancer(user) ? 'OTHER' : '', // hard-coded for freelancers
                category: '',
            })),
        ]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch, user.role]);

    const handleDeleteFile = useCallback(fileName => {
        dispatch(storeUploadDocumentsData(data.filter(item => item.file.name !== fileName)));
    }, [data, dispatch]);

    const handleProceed = useCallback(() => {
        if (activeStep === UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS) {
            dispatch(setUploadDocumentsStep(UPLOAD_DOCUMENTS_STEPS.MAP_CATEGORIES_AND_TYPES));

            return;
        }

        dispatch(uploadDocuments(false, params.freelancerId, params.companyId));
    }, [activeStep, dispatch, params.companyId, params.freelancerId]);

    const createDocumentTypeChangeHandler = useCallback(updatedIndex => event => {
        dispatch(storeUploadDocumentsData(data.map((item, index) => {
            if (updatedIndex === index) {
                return {
                    ...item,
                    type: event.target.value,
                    year: null,
                };
            }

            return item;
        })));
    }, [data, dispatch]);

    const createDocumentCategoryChangeHandler = useCallback(updatedIndex => event => {
        dispatch(storeUploadDocumentsData(data.map((item, index) => {
            if (updatedIndex === index) {
                return {
                    ...item,
                    category: event.target.value,
                    type: isUserCareOrFreelancer(user) ? 'OTHER' : '', // hard-coded for freelancers
                    year: null,
                };
            }

            return item;
        })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch, user.role]);

    const createDocumentYearChangeHandler = useCallback(updatedIndex => event => {
        dispatch(storeUploadDocumentsData(data.map((item, index) => {
            if (updatedIndex === index) {
                return {
                    ...item,
                    year: event.target.value,
                };
            }

            return item;
        })));
    }, [data, dispatch]);

    const pastYear = new Date().getFullYear() - 1;
    const years = range(FIRST_YEAR, pastYear);

    const getAvailableDocumentTypes = useCallback(index => {
        const item = data[index];

        if (!item.category) {
            return [];
        }

        const uploadedDocumentTypes = documents
            .filter(document => document.category === item.category)
            .map(document => document.type);

        const allowedDocumentTypes = isUserCareOrFreelancer(user)
            ? DOCUMENT_TYPES_BY_CATEGORY_FOR_FREELANCER
            : DOCUMENT_TYPES_BY_CATEGORY;

        const types = Object.values(allowedDocumentTypes[item.category]);
        const usedTypes = data
            .filter((_, metadataIndex) => metadataIndex !== index)
            .map(item => item.type);

        return types.filter(type => {
            if (type === allowedDocumentTypes[UPLOADABLE_DOCUMENT_CATEGORIES.ADMINISTRATIVE].BALANCE_SHEET) {
                return true;
            } else if (type === allowedDocumentTypes[UPLOADABLE_DOCUMENT_CATEGORIES.ADMINISTRATIVE].PAYSLIP) {
                return true;
            } else if (type === allowedDocumentTypes[UPLOADABLE_DOCUMENT_CATEGORIES.ADMINISTRATIVE].LIASSE_FISCALE) {
                return true;
            } else if (type === allowedDocumentTypes[item.category].OTHER) {
                return true;
            }

            return ![
                ...usedTypes,
                ...uploadedDocumentTypes,
            ].includes(type);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, documents, user.role]);

    const hasYear = data.some(item => shouldItemHaveYear(item.type));

    const hasEmptyField = useMemo(() => data.some(item => !item.type
        || !item.category
        || (shouldItemHaveYear(item.type) && !item.year)),
    [data]);

    const handleCloseModal = useCallback(() => {
        dispatch(setUploadDocumentsStep(UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS));
        dispatch(storeUploadDocumentsData([]));
    }, [dispatch]);

    return (
        <ModalWrapper
            modalKey={modalKey}
            containerSx={{
                width: '900px',
            }}
            hasShowClose={!isUploading}
            handleCloseCB={handleCloseModal}
        >
            {() => (
                <Box sx={!isMobileSize ? {} : {p: 2, pt: 0}}>
                    <Typography
                        sx={{mx: 1, mb: 2}}
                        variant={isMobileSize ? 'h5' : 'h4'}
                    >
                        {t('attachDocuments')}
                    </Typography>

                    <Box sx={{transform: 'translate3d(0, 0, 0)'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 3,
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                            >
                                {activeStep === UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS ? t('pleaseAttachDocuments') : t('pleaseLinkFiles')}
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                            >
                                {t('step')}&nbsp;{activeStep === UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS ? 1 : 2}/2
                            </Typography>
                        </Box>

                        {activeStep === UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS && (
                            <UploadDocuments
                                files={data.map(item => item.file)}
                                onAddFiles={handleAddFiles}
                                onDeleteFile={handleDeleteFile}
                                fileLimit={0}
                                acceptFiles="image/jpeg, image/png, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        )}

                        {activeStep === UPLOAD_DOCUMENTS_STEPS.MAP_CATEGORIES_AND_TYPES && (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={hasYear ? 4 : 6}>
                                        <Typography
                                            variant="body2"
                                            sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                                        >
                                            {t('fileName')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body2"
                                            sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                                        >
                                            {t('category')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Typography
                                            variant="body2"
                                            sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                                        >
                                            {t('documentType')}
                                        </Typography>
                                    </Grid>

                                    {hasYear && (
                                        <Grid item xs={2}>
                                            <Typography
                                                variant="body2"
                                                sx={theme => ({fontWeight: theme.typography.fontWeightMedium})}
                                            >
                                                {t('year')}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>

                                <Divider sx={{mt: 3}} />

                                {data.map((item, index) => {
                                    return (
                                        <React.Fragment key={item.file.name}>
                                            <Document
                                                type={item.type}
                                                category={item.category}
                                                year={item.year}
                                                onTypeChange={createDocumentTypeChangeHandler(index)}
                                                onCategoryChange={createDocumentCategoryChangeHandler(index)}
                                                onYearChange={createDocumentYearChangeHandler(index)}
                                                categories={Object.values(isUserCareOrFreelancer(user)
                                                    ? UPLOADABLE_DOCUMENT_CATEGORIES_FOR_FREELANCER
                                                    : UPLOADABLE_DOCUMENT_CATEGORIES)}
                                                types={getAvailableDocumentTypes(index)}
                                                years={years}
                                                isLoading={isUploading}
                                                data={item}
                                                hasYear={hasYear}
                                                isYearVisible={shouldItemHaveYear(item.type)}
                                            />

                                            {data.length !== index + 1 && <Box cx={{borderTop: '1px dashed #BDBDBD'}} />}
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        )}
                    </Box>

                    <Grid
                        justifyContent={activeStep === UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS ? 'center' : 'space-between'}
                        container
                    >
                        {activeStep === UPLOAD_DOCUMENTS_STEPS.MAP_CATEGORIES_AND_TYPES && (
                            <Button
                                variant="contained"
                                size="large"
                                sx={{mt: 4}}
                                startIcon={<ArrowBackIcon />}
                                onClick={
                                    () => dispatch(setUploadDocumentsStep(UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS))
                                }
                                disabled={isUploading}
                            >
                                {t('common:back')}
                            </Button>
                        )}

                        <ButtonWithCircularProgress
                            buttonProps={{
                                variant: 'contained',
                                color: 'secondary',
                                size: 'large',
                                sx: {mt: 4},
                                endIcon: <ArrowForwardIcon />,
                                onClick: handleProceed,
                                disabled: !data.length
                                    || (activeStep === UPLOAD_DOCUMENTS_STEPS.MAP_CATEGORIES_AND_TYPES && hasEmptyField)
                                    || isUploading,
                            }}
                            isLoading={isUploading}
                        >
                            {t('proceed')}
                        </ButtonWithCircularProgress>
                    </Grid>
                </Box>
            )}
        </ModalWrapper>
    );
};

export default UploadDocumentsModal;
