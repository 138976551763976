import {
    faHourglassStart,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {AccountBalanceTableDataRowAmount} from './account-balance-data-row/AccountBalanceTableDataRowAmount';
import Category from './account-balance-data-row/AccountBalanceTableDataRowCategory';
import ChipSection from './account-balance-data-row/AccountBalanceTableDataRowChip';
import AccountBalanceTableDataRowDate from './account-balance-data-row/AccountBalanceTableDataRowDate';
import Vat from './account-balance-data-row/AccountBalanceTableDataRowVat';
import AccountBalanceIcon from './AccountBalanceIcon';
import Collapse from '../../../../../../components/joy-ui/mui-legacy/Collapse';
import useMediaQuery from '../../../../../../components/joy-ui/mui-legacy/useMediaQuery';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {TransactionsSelector} from '../../store/transactions.selector';
import {
    MAX_TRANSACTIONS_ANIMATION,
    TransactionSide,
} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';

const currentYear = moment().format('YYYY');
const AccountBalanceTableDataRow = ({
    id,
    prevId,
    isSidebarOpened,
    tableSize,
    isMobileSize,
    filter,
    onOpenClick,
    language,
    selectCategoriesObject,
    selectSourcesObject,
    t,
    areFieldsCollapsed,
    totalLoadedIds,
    isUserHasInvoiceAccess,
    combinedTabIcons,
}) => {
    const data = useSelector(TransactionsSelector.createSelectTransactionById(id));
    const prevData = useSelector(TransactionsSelector.createSelectTransactionById(prevId));
    const isMobileSize460 = useMediaQuery(`(max-width:460px)`);

    if (!data) {
        return null;
    }

    // Do not display if filter for not categorized is active, but it has no chip flag
    if (!data.hasChip && filter.isNotCategorized) {
        return null;
    }

    const isDebit = data.side === 'DEBIT';
    let AdditionalContent = null;
    let isEditable = true;

    const {
        isRejected,
        isCancelled,
        isOnHold,
        isUpcoming,
        isNotCategorized,
        isCategorized,
        isAutoCategorized,
    } = AccountBalanceHelperFunctions.getCategorizationState(data);

    const isRejectedOrCanceled = isCancelled || isRejected;

    const isChipVisible = isUserHasInvoiceAccess || data?.side !== TransactionSide.CREDIT;

    const hasChips = isChipVisible
        && (
            isNotCategorized
            || isCategorized
            || isRejected
            || isCancelled
        );

    if (currentYear !== data.date.format('YYYY')) {
        isEditable = false;
    } else if (isRejectedOrCanceled) {
        isEditable = false;
    }

    let isNewDate = false;

    if (!prevData || (!prevData.date.isSame(data.date, 'day'))) {
        isNewDate = true;
    }

    if (prevData && (prevData.date.format('M') !== data.date.format('M'))) {
        let displayYear = '';
        if (currentYear !== data.date.format('YYYY')) {
            displayYear = data.date.format('YYYY');
        }

        AdditionalContent = (
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                mt: '-2px',
                mb: 0,
                textTransform: 'capitalize',
                pl: 1,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '19px',
                    height: '100%',
                    minHeight: '70px',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        borderRight: '1px solid',
                        borderColor: 'primary.100',
                        display: 'flex',
                        flexGrow: 1,
                        zIndex: 4,
                    }}
                    />
                </Box>
                <Typography
                    level="h4"
                    component="div"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        ml: 'calc(5% - 24px)',
                        position: 'relative',
                        top: '4px',
                        fontWeight: 'lg',
                        color: 'text.secondary',
                    }}
                >
                    { data.date.format('MMMM') } { displayYear }
                </Typography>
            </Box>
        );
    }

    if (isMobileSize) {
        return (
            <Box
                key={data.id}
                onClick={() => onOpenClick(data.id, data.hasChip && isChipVisible, data.status)}
            >

                <Box sx={{
                    display: 'flex',
                }}
                >
                    <Box sx={{width: '50px', mr: 1}}>
                        <AccountBalanceTableDataRowDate
                            date={data.date}
                            isNewDate={isNewDate}
                            isUpcoming={isUpcoming}
                            isV3
                        />
                    </Box>

                    <Stack
                        direction="column"
                        gap={1}
                        sx={{
                            width: 'calc(100vw - 50px - 16px - 64px)',
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                            py: 2,
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    maxWidth: isMobileSize460 ? '60%' : '80%',
                                }}
                            >
                                <AccountBalanceIcon
                                    paymentType={data.type}
                                    category={data.category}
                                    sourceOfRevenue={data.sourceOfRevenue}
                                    hasBackground={false}
                                    iconColor={isRejectedOrCanceled ? 'text.tertiary' : 'neutral.400'}
                                    size="20px"
                                    isV3
                                    boxSx={{
                                        ml: 0,
                                    }}
                                />

                                <Typography
                                    level="body-md"
                                    sx={{
                                        fontWeight: 'lg',
                                        fontSize: 'md',
                                        lineHeight: 'md',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        color: isRejectedOrCanceled ? 'text.tertiary' : 'neutral.plainColor',
                                        maxWidth: isMobileSize460 ? '60%' : '90%',
                                    }}
                                >
                                    {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                                </Typography>

                                {isChipVisible && isOnHold && (
                                    <ConditionalTooltip
                                        tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                                        isV3
                                    >
                                        <Box
                                            fontSize="md"
                                            sx={{
                                                color: 'warning.solidBg',
                                                ml: 1,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faHourglassStart}
                                                fixedWidth
                                            />
                                        </Box>
                                    </ConditionalTooltip>
                                )}

                                {AccountBalanceHelperFunctions.showLabelIcon(data, combinedTabIcons)}
                            </Stack>
                            <AccountBalanceTableDataRowAmount
                                t={t}
                                isDebit={isDebit}
                                isRejectedOrCancelled={isRejectedOrCanceled}
                                isUpcoming={isUpcoming}
                                amount={data.amount}
                                currency={data.currency}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                        >
                            {hasChips && (
                                <ChipSection
                                    isNotCategorized={isNotCategorized}
                                    isRejected={isRejected}
                                    isCancelled={isCancelled}
                                    isCategorized={isCategorized}
                                    isAutoCategorized={isAutoCategorized}
                                    t={t}
                                    isV3
                                />
                            )}
                            <Category
                                side={data.side}
                                category={data.category}
                                sourceOfRevenue={data.sourceOfRevenue}
                                language={language}
                                selectSourcesObject={selectSourcesObject}
                                selectCategoriesObject={selectCategoriesObject}
                                isRejectedOrCanceled={isRejectedOrCanceled}
                                isV3
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        );
    }

    const CollapseElement = totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? Box : Collapse;

    return (
        <Box
            key={data.id}
            onClick={() => onOpenClick(data.id, data.hasChip && isChipVisible, data.status)}
        >
            {AdditionalContent}
            <Box sx={{
                'display': 'flex',
                'alignItems': 'center',
                'overFlowX': 'hidden',
                'borderBottom': '1px solid',
                'borderColor': 'divider',
                ...(isUpcoming ? {
                    backgroundColor: 'background.surface',
                } : {}),
                '&:hover': {
                    'cursor': 'pointer',
                    'backgroundColor': 'background.surface',
                    '& .vat-box': {
                        visibility: 'visible',
                    },
                },
            }}
            >
                <Box sx={{
                    flex: tableSize.firstSegment,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    mb: '-1px',
                    pb: '1px',
                    backgroundColor: 'white',
                    pr: 1,
                }}
                >
                    <AccountBalanceTableDataRowDate
                        date={data.date}
                        isNewDate={isNewDate}
                        isUpcoming={isUpcoming}
                        isV3
                    />
                </Box>

                <Box sx={{
                    flex: tableSize.secondSegment,
                }}
                >
                    {hasChips && (
                        <ChipSection
                            isNotCategorized={isNotCategorized}
                            isRejected={isRejected}
                            isCancelled={isCancelled}
                            isCategorized={isCategorized}
                            isAutoCategorized={isAutoCategorized}
                            t={t}
                            isV3
                        />
                    )}
                </Box>

                <Box sx={{
                    flex: tableSize.thirdSegment,
                    maxWidth: '35%',
                    pr: 2,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <AccountBalanceIcon
                            paymentType={data.type}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            hasBackground={false}
                            iconColor={isRejectedOrCanceled ? 'text.tertiary' : 'neutral.400'}
                            size="20px"
                            isV3
                        />

                        <Typography
                            level="body-md"
                            sx={{
                                fontWeight: 'lg',
                                fontSize: 'md',
                                lineHeight: 'md',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                color: isRejectedOrCanceled ? 'text.tertiary' : 'neutral.plainColor',
                            }}
                        >
                            {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                        </Typography>

                        {isChipVisible && isOnHold && (
                            <ConditionalTooltip
                                tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                                isV3
                            >
                                <Box
                                    fontSize="md"
                                    sx={{
                                        color: 'warning.solidBg',
                                        ml: 1,
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faHourglassStart}
                                        fixedWidth
                                    />
                                </Box>
                            </ConditionalTooltip>
                        )}

                        {AccountBalanceHelperFunctions.showLabelIcon(data, combinedTabIcons)}
                    </Box>
                </Box>

                <CollapseElement
                    in={!isSidebarOpened || !areFieldsCollapsed}
                    sx={{
                        flex: tableSize.fourthSegment,
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Category
                            side={data.side}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            language={language}
                            selectSourcesObject={selectSourcesObject}
                            selectCategoriesObject={selectCategoriesObject}
                            isRejectedOrCanceled={isRejectedOrCanceled}
                            isV3
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                visibility: 'hidden',
                            }}
                            className="vat-box"
                        >
                            {data.category && data?.side === TransactionSide.DEBIT && (
                                <Vat data={data} isEditable={isEditable} />
                            )}
                        </Box>
                    </Box>
                </CollapseElement>

                <Box sx={{
                    flex: tableSize.fifthSegment,
                    pr: 1.2,
                }}
                >
                    <AccountBalanceTableDataRowAmount
                        t={t}
                        isDebit={isDebit}
                        isRejectedOrCancelled={isRejectedOrCanceled}
                        isUpcoming={isUpcoming}
                        amount={data.amount}
                        currency={data.currency}
                    />
                </Box>
            </Box>
        </Box>
    );
};

AccountBalanceTableDataRow.propTypes = {
    id: PropTypes.string.isRequired,
    prevId: PropTypes.string,
    isSidebarOpened: PropTypes.bool.isRequired,
    tableSize: PropTypes.object.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    onOpenClick: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    selectCategoriesObject: PropTypes.object.isRequired,
    selectSourcesObject: PropTypes.object.isRequired,
    areFieldsCollapsed: PropTypes.bool,
    t: PropTypes.func.isRequired,
    totalLoadedIds: PropTypes.number,
    combinedTabIcons: PropTypes.object,
    isUserHasInvoiceAccess: PropTypes.bool.isRequired,
};

AccountBalanceTableDataRow.defaultProps = {
    areFieldsCollapsed: false,
    prevId: '',
    totalLoadedIds: 0,
    combinedTabIcons: null,
};

export default AccountBalanceTableDataRow;
