import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import useMediaQuery from '../../../../components/joy-ui/mui-legacy/useMediaQuery';

export const JoyActionsComponent = props => {
    const {page, onPageChange, count, rowsPerPage} = props;
    const isNextDisabled = count !== -1 ? page >= Math.ceil(count / rowsPerPage) - 1 : false;
    const isPrevDisabled = page === 0;
    const {t} = useTranslation('common');
    const isMobileSize = useMediaQuery(`(max-width:625px)`);

    return (
        <JoyUIThemeProvider>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    display: 'flex',
                    flexBasis: {
                        mobile: '100%',
                    },
                    ...(isMobileSize && {
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                    }),
                }}
            >
                <Button
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                    onClick={e => onPageChange(e, page - 1)}
                    disabled={isPrevDisabled}
                >
                    {t('previous')}
                </Button>
                <Button
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                    disabled={isNextDisabled}
                    onClick={e => onPageChange(e, page + 1)}
                >
                    {t('next')}
                </Button>

            </Stack>
        </JoyUIThemeProvider>
    );
};

JoyActionsComponent.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};
