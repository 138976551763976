import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';

export const StatusAlert = ({color, icon, title, description}) => (
    <Alert color={color} startDecorator={<FontAwesomeIcon icon={icon} />} sx={{alignItems: 'baseline'}}>
        <Stack>
            <Typography
                variant="title-md"
                textTransform="uppercase"
                fontStyle="italic"
                fontWeight={700}
                textColor="inherit"
            >
                {title}
            </Typography>
            {description && (
                <Typography level="body-md" textColor="inherit">
                    {description}
                </Typography>
            )}
        </Stack>
    </Alert>
);

StatusAlert.propTypes = {
    color: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};

StatusAlert.defaultProps = {
    description: undefined,
};
