import {useDispatch} from 'react-redux';
import {routerActions} from '../lib/router/connected-router-saga/connected-router.actions';

export const useGoBackOrFallback = () => {
    const dispatch = useDispatch();
    return fallbackRoute => {
        if (window.history.length > 1) {
            dispatch(routerActions.back());
        } else {
            dispatch(routerActions.push(fallbackRoute));
        }
    };
};
