import {Button, Stack, Typography} from '@mui/joy';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {MAX_NUMBER_OF_BANKS} from '../../modules/bridge-api/utils/constants';

const modalKey = ModalsKeys.BANK_ACCOUNT_LIMIT;

const BankAccountLimitModal = () => {
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();

    return (
        <ModalWrapper
            isV3
            modalKey={modalKey}
            // hasShowClose={!inProgress}
            title={(
                <Typography level="h1">
                    {t('bankAccountLimitModal.title')}
                </Typography>
            )}
            containerSx={{
                width: '580px',
            }}
        >
            <Stack gap={4}>
                <Typography>
                    {t('bankAccountLimitModal.content', {maxBankAccounts: MAX_NUMBER_OF_BANKS})}
                </Typography>
                <Button
                    color="primary"
                    onClick={() => {
                        dispatch(UiActions.setActiveModal(ModalsKeys.BANK_ACCOUNT_LIMIT, false));
                    }}
                    fullWidth
                >
                    {t('bankAccountLimitModal.close')}
                </Button>
            </Stack>
        </ModalWrapper>
    );
};

export {
    BankAccountLimitModal,
};
