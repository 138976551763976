import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useGoBackOrFallback} from '../../../hooks/use-go-back-or-fallback';

export const BackButton = ({onBackClick, fallbackRoute}) => {
    const {t} = useTranslation('vatDeclaration');
    const goBackOrFallback = useGoBackOrFallback();
    const handleClick = () => {
        if (onBackClick) {
            onBackClick();
        } else {
            goBackOrFallback(fallbackRoute);
        }
    };

    return (
        <Button
            variant="plain"
            color="primary"
            size="sm"
            startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
            sx={{'color': 'linen.500', '--variant-plainHoverBg': 'transparent'}}
            onClick={handleClick}
        >
            {t('back')}
        </Button>
    );
};

BackButton.propTypes = {
    onBackClick: PropTypes.func,
    fallbackRoute: PropTypes.string.isRequired,
};

BackButton.defaultProps = {
    onBackClick: undefined,
};
