import AdapterMoment from '@date-io/moment';
import {
    faArrowLeft,
    faBoxArchive,
    faCircleXmark,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Input,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/joy';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AlertInfo} from '../../../../components/joy-ui/alerts/AlertInfo';
import {AlertWarning} from '../../../../components/joy-ui/alerts/AlertWarning';
import DropZone from '../../../../components/joy-ui/dropzone/DropZone';
import {FormErrorText} from '../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../components/joy-ui/forms/FormFieldLabel';
import {SelectItem} from '../../../../components/joy-ui/forms/SelectItem';
import {DatePicker, LocalizationProvider} from '../../../../components/joy-ui/x-date-pickers';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {useIsMobileSize} from '../../../../hooks/use-is-mobile-size';
import Yup from '../../../../lib/yup/yup';
import {BE_DATE_FORMAT} from '../../../../utils/constants';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {requiredFieldProps} from '../../../user/modules/create-user-form/components/helper';
import {BankActions} from '../../store/bank.action';

const modalKey = ModalsKeys.ARCHIVE_INTEGRATION_MODAL;

const ARCHIVE_MODAL_VALUES = {
    1: 'ARCHIVE',
    2: 'DELETE',
};

const formElements = {
    selectedAccountAction: 'selectedAccountAction',
    archivingDate: 'archivingDate',
    bankStatements: 'bankStatements',
    userConsent: 'userConsent',
};

const defaultValues = {
    [formElements.selectedAccountAction]: '',
    [formElements.archivingDate]: null,
    [formElements.bankStatements]: [],
    [formElements.userConsent]: false,
};

const createValidationSchema = () => Yup.object().shape({
    selectedAccountAction: Yup.string().equals([ARCHIVE_MODAL_VALUES['1']]).required(),
    archivingDate: Yup.date().required(),
    bankStatements: Yup.array().min(1).max(12),
    userConsent: Yup.boolean().equals([true]).required(),
});

const ArchiveIntegrationModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');
    const isMobileSize = useIsMobileSize();

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
        watch,
        reset,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t)),
        defaultValues: defaultValues,
        mode: 'onChange',
    });

    const selectedAccountAction = watch(formElements.selectedAccountAction);

    const resetDataState = () => {
        reset();
    };

    const isArchiving = useLoadingState(LoadingTypes.DELETE_BANK);
    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const handleClick = createSubmitHandler(submitData => {
        dispatch(BankActions.archiveIntegrationAccount({
            integration: data?.integration,
            archivingDate: moment(submitData.archivingDate).format(BE_DATE_FORMAT),
            bankStatements: submitData.bankStatements,
        }));
    });

    const handleDelete = () => {
        resetDataState();
        dispatch(UiActions.setModalData(modalKey, null));
        dispatch(UiActions.setModalData(ModalsKeys.DELETE_INTEGRATION_MODAL, {integration: data?.integration}));

        dispatch(UiActions.setActiveModal(modalKey, false));
        dispatch(UiActions.setActiveModal(ModalsKeys.DELETE_INTEGRATION_MODAL, true));
    };

    const showAdditionalFields = selectedAccountAction === ARCHIVE_MODAL_VALUES['1'];
    const hasAlert = selectedAccountAction === ARCHIVE_MODAL_VALUES['2'];

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '680px'}}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('accountArchiving.archiveTitle')}
                </Typography>
            )}
            handleOpenCB={() => resetDataState()}
            buttons={({handleClose}) => (
                <>
                    <Button
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faArrowLeft} />
                        )}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                        size="lg"
                    >
                        {t('accountArchiving.backButton')}
                    </Button>

                    <Button
                        loading={isArchiving}
                        disabled={!isValid}
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faBoxArchive} />
                        )}
                        loadingPosition="start"
                        onClick={handleClick}
                        color="danger"
                        variant="soft"
                        size="lg"
                    >
                        {t('archivingActions.archive')}
                    </Button>
                </>
            )}
        >
            <Typography level="body-lg" sx={{mb: 2, color: 'text.secondary'}}>
                {t('accountArchiving.archiveText')}
            </Typography>

            <Stack
                direction="column"
                gap={{desktop: 3, mobile: 2}}
            >
                <AlertInfo>
                    {t('accountArchiving.archiveAlertText')}
                </AlertInfo>

                <Controller
                    control={control}
                    name={formElements.selectedAccountAction}
                    render={({field}) => (
                        <RadioGroup
                            name={formElements.selectedAccountAction}
                            {...field}
                            sx={{
                                gap: {desktop: 3, mobile: 2},
                                m: 0,
                            }}
                        >
                            <SelectItem
                                value={ARCHIVE_MODAL_VALUES['1']}
                                name={t('accountArchiving.archiveQuestion')}
                                text={t('accountArchiving.archiveAnswer1')}
                                isSelected={selectedAccountAction === ARCHIVE_MODAL_VALUES['1']}
                                additionalContent={showAdditionalFields ? (
                                    <Stack
                                        direction="column"
                                        gap={2}
                                        ml={4}
                                    >
                                        <Controller
                                            control={control}
                                            name="archivingDate"
                                            render={({field}) => (
                                                <LocalizationProvider
                                                    dateLibInstance={moment}
                                                    dateAdapter={AdapterMoment}
                                                >
                                                    <DatePicker
                                                        {...field}
                                                        openTo="day"
                                                        views={['year', 'month', 'day']}
                                                        renderInput={({inputProps, ...rest}) => {
                                                            return (
                                                                <FormControl error={!!errors.archivingDate}>
                                                                    <FormFieldLabel
                                                                        label={t('accountArchiving.archiveDate')}
                                                                        color="text.secondary"
                                                                        sx={{
                                                                            fontWeight: 'xl',
                                                                        }}
                                                                    />
                                                                    <Input
                                                                        {...rest}
                                                                        {...inputProps}
                                                                        placeholder="dd/mm/yyyy"
                                                                        color={errors.archivingDate ? 'danger' : 'neutral'}
                                                                        error={!!errors.archivingDate}
                                                                        {...requiredFieldProps}
                                                                    />
                                                                    <FormErrorText
                                                                        errorMessage={errors.archivingDate?.message}
                                                                    />
                                                                </FormControl>
                                                            );
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name={formElements.bankStatements}
                                            render={({field}) => (
                                                <ArchiveAccountDropzone
                                                    field={field}
                                                    isMobileSize={isMobileSize}
                                                    t={t}
                                                />
                                            )}
                                        />
                                    </Stack>
                                ) : null}
                            />
                            <SelectItem
                                value={ARCHIVE_MODAL_VALUES['2']}
                                name={t('accountArchiving.archiveQuestion')}
                                text={t('accountArchiving.archiveAnswer2')}
                                isSelected={selectedAccountAction === ARCHIVE_MODAL_VALUES['2']}
                            />
                        </RadioGroup>
                    )}
                />

                {hasAlert && (
                    <AlertWarning
                        title={t('accountArchiving.warningTitle')}
                        text={t('accountArchiving.warningText')}
                    >
                        <Button
                            fullWidth
                            onClick={handleDelete}
                            startDecorator={(
                                <FontAwesomeIcon icon={faCircleXmark} />
                            )}
                            color="warning"
                            size="lg"
                            variant="solid"
                        >
                            {t('archivingActions.close')}
                        </Button>
                    </AlertWarning>
                )}

                {!!selectedAccountAction && (
                    <Controller
                        control={control}
                        name={formElements.userConsent}
                        render={({field}) => (
                            <FormControl sx={{ml: 2}}>
                                <Checkbox
                                    {...field}
                                    variant="soft"
                                    color="neutral"
                                    size="lg"
                                    label={(
                                        <Typography
                                            level="body-md"
                                            sx={{
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {t('accountArchiving.archiveCheckboxConsent')}
                                        </Typography>
                                    )}
                                    disabled={selectedAccountAction === ARCHIVE_MODAL_VALUES['2']}
                                />
                            </FormControl>
                        )}
                    />
                )}
            </Stack>
        </ModalWrapper>
    );
};

const ArchiveAccountDropzone = ({field, isMobileSize, t}) => {
    return (
        <Stack
            direction="row"
            gap={1}
            boxShadow="xs"
            borderRadius="sm"
            sx={{
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                p: 1.5,
            }}
        >
            <Box sx={{color: 'text.secondary'}}>
                <FontAwesomeIcon icon={faUpload} fixedWidth />
            </Box>

            <Stack
                direction="column"
                gap={1}
            >
                <Typography
                    level="body-md"
                    fontWeight="xl"
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    {t('accountArchiving.archiveDropZoneTitle')}
                </Typography>
                <Typography
                    level="body-md"
                    sx={{
                        color: 'text.tertiary',
                        mb: 1,
                    }}
                >
                    {t('accountArchiving.archiveDropZoneText')}
                </Typography>

                <DropZone
                    name="archive_account_bank_statements"
                    isFileRemovalDisabled
                    files={field.value}
                    onDropAccepted={field.onChange}
                    onDeleteFile={() => {}}
                    buttonText={t('common:attach')}
                    setIsOpen
                    hasChips
                    fileLimit={12}
                    isMultiple
                    acceptFiles="application/pdf"
                    readableFileExtension="PDF"
                    isInfoTextVisible={isMobileSize}
                    dropZoneCustomText={(
                        <Typography
                            valevelriant="body-md"
                            sx={{
                                mt: 1,
                                color: 'text.tertiary',
                            }}
                        >
                            {t('accountArchiving.archiveDropZoneMaxFileText')}
                        </Typography>
                    )}
                    isMaxFileMessageDisabled
                />
            </Stack>
        </Stack>
    );
};

ArchiveAccountDropzone.propTypes = {
    field: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default ArchiveIntegrationModal;
