import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';

const getRequiredConsents = userId => coreApiInstance({
    method: 'GET',
    url: `/api/v2/users/${userId}/required-consents`,
}).then(result => result.data);

const giveConsents = (userId, termIds) => coreApiInstance({
    method: 'POST',
    url: `/api/v2/users/${userId}/give-consents`,
    data: {
        termIds,
    },
});

export const TermsApi = {
    getRequiredConsents,
    giveConsents,
};
