import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {AccountingActions} from './accounting.action';
import {AccountingActionTypes} from './accounting.action-type';
import {AccountingSelector} from './accounting.selector';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';

import {UserRoles} from '../../../../../utils/user-roles';
import {isMobileSafari, isSafari} from '../../../../../v1/app/app.helpers';
import {getCompanyId} from '../../../../../v1/app/utils/get-company-id';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {SseActions} from '../../../../server-side-events/store/sse.actions';
import {SSESelector} from '../../../../server-side-events/store/sse.selector';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {AccountingApi} from '../api/accounting.api';

const downloadFECFileFlow = function* (companyId, type, dateFrom, dateTo) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_FEC_FILE, true));

        const SSEEventSource = yield select(SSESelector.selectInvoiceSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectInvoicingSSE());
            yield delay(200);
        }

        const response = yield call(AccountingApi.downloadFileFEC, {
            companyId,
            type,
            dateFrom,
            dateTo,
        });

        // Put eventId in saga to wait for SSE response
        yield put(AccountingActions.updateFECFileDownloadLoader({
            eventId: response.pendingEventId,
            inProgress: true,
        }));
    } catch (e) {
        Debug.error('fec downloadFECFileFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleSSEDownloadFECFileFailed = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(AccountingSelector.createFileLoaderSelectorByEventId(message.id));

        yield put(AccountingActions.updateFECFileDownloadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        Toast.error('FECFileDownloadError');
    } catch (e) {
        Debug.error('fec handleSSEDownloadFECFileFailed', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_FEC_FILE, false));
    }
};

export const handleSSEUploadFinalFECFileFailed = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(AccountingSelector.createFileLoaderSelectorByEventId(message.id));

        yield put(AccountingActions.updateFinalFECFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        Toast.error('FECFileUploadError');
    } catch (e) {
        Debug.error('fec handleSSEUploadFinalFECFileFailed', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_FINAL_FEC_FILE, false));
    }
};

export const handleSSEDownloadFECFileNoData = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(AccountingSelector.createFileLoaderSelectorByEventId(message.id));

        yield put(AccountingActions.updateFECFileDownloadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        Toast.info(message.data?.message || 'No fec entries found');
    } catch (e) {
        Debug.error('fec handleSSEDownloadFECFileNoData', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_FEC_FILE, false));
    }
};

export const handleSSEDownloadFECFileSuccess = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(AccountingSelector.createFileLoaderSelectorByEventId(message.id));

        // We need to check if there is event for current user session so he will not download some other file
        if (event) {
            const signedUrl = message.data?.signedUrl;

            if (signedUrl) {
                if (isSafari || isMobileSafari) {
                    // TODO:HIGH: It's ugly but it works.
                    fetch(signedUrl).then(response => {
                        return response.blob();
                    }).then(blob => {
                        const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                        const filename = matchedGroups[2];

                        window.saveAs(blob, decodeURI(filename));
                    });
                } else {
                    window.open(signedUrl, '_blank');
                }
            }

            yield put(AccountingActions.updateFECFileDownloadLoader({
                eventId: event.eventId,
                inProgress: false,
            }));
        }
    } catch (e) {
        Debug.error('fec handleSSEDownloadFECFileSuccess', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_FEC_FILE, false));
    }
};

const getJournalAccountsFlow = function* (companyId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.JOURNAL_ACCOUNTS, true));

        const response = yield call(AccountingApi.getJournalAccounts, {
            companyId,
        });

        yield put(AccountingActions.storeJournalAccounts(response));
    } catch (e) {
        Debug.error('accounting getJournalAccountsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.JOURNAL_ACCOUNTS, false));
    }
};

const updateJournalAccountsFlow = function* (companyId, items) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.JOURNAL_ACCOUNTS, true));

        const response = yield call(AccountingApi.updateJournalAccounts, {
            companyId,
            items,
        });

        yield put(AccountingActions.storeJournalAccounts(response));
    } catch (e) {
        Debug.error('accounting updateJournalAccountsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.JOURNAL_ACCOUNTS, false));
    }
};

export const initialiseAnnualAccountsFlow = function* (companyId, year) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, true));

        const annualAccount = yield call(AccountingApi.initialiseAnnualAccounts, {companyId, year});
        yield put(AccountingActions.storeAnnualAccountForYear(annualAccount));
    } catch (e) {
        Debug.error('accounting initialiseAnnualAccountsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, false));
    }
};

export const getAnnualAccountsFlow = function* (companyId) {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, true));

        if (loggedInUser.additionalInfo.fetch_annual_account || loggedInUser?.role === UserRoles.ADMINISTRATOR) {
            const response = yield call(AccountingApi.getAnnualAccounts, {
                companyId,
            });

            yield put(AccountingActions.storeAnnualAccounts(response));

            yield put(AccountingActions.updateINPISubmissionDetails(response.reduce((accounts, annualAccount) => ({
                ...accounts,
                [annualAccount.id]: annualAccount.submissionInfo,
            }), {})));
        }
    } catch (e) {
        Debug.error('accounting getAnnualAccountsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, false));
    }
};

export const getAnnualAccountByIdFlow = function* (companyId, annualAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, true));

        const response = yield call(AccountingApi.getAnnualAccounts, {
            companyId,
            annualAccountId,
        });

        yield put(AccountingActions.storeAnnualAccountForYear(response));

        yield put(AccountingActions.updateINPISubmissionDetails({
            [response.id]: response.submissionInfo,
        }));
    } catch (e) {
        Debug.error('accounting getAnnualAccountByIdFlow ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, false));
    }
};

export const getAnnualAccountsForFreelancerSaga = function* () {
    try {
        const companyId = yield call(getCompanyId);
        yield call(getAnnualAccountsFlow, companyId);
    } catch (e) {
        Debug.error('accounting getAnnualAccountsForFreelancerSaga ', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const setDepositOfAccountsManualMode = function* (companyId, annualAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, true));
        const response = yield call(AccountingApi.setDepositOfAccountsManualMode, {
            companyId,
            annualAccountId,
        });

        yield put(AccountingActions.storeAnnualAccountForYear(response));
    } catch (e) {
        Debug.error('accounting setDepositOfAccountsManualMode', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, false));
    }
};

const resetDepositOfAccountsManualMode = function* (companyId, annualAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, true));
        const response = yield call(AccountingApi.resetDepositOfAccountsManualMode, {
            companyId,
            annualAccountId,
        });

        yield put(AccountingActions.storeAnnualAccountForYear(response));
    } catch (e) {
        Debug.error('accounting resetDepositOfAccountsManualMode', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, false));
    }
};

const downloadFinalFECFileFlow = function* (companyId, year, type) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_FEC_FILE, true));

        const SSEEventSource = yield select(SSESelector.selectInvoiceSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectInvoicingSSE());
            yield delay(200);
        }

        const response = yield call(AccountingApi.downloadFinalFileFEC, {
            companyId,
            year,
            type,
        });

        // Put eventId in saga to wait for SSE response
        yield put(AccountingActions.updateFECFileDownloadLoader({
            eventId: response.pendingEventId,
            inProgress: true,
        }));
    } catch (e) {
        Debug.error('fec downloadFinalFECFileFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const uploadFinalFECFileFlow = function* (companyId, year, file, annualAccountId, shouldRefreshAnnualAccounts) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_FINAL_FEC_FILE, true));

        const SSEEventSource = yield select(SSESelector.selectInvoiceSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectInvoicingSSE());
            yield delay(200);
        }

        const response = yield call(AccountingApi.uploadFinalFileFEC, {
            companyId,
            year,
            file,
            annualAccountId,
        });

        // Put eventId in saga to wait for SSE response
        yield put(AccountingActions.updateFinalFECFileUploadLoader({
            eventId: response.pendingEventId,
            inProgress: true,
            companyId,
            year,
            shouldRefreshAnnualAccounts,
            annualAccountId,
        }));
    } catch (e) {
        Debug.error('fec uploadFinalFECFileFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleSSEUploadFinalFECFileSuccess = function* (data) {
    try {
        const message = JSON.parse(data.data);
        const event = yield select(AccountingSelector.createFileLoaderSelectorByEventId(message.id));

        // We need to check if there is event for current user session so he will not download some other file
        if (event) {
            const {companyId, annualAccountId, shouldRefreshAnnualAccounts} = event;

            if (shouldRefreshAnnualAccounts) {
                yield call(getAnnualAccountByIdFlow, companyId, annualAccountId);
            } else {
                yield call(getFECDocumentsFlow, companyId);
            }

            yield put(AccountingActions.updateFinalFECFileUploadLoader({
                eventId: event.eventId,
                inProgress: false,
            }));
        }
    } catch (e) {
        Debug.error('fec handleSSEUploadFinalFECFileSuccess', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_FINAL_FEC_FILE, false));
    }
};

const deleteFinalFECFileFlow = function* (companyId, finalFecId, annualAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_FINAL_FEC_FILE, true));

        yield call(AccountingApi.deleteFinalFileFEC, {
            companyId,
            finalFecId,
        });

        if (annualAccountId) {
            // Need to refresh annual account
            yield call(getAnnualAccountByIdFlow, companyId, annualAccountId);
        } else {
            // Default
            yield call(getFECDocumentsFlow, companyId);
        }

        Toast.success('documentDeleted');
    } catch (e) {
        Debug.error('fec deleteFinalFECFileFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_FINAL_FEC_FILE, false));
    }
};

/*
 * Balance sheet
 */

const uploadBalanceSheetFlow = function* (companyId, annualAccountId, file) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BALANCE_SHEET_FILE, true));

        // TODO: Waiting for new route
        const response = yield call(AccountingApi.uploadBalanceSheet, {
            companyId,
            annualAccountId,
            file,
        });

        yield put(AccountingActions.storeAnnualAccountForYear(response));
    } catch (e) {
        Debug.error('[deposit-of-accounts] uploadBalanceSheetFlow ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BALANCE_SHEET_FILE, false));
    }
};

const generateDocumentsFlow = function* (companyId, year, annualAccountId) {
    try {
        yield put(AccountingActions.storeAllDocumentsGenerationProgress(true));
        yield call(AccountingApi.generateDocuments, {companyId, year});
        yield call(getAnnualAccountByIdFlow, companyId, annualAccountId);
    } catch (e) {
        Debug.error('[deposit-of-accounts] generateDocumentsFlow ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(AccountingActions.storeAllDocumentsGenerationProgress(false));
    }
};

const regenerateDocumentsFlow = function* (companyId, documentId, year) {
    try {
        yield put(AccountingActions.storeDocumentGenerationProgress({[documentId]: true}));
        const response = yield call(AccountingApi.regenerateDocument, {companyId, documentId});

        const annualAccounts = yield select(AccountingSelector.selectAnnualAccounts);
        const annualAccount = annualAccounts[year];
        let generatedDocuments = [...annualAccount?.generatedDocuments];

        if (generatedDocuments) {
            generatedDocuments = generatedDocuments?.map(document => {
                if (document.id === response.id) {
                    return {
                        ...document,
                        docStatus: response.docStatus,
                    };
                }

                return document;
            });

            yield put(AccountingActions.storeAnnualAccountForYear({...annualAccount, generatedDocuments}));
        }

        yield put(AccountingActions.storeDocumentGenerationProgress({[documentId]: false}));
    } catch (e) {
        Debug.error(`[deposit-of-accounts] regenerateDocumentFlow doc id ${documentId}`, 'Error: ', {e});
        Toast.error('genericError');
    }
};

const regenerateAllDocumentsFlow = function* (companyId, year) {
    const annualAccount = (yield select(AccountingSelector.selectAnnualAccounts))[year];
    const documents = annualAccount.generatedDocuments;

    try {
        yield put(AccountingActions.storeDocumentGenerationProgress(
            documents.reduce((result, doc) => ({...result, [doc.id]: true}), {}),
        ));
        const regeneratedDocuments = yield call(AccountingApi.regenerateAllDocuments, {companyId, year});

        if (annualAccount) {
            const updatedAnnualAccount = {...annualAccount};
            updatedAnnualAccount.generatedDocuments = annualAccount.generatedDocuments.map(document => {
                const regeneratedDoc = regeneratedDocuments.find(doc => doc.id === document.id);
                if (regeneratedDoc) {
                    return regeneratedDoc;
                }

                return document;
            });

            yield put(AccountingActions.storeAnnualAccountForYear(updatedAnnualAccount));
        }
    } catch (e) {
        Debug.error('[deposit-of-accounts] regenerateAllDocumentsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(AccountingActions.storeDocumentGenerationProgress(
            documents.reduce((result, doc) => ({...result, [doc.id]: false}), {}),
        ));
    }
};

export const getBalanceSheetDetailsFlow = function* (companyId, year) {
    try {
        const result = yield call(AccountingApi.getBalanceSheetDetails, {companyId, year});
        yield put(AccountingActions.storeBalanceSheetDetails(result));
    } catch (e) {
        Debug.error('[deposit-of-accounts] getBalanceSheetDetailsFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

/*
 * Deposit Of Accounts: INPI
 */
const sendFormalityToINPIFlow = function* (companyId, annualAccountId) {
    try {
        const SSEEventSource = yield select(SSESelector.selectInvoiceSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectInvoicingSSE());
            yield delay(200);
        }

        const annualAccounts = yield select(AccountingSelector.selectAnnualAccounts);
        const annualAccount = Object.values(annualAccounts).find(account => account.id === annualAccountId);
        yield put(AccountingActions.updateINPISubmissionDetails({
            [annualAccountId]: {
                ...annualAccount.submissionInfo,
                init: true,
            },
        }));

        yield call(AccountingApi.sendFormalityToINPI, {
            companyId,
            annualAccountId,
        });
    } catch (e) {
        Debug.error('[deposit-of-accounts] sendFormalityToINPIFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const retrySendFormalityToINPIFlow = function* (companyId, annualAccountId) {
    try {
        const SSEEventSource = yield select(SSESelector.selectInvoiceSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectInvoicingSSE());
            yield delay(200);
        }

        const response = yield call(AccountingApi.retrySendFormalityToINPI, {
            companyId,
            annualAccountId,
        });

        const allINPIDetails = yield select(AccountingSelector.selectINPISubmission);
        const currINPIDetails = allINPIDetails[response.pendingEventId];

        yield put(AccountingActions.updateINPISubmissionDetails({
            [response.pendingEventId]: {
                ...currINPIDetails,
                retry: true,
                error: null,
                warnings: [],
            },
        }));
    } catch (e) {
        Debug.error('[deposit-of-accounts] sendFormalityToINPIFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleFormalityINPIStatusSSEUpdate = function* (sseData) {
    try {
        const updateData = JSON.parse(sseData.data);
        const companyId = yield call(getCompanyId);

        const allINPIDetails = yield select(AccountingSelector.selectINPISubmission);
        const currINPIDetails = allINPIDetails[updateData.id];
        yield put(AccountingActions.updateINPISubmissionDetails({
            [updateData.id]: {
                ...currINPIDetails,
                ...updateData.data,

                // Clear flags
                retry: false,
            },

        }));

        // Refresh INPI data
        yield call(getAnnualAccountByIdFlow, companyId, updateData.id);
    } catch (e) {
        Debug.error('[deposit-of-accounts] handleFormalityINPIStatusSSEUpdate ', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleFormalityINPIStatusSSEError = function* (sseData) {
    try {
        const updateData = JSON.parse(sseData.data);
        const companyId = yield call(getCompanyId);

        yield put(AccountingActions.updateINPISubmissionDetails({
            [updateData.id]: {
                // Clear flags
                retry: false,
            },
        }));

        // Refresh INPI data
        yield call(getAnnualAccountByIdFlow, companyId, updateData.id);

        Toast.error('genericError');
    } catch (e) {
        Debug.error('[deposit-of-accounts] handleFormalityINPIStatusSSEUpdate ', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const getFECDocumentsFlow = function* (companyId) {
    try {
        const response = yield call(AccountingApi.getFECDocuments, {companyId});
        yield put(AccountingActions.storeFECDocuments(response));
    } catch (e) {
        Debug.error('[deposit-of-accounts] getFECDocumentsFlow  ', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const refreshINPIStatusFlow = function* (companyId, annualAccountId) {
    try {
        const response = yield call(AccountingApi.refreshINPIStatus, {companyId, annualAccountId});

        yield put(AccountingActions.storeAnnualAccountForYear(response));

        yield put(AccountingActions.updateINPISubmissionDetails({
            [response.id]: {
                ...response.submissionInfo,

                // Clear flags
                retry: false,
            },
        }));
    } catch (e) {
        Debug.error('[deposit-of-accounts] refreshINPIStatusFlow ', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const resendPaymentStepEmailFlow = function* (companyId, annualAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_PAYMENT_EMAIL, true));
        yield call(AccountingApi.resendPaymentStepEmail, {companyId, annualAccountId});
        Toast.success('paymentEmailResent');
    } catch (e) {
        Debug.error('[deposit-of-accounts] resendPaymentStepEmailFlow  ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_PAYMENT_EMAIL, false));
    }
};

export const sendBalanceSheetSplitPageDataFlow = function* (companyId, annualAccountId, balanceSheetSplitPage) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_BALANCE_SHEET_SPLIT_PAGE_DATA, true));

        const response = yield call(AccountingApi.sendBalanceSheetSplitPageData, {
            companyId,
            annualAccountId,
            balanceSheetSplitPage,
        });

        yield put(AccountingActions.storeAnnualAccountForYear(response));

        yield put(AccountingActions.updateINPISubmissionDetails({
            [response.id]: response.submissionInfo,
        }));
    } catch (e) {
        Debug.error('[deposit-of-accounts] sendBalanceSheetSplitPageDataFlow  ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_BALANCE_SHEET_SPLIT_PAGE_DATA, false));
    }
};

export const regenerateBalanceSheetDerivedDocuments = function* (companyId, annualAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.REGENERATING_BALANCE_SHEET_DERIVED_DOCUMENTS, true));

        const response = yield call(AccountingApi.regenerateBalanceSheetDerivedDocuments, {
            companyId,
            annualAccountId,
        });

        yield put(AccountingActions.storeAnnualAccountForYear(response));

        yield put(AccountingActions.updateINPISubmissionDetails({
            [response.id]: response.submissionInfo,
        }));
    } catch (e) {
        Debug.error('[deposit-of-accounts] regenerateBalanceSheetDerivedDocuments  ', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.REGENERATING_BALANCE_SHEET_DERIVED_DOCUMENTS, false));
    }
};

export const downloadFECFileWorker = function* ({payload}) {
    const {companyId, type, dateFrom, dateTo} = payload;
    yield call(downloadFECFileFlow, companyId, type, dateFrom, dateTo);
};

export const getJournalAccountsWorker = function* ({payload}) {
    const {companyId} = payload;
    yield call(getJournalAccountsFlow, companyId);
};

export const updateJournalAccountsWorker = function* ({payload}) {
    const {companyId, items} = payload;
    yield call(updateJournalAccountsFlow, companyId, items);
};

export const initialiseAnnualAccountsWorker = function* ({payload}) {
    const {companyId, year} = payload;
    yield call(initialiseAnnualAccountsFlow, companyId, year);
};

export const getAnnualAccountsWorker = function* ({payload}) {
    const {companyId} = payload;
    yield call(getAnnualAccountsFlow, companyId);
};

export const getAnnualAccountByIdWorker = function* ({payload}) {
    const {companyId, annualAccountId} = payload;
    yield call(getAnnualAccountByIdFlow, companyId, annualAccountId);
};

export const downloadFinalFECFileWorker = function* ({payload}) {
    const {companyId, year, type} = payload;
    yield call(downloadFinalFECFileFlow, companyId, year, type);
};

export const uploadFinalFECFileWorker = function* ({payload}) {
    const {companyId, year, file, annualAccountId, shouldRefreshAnnualAccounts} = payload;
    yield call(uploadFinalFECFileFlow, companyId, year, file, annualAccountId, shouldRefreshAnnualAccounts);
};

export const deleteFinalFECFileWorker = function* ({payload}) {
    const {companyId, finalFecId, annualAccountId} = payload;
    yield call(deleteFinalFECFileFlow, companyId, finalFecId, annualAccountId);
};

export const toggleDepositOfAccountsManualModeWorker = function* ({payload}) {
    const {companyId, annualAccountId, manualMode} = payload;
    if (manualMode) {
        yield call(setDepositOfAccountsManualMode, companyId, annualAccountId);
    } else {
        yield call(resetDepositOfAccountsManualMode, companyId, annualAccountId);
    }
};

export const uploadBalanceSheetWorker = function* ({payload}) {
    const {companyId, annualAccountsId, file} = payload;
    yield call(uploadBalanceSheetFlow, companyId, annualAccountsId, file);
};

export const generateDocumentsWorker = function* ({payload}) {
    const {companyId, year, annualAccountId} = payload;
    yield call(generateDocumentsFlow, companyId, year, annualAccountId);
};

export const regenerateDocumentWorker = function* ({payload}) {
    const {companyId, documentId, year} = payload;
    yield call(regenerateDocumentsFlow, companyId, documentId, year);
};

export const regenerateAllDocumentsWorker = function* ({payload}) {
    const {companyId, year} = payload;
    yield call(regenerateAllDocumentsFlow, companyId, year);
};

export const getBalanceSheetDetailsWorker = function* ({payload}) {
    const {companyId, year} = payload;
    yield call(getBalanceSheetDetailsFlow, companyId, year);
};

export const sendFormalityToINPIWorker = function* ({payload}) {
    const {companyId, annualAccountId, retry} = payload;
    if (retry) {
        yield call(retrySendFormalityToINPIFlow, companyId, annualAccountId);
    } else {
        yield call(sendFormalityToINPIFlow, companyId, annualAccountId);
    }
};

export const getFECDocumentsWorker = function* ({payload}) {
    const {companyId} = payload;
    yield call(getFECDocumentsFlow, companyId);
};

export const refreshINPIStatusWorker = function* ({payload}) {
    const {companyId, annualAccountId} = payload;
    yield call(refreshINPIStatusFlow, companyId, annualAccountId);
};

export const resendPaymentStepEmailWorker = function* ({payload}) {
    const {companyId, annualAccountId} = payload;
    yield call(resendPaymentStepEmailFlow, companyId, annualAccountId);
};

export const sendBalanceSheetSplitPageDataWorker = function* ({payload}) {
    const {companyId, annualAccountId, balanceSheetSplitPage} = payload;
    yield call(sendBalanceSheetSplitPageDataFlow, companyId, annualAccountId, balanceSheetSplitPage);
};

export const regenerateBalanceSheetDerivedDocumentsWorker = function* ({payload}) {
    const {companyId, annualAccountId} = payload;
    yield call(regenerateBalanceSheetDerivedDocuments, companyId, annualAccountId);
};

export const watchAccountingSaga = function* () {
    yield all([
        takeEvery(AccountingActionTypes.DOWNLOAD_FEC_FILE, downloadFECFileWorker),
        takeEvery(AccountingActionTypes.GET_JOURNAL_ACCOUNTS, getJournalAccountsWorker),
        takeEvery(AccountingActionTypes.UPDATE_JOURNAL_ACCOUNTS, updateJournalAccountsWorker),
        takeEvery(AccountingActionTypes.INITIALISE_ANNUAL_ACCOUNTS, initialiseAnnualAccountsWorker),
        takeEvery(AccountingActionTypes.GET_ANNUAL_ACCOUNTS, getAnnualAccountsWorker),
        takeEvery(AccountingActionTypes.GET_ANNUAL_ACCOUNT_BY_ID, getAnnualAccountByIdWorker),
        takeEvery(AccountingActionTypes.DOWNLOAD_FINAL_FEC_FILE, downloadFinalFECFileWorker),
        takeEvery(AccountingActionTypes.UPLOAD_FINAL_FEC_FILE, uploadFinalFECFileWorker),
        takeEvery(AccountingActionTypes.TOGGLE_DEPOSIT_OF_ACCOUNTS_MODE, toggleDepositOfAccountsManualModeWorker),
        takeEvery(AccountingActionTypes.DELETE_FINAL_FEC_FILE, deleteFinalFECFileWorker),
        takeEvery(AccountingActionTypes.UPLOAD_BALANCE_SHEET, uploadBalanceSheetWorker),
        takeEvery(AccountingActionTypes.GENERATE_DOA_DOCUMENTS, generateDocumentsWorker),
        takeEvery(AccountingActionTypes.REGENERATE_SINGLE_DOCUMENT, regenerateDocumentWorker),
        takeEvery(AccountingActionTypes.REGENERATE_ALL_DOCUMENTS, regenerateAllDocumentsWorker),
        takeEvery(AccountingActionTypes.GET_BALANCE_SHEET_DETAILS, getBalanceSheetDetailsWorker),
        takeEvery(AccountingActionTypes.SEND_FORMALITIES_TO_INPI, sendFormalityToINPIWorker),
        takeEvery(AccountingActionTypes.GET_FEC_DOCUMENTS, getFECDocumentsWorker),
        takeEvery(AccountingActionTypes.REFRESH_INPI_STATUS, refreshINPIStatusWorker),
        takeEvery(AccountingActionTypes.RESEND_PAYMENT_STEP_EMAIL, resendPaymentStepEmailWorker),
        takeEvery(AccountingActionTypes.SEND_BALANCE_SHEET_SPLIT_PAGE_DATA, sendBalanceSheetSplitPageDataWorker),
        takeEvery(
            AccountingActionTypes.REGENERATE_BALANCE_SHEET_DERIVED_DOCUMENTS,
            regenerateBalanceSheetDerivedDocumentsWorker,
        ),
    ]);
};
