import {
    faBank,
    faLayerGroup,
    faTrash,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import {ReactComponent as HiText} from '../../../../../../assets/svg/logo/hirounded.svg';
import {BANK_TYPES} from '../../../bridge-api/utils/constants';

export const AccountsMenuItemIcon = ({
    value,
    isSelected,
    isHiway,
    hasError,
    isInHandler,
}) => {
    if (isHiway && !hasError) {
        const color = isInHandler
            ? '#E7604D' // primary.solidBg
            : isSelected
                ? '#F6EBDF' // background.level3
                : '#502131'; // neutral.softColor

        return (
            <HiText width="20px" height="20px" fill={color} />
        );
    }

    const icon = hasError
        ? faTriangleExclamation
        : value === BANK_TYPES.archived
            ? faTrash
            : value === BANK_TYPES.combined
                ? faLayerGroup
                : faBank;

    return (
        <FontAwesomeIcon icon={icon} />
    );
};

AccountsMenuItemIcon.propTypes = {
    value: PropTypes.string,
    isSelected: PropTypes.bool,
    isHiway: PropTypes.bool,
    hasError: PropTypes.bool,
    isInHandler: PropTypes.bool,
};


AccountsMenuItemIcon.defaultProps = {
    value: null,
    isSelected: false,
    hasError: false,
    isHiway: false,
    isInHandler: false,
};
