import {
    faHourglassStart,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';
import {currencyToSign, getNumberSegments, numberWithSpaces} from '../../../../../../../v1/app/app.helpers';

export const AccountBalanceTableDataRowAmount = ({
    t,
    isDebit,
    isRejectedOrCancelled,
    isUpcoming,
    amount,
    currency,
}) => {
    const numberSegments = getNumberSegments(numberWithSpaces(amount));

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
        >
            {isUpcoming && (
                <ConditionalTooltip
                    tooltip={t('accountBalance.upcoming')}
                    isV3
                    sx={{maxWidth: '300px'}}
                >
                    <Box
                        fontSize="md"
                        sx={{
                            color: 'linen.900',
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faHourglassStart}
                            fixedWidth
                        />
                    </Box>
                </ConditionalTooltip>
            )}
            <Box sx={{
                color: isUpcoming
                    ? 'linen.900'
                    : isRejectedOrCancelled
                        ? 'text.tertiary'
                        : !isDebit
                            ? 'text.success'
                            : 'text.secondary',
                textAlign: 'right',
                whiteSpace: 'nowrap',
            }}
            >
                <Typography
                    component="span"
                    level="title-lg"
                    sx={{
                        color: 'inherit',
                        display: 'inline',
                    }}
                >
                    {parseFloat(amount) !== 0 && (isDebit ? '-' : '+')} {numberSegments?.integer},
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 'smd',
                        fontSize: 'sm',
                        fontStyle: 'italic',
                        color: 'inherit',
                        display: 'inline',
                    }}
                >
                    {numberSegments?.decimal} {currencyToSign(currency)}
                </Typography>
            </Box>
        </Stack>
    );
};

AccountBalanceTableDataRowAmount.propTypes = {
    t: PropTypes.func.isRequired,
    isDebit: PropTypes.bool.isRequired,
    isRejectedOrCancelled: PropTypes.bool.isRequired,
    isUpcoming: PropTypes.bool.isRequired,
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
};
