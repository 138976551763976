import {useEffect} from 'react';
import {getConfig} from '../../v1/config';

const config = getConfig();

const env = config.environment;
const hotjarId = config.hotjarId;


const Hotjar = () => {
    /* Load Hotjar in staging or production */
    useEffect(() => {
        if (env === 'production' || env === 'staging') {
            const script = document.createElement('script');
            script.text = `(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:${hotjarId},hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
            document.head.appendChild(script);
        }
    }, []);


    return null;
};

export {
    Hotjar,
};
