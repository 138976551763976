import axios from 'axios';
import {
    fromCompanyDocumentDTO,
    fromCompanyInformationDTO,
    fromNonConvictionDeclarationDTO,
    fromPersonalInformationDTO,
    fromSingleCompanyDTO,
    toCompanyDocumentDTO,
    toCompanyInformationDTO, toCompanyInformationIbanBicDTO,
    toDocumentsDTO,
    toNonConvictionDeclarationDTO,
    toPersonalInformationDTO,
    transformCompanies,
    transformCompanyDocuments,
    transformPersonalCompanyDocuments,
    transformTrainingCompanyDocuments,
} from './company.dto';
import {coreApiInstance} from '../../../../../lib/api-providers/core-api-instance';
import {invoiceApiInstance} from '../../../../../lib/invoice-api-instance';
import {DOCUMENT_CATEGORIES} from '../../../../config/constants/documentConstants';
import axiosInstance, {safeTransformData} from '../../axios';

export const getCompaniesRequest = params => {
    return axiosInstance({
        method: 'GET',
        url: '/v1/companies',
        params,
    }).then(response => {
        const companies = safeTransformData(response?.data, transformCompanies);

        return {
            companies: companies,
            hiwayTotal: response?.data?.totalHiwayProRecords ?? 0,
            total: response?.data?.totalRecords ?? 0,
        };
    });
};

export const getCompanyDocumentsRequest = (freelancerId, companyId, documentContext) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents`,
        params: {
            doc_context: documentContext,
        },
    }).then(response => safeTransformData(response, transformCompanyDocuments, {freelancerId}));
};

export const getCompanyDocumentsByTypeRequest = (freelancerId, companyId, docType) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents`,
        params: {
            doc_type: docType,
        },
    }).then(response => (response?.data || []).map(fromCompanyDocumentDTO));
};

export const getHiwayDocuments = () => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/hiway/documents`,
    }).then(response => safeTransformData(response));
};

export const generateCompanyDocumentsRequest = (freelancerId, companyId, stage = 1) => {
    return axiosInstance({
        method: 'POST',
        params: {
            stage,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/generate-documents`,
    }).then(response => safeTransformData(response));
};

/**
 * Gets saved personal information data for a given freelancer and a given company
 * @param {String} freelancerId UUID
 * @param {String} companyId UUID
 * @returns {Promise} Null if the data does not exist, or transformed data
 */
export const getPersonalInformationDataRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/personal-info`,
    }).then(response => safeTransformData(response, fromPersonalInformationDTO));
};

/**
 * Gets saved non-conviction declaration data for a given freelancer and a given company
 * @param {String} freelancerId UUID
 * @param {String} companyId UUID
 * @returns {Promise} Null if the data does not exist, or transformed data
 */
export const getNonConvictionDeclarationRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/non-conviction`,
    }).then(response => safeTransformData(response, fromNonConvictionDeclarationDTO));
};

/**
 * Gets saved company information data for a given freelancer and a given company
 * @param {String} freelancerId UUID
 * @param {String} companyId UUID
 * @returns {Promise} Null if the data does not exist, or transformed data
 */
export const getCompanyInformationDataRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/company-info`,
    }).then(response => safeTransformData(response, fromCompanyInformationDTO));
};

export const savePersonalInformationDataRequest = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/personal-info`,
        data,
        transformRequest: data => JSON.stringify(toPersonalInformationDTO(data)),
    });
};


export const saveNonConvictionDeclarationDataRequest = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/non-conviction`,
        data,
        transformRequest: data => JSON.stringify(toNonConvictionDeclarationDTO(data)),
    });
};

export const saveCompanyInformationDataRequest = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/company-info`,
        data,
        transformRequest: data => JSON.stringify(toCompanyInformationDTO(data)),
    });
};

export const saveCompanyIbanBicInformationDataRequest = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/company-info`,
        data,
        transformRequest: data => JSON.stringify(toCompanyInformationIbanBicDTO(data)),
    });
};

export const saveRegistrationBankInfoAndKbisRequest = (freelancerId, companyId, data) => {
    if (!data.file[0]) {
        return axiosInstance({
            method: 'PUT',
            url: `/v1/freelancers/${freelancerId}/companies/${companyId}/registration-bank-info-and-kbis`,
            data: {
                iban: data.iban,
                bic: data.bic,
            },
        });
    }

    const formData = new FormData();
    const {iban, bic, shouldSendFile} = data;
    if (bic) {
        formData.append('bic', data.bic);
    }
    if (iban) {
        formData.append('iban', data.iban.replaceAll(' ', ''));
    }
    if (shouldSendFile === true) {
        formData.append('file', data.file[0]);
    }
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/registration-bank-info-and-kbis`,
        data: formData,
    });
};

export const putDataInputFinishedForFreelancerWithExistingCompany = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/data-input-finished`,
    }).then(response => response.data);
};

export const getDocumentUrls = (freelancerId, companyId, documents) => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/personal-documents-upload-urls`,
        data: {
            documents,
        },
    }).then(response => safeTransformData(response));
};

export const saveDocumentsRequest = (freelancerId, companyId, documents) => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/personal-documents-upload-ack`,
        data: {
            documents,
        },
    });
};

export const saveDocumentFlagsRequest = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/personal-documents`,
        data,
        transformRequest: data => JSON.stringify(toDocumentsDTO(data)),
    });
};

export const uploadDocumentRequest = (url, file) => {
    const options = {
        headers: {
            'Content-Type': file.type,
        },
    };

    return axios.put(url, file, options);
};

export const getCompanyRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

export const approveCompanyRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/approve`,
    }).then(response => safeTransformData(response));
};

export const requestEditsRequest = (freelancerId, companyId, message) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/request-edits`,
        data: {
            message,
        },
    }).then(response => safeTransformData(response));
};

export const getPersonalCompanyDocumentsRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents`,
        params: {
            doc_category: [
                DOCUMENT_CATEGORIES.PERSONAL,
            ],
        },
    }).then(response => safeTransformData(response, transformPersonalCompanyDocuments));
};

export const getCompanyDocumentRequest = (freelancerId, companyId, documentId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}`,
    }).then(response => safeTransformData(response, fromCompanyDocumentDTO, {freelancerId}));
};

export const updateCompanyDocumentRequest = (freelancerId, companyId, documentId, fileName, subType) => {
    const data = {};

    if (fileName) {
        data.fileName = fileName;
    }

    if (subType) {
        data.subType = subType;
    }

    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}`,
        data,
        transformRequest: data => JSON.stringify(toCompanyDocumentDTO(data)),
    }).then(response => safeTransformData(response));
};

export const uploadCompanyDocumentRequest = (
    companyId,
    file,
    type,
    category,
    id,
    subType,
    year = undefined,
) => {
    const formData = new FormData();

    formData.append('file', file);

    formData.append('documentType', type);
    formData.append('documentCategory', category);

    if (id) {
        formData.append('documentId', id);
    }

    if (year) {
        formData.append('year', year);
    }

    if (subType) {
        formData.append('documentSubType', subType);
    }

    return coreApiInstance({
        method: 'POST',
        url: `/api/v2/companies/${companyId}/documents/upload`,
        header: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).then(response => safeTransformData(response));
};

export const uploadCompanyBalanceSheetRequest = ({
    companyId,
    file,
    type,
    category,
    year,
    id,
    isReplace = false,
}) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('docType', type);
    formData.append('docCategory', category);
    if (isReplace) {
        formData.append('documentId', id);
    } else {
        formData.append('year', year);
    }

    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/balance-sheet-document-base-upload`,
        header: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).then(response => safeTransformData(response));
};

export const getSignedUrlForDocumentRequest = (freelancerId, companyId, documentId, isDownload = false) => {
    return axiosInstance({
        method: 'GET',
        params: {
            content_disposition: isDownload ? 'attachment' : 'inline',
            preview: isDownload ? undefined : true,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/sign-url-get-document`,
    }).then(response => safeTransformData(response));
};

export const getSignedUploadUrlForDocumentRequest = (freelancerId, companyId, documentId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/signed-url-put-document`,
    }).then(response => safeTransformData(response));
};

export const deleteDocumentRequest = (freelancerId, companyId, documentId) => {
    return axiosInstance({
        method: 'DELETE',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}`,
    }).then(response => safeTransformData(response));
};

export const getEnterpriseInformationRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/enterprise-info`,
    }).then(response => response?.data);
};

// TODO:HIGH Fix data transformation.
export const updateEnterpriseInformationRequest = (freelancerId, companyId, data, propagateAddressChange) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/enterprise-info`,
        data: {
            company_name: data.companyName,
            legal_status: data.legalStatus,
            // headquarters_address: data.headquartersAddress,
            headquarters_street: data.headquartersStreet,
            headquarters_street_number: data.headquartersStreetNumber ?? '',
            headquarters_zip: data.headquartersZip ?? '',
            headquarters_city: data.headquartersCity ?? '',
            headquarters_country: data.headquartersCountry,
            activity_start_date: data.activityStartDate,
            siren: data.siren,
            siret: data.siret,
            ape_naf_code: data.apeNafCode,
            vat: data.vat,
            sie: data.sie,
            tva_frequency_payment: data.tvaFrequencyPayment,
            date_of_hire: data.dateOfHire,
            tax_system: data.taxSystem,
            tax_collector: data.taxCollector,
            propagate_address_change: propagateAddressChange,
        },
    }).then(response => safeTransformData(response));
};

export const registrationPerformedRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/registration-performed`,
    }).then(response => safeTransformData(response));
};

export const getTrainingCompanyDocumentsRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents`,
        params: {
            doc_category: [
                DOCUMENT_CATEGORIES.TRAINING,
            ],
        },
    }).then(response => safeTransformData(response, transformTrainingCompanyDocuments, {}));
};

export const signDocumentRequest = (freelancerId, companyId, documentId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/sign`,
    }).then(response => safeTransformData(response));
};
