import {
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, RadioGroup, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AlertInfo} from '../../../../../components/joy-ui/alerts/AlertInfo';
import {AccountItem} from '../../../../../components/joy-ui/forms/AccountItem';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {push, selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {ACCOUNT_STATUSES} from '../../../../company-profile/modules/accounting/utils/constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {BankSelector} from '../../../store/bank.selector';
import {AccountsMenuItemIcon} from '../../account-balance/components/transaction-list/AccountsMenuItemIcon';
import {TransactionsActions} from '../../account-balance/store/transactions.action';
import {BridgeActions} from '../store/bridge-api.action';
import {getIntegrationInfo} from '../utils/bridge-util';
import {BANK_TYPES} from '../utils/constants';

const modalKey = ModalsKeys.PICK_DEFAULT_BANK_MODAL;
const PickDefaultBankModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SET_DEFAULT_BANK),
    );

    const integrations = useSelector(BankSelector.selectIntegrations);
    const location = useSelector(selectRouterLocation);

    const [value, setValue] = React.useState();
    const chosenValue = value ? integrations.find(integration => integration?.id === value) : undefined;

    const bankAccountHolder = chosenValue?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
    const account = bankAccountHolder?.bankAccounts?.[0];

    const handleChange = event => {
        setValue(event.target.value);
    };

    const onSubmit = () => {
        // If on transaction list get transactions
        if (location?.pathname === RoutePaths.BANK_TRANSACTION_LIST) {
            dispatch(TransactionsActions.getCombinedTransactionList({}));
        } else {
            dispatch(push(RoutePaths.BANK_TRANSACTION_LIST));
        }

        dispatch(BridgeActions.setDefaultBank(
            {
                id: account?.id,
                bankAccountHolderId: bankAccountHolder?.id,
            },
        ));
    };

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '640px'}}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('bridgeAPI.pickDefaultBankModal.title')}
                </Typography>
            )}
            buttons={() => (
                <Button
                    disabled={!value}
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faCheck} />
                    )}
                    loading={isLoading}
                    loadingPosition="start"
                    color="success"
                    size="lg"
                    variant="soft"
                    onClick={onSubmit}
                >
                    {t('bridgeAPI.pickDefaultBankModal.confirm')}
                </Button>
            )}
            hasShowClose={false}
        >
            <>
                <Typography level="body-lg" sx={{mb: 2}}>
                    {t('bridgeAPI.pickDefaultBankModal.text')}
                </Typography>

                <AlertInfo sx={{mb: 2}}>
                    {t('bridgeAPI.pickDefaultBankModal.alertPart1')}
                    <ul style={{paddingLeft: '16px'}}>
                        <li>
                            {t('bridgeAPI.pickDefaultBankModal.alertList1')}
                        </li>
                        <li>
                            {t('bridgeAPI.pickDefaultBankModal.alertList2')}
                        </li>
                    </ul>
                    {t('bridgeAPI.pickDefaultBankModal.alertPart2')}
                </AlertInfo>

                <RadioGroup
                    defaultValue={value ? chosenValue?.id : ''}
                    name="account-integration"
                    onChange={handleChange}
                    sx={{
                        gap: {desktop: 3, mobile: 2},
                        m: 0,
                    }}
                >
                    {integrations.map(integration => {
                        const {account: chosenAccount} = getIntegrationInfo(integration);

                        if (!chosenAccount?.id || chosenAccount?.status !== ACCOUNT_STATUSES.ACTIVE) {
                            return null;
                        }

                        const isHiway = integration?.type === BANK_TYPES.hiway;

                        return (
                            <AccountItem
                                key={integration.id}
                                value={integration.id}
                                name={chosenAccount?.name}
                                nameIcon={(
                                    <AccountsMenuItemIcon isHiway={isHiway} />
                                )}
                                accountNumber={t('bridgeAPI.pickDefaultBankModal.iban', {
                                    iban: chosenAccount?.iban,
                                })}
                                balance={chosenAccount?.availableBalance}
                                isSelected={value === integration.id}
                                isDefault={chosenAccount?.isDefault}
                                defaultBadgeTitle={t('bridgeAPI.pickDefaultBankModal.default')}
                            />
                        );
                    })}
                </RadioGroup>
            </>
        </ModalWrapper>
    );
};

export default PickDefaultBankModal;
