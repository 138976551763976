import moment from 'moment';
import momentTz from 'moment-timezone';
import {BE_DATE_FORMAT, DEFAULT_TIME_ZONE} from './constants';

export const CURRENT_YEAR = new Date().getFullYear();
export const PAST_YEAR = CURRENT_YEAR - 1;

export const isDDMMYYYYFormat = input => {
    const reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    return !!input?.match(reg);
};

export const dateComparator = (dateFieldName, order = 'desc') => (a, b) => {
    const dateA = new Date(a[dateFieldName]);
    const dateB = new Date(b[dateFieldName]);

    if (order === 'asc') {
        return dateA < dateB ? -1 : (
            dateA > dateB ? 1 : 0
        );
    }

    return dateA > dateB ? -1 : (
        dateA < dateB ? 1 : 0
    );
};

export const getWorkingDaysInRange = (startDate, endDate, filter = _ => true) => {
    const workingDays = [];

    const currDate = startDate;
    while (currDate <= endDate) {
        if (currDate.isoWeekday() >= 1 && currDate.isoWeekday() <= 5 && filter(currDate)) {
            workingDays.push(currDate.clone());
        }

        currDate.add(1, 'day');
    }

    return workingDays;
};

export const monthsLeftInYear = dateDMY => {
    const targetDate = moment(dateDMY, BE_DATE_FORMAT);
    const currentYear = moment().year();

    // If the date is from a previous year, return 12
    if (targetDate.year() < currentYear) {
        return 12;
    }

    // Calculate months between target date and end of the year
    const endOfYear = moment().endOf('year');
    return endOfYear.diff(targetDate, 'months') + 1;
};

export const getCurrentDateTz = () => momentTz.tz(DEFAULT_TIME_ZONE);
