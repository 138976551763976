import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CategoriesSearch from './CategoriesSearch';
import PopularCategory from './PopularCategory';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionSide} from '../../../../util/constants';
import {SECTION_STATUS} from '../../utils/constants';
import ViewAllCategoriesButton from '../common/ViewAllCategoriesButton';
import CategorisationSection from '../header/CategorisationSection';

const PopularCategories = ({
    setIsShowAllCategories,
    onSelectCategory,
    mostUsedCategoryList,
    isRejectedOrCancelled,
    side,
}) => {
    const {t} = useTranslation('bank');

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    let categoriesDisplayedCount = 0;
    return (
        <CategorisationSection
            title={side === TransactionSide.CREDIT
                ? t(`categorisation.section.creditCategory`)
                : t(`categorisation.section.category`)
             }
            status={isRejectedOrCancelled ? SECTION_STATUS.NO_INFORMATION_EXPECTED : SECTION_STATUS.REQUIRED}
            isActive={!isRejectedOrCancelled}
            isFocused={!isRejectedOrCancelled}
        >
            <CategoriesSearch
                setIsShowAllCategories={setIsShowAllCategories}
            />

            <Box>
                <Typography
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(16),
                        letterSpacing: '0.1px',
                        fontWeight: 500,
                        color: 'text_v2.secondary',
                        mb: 1,
                    }}
                >
                    {t('categorisation.popular')}
                </Typography>

                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'stretch',
                    justifyContent: 'space-between',
                    width: isMobileSize ? '100%' : 'calc(600px - 64px)',
                    overflowX: 'auto',
                }}
                >
                    {side === TransactionSide.DEBIT && mostUsedCategoryList && (
                        <>
                            {mostUsedCategoryList?.categoriesList.map(categoryParent => {
                                return (
                                    <>
                                        {categoryParent?.mccGroupItems.map(category => {
                                            if (categoriesDisplayedCount >= 3) {
                                                return null;
                                            }
                                            categoriesDisplayedCount += 1;

                                            const categoryKey = mostUsedCategoryList
                                                .categoriesObject?.[category.mccKey]?.faIcon.icon;

                                            return (
                                                <PopularCategory
                                                    key={categoryKey}
                                                    faIcon={category?.faIcon}
                                                    title={category?.[language]?.categoryName}
                                                    onClick={() => {
                                                        onSelectCategory(category);
                                                    }}
                                                />
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </>
                    )}
                    {side === TransactionSide.CREDIT && mostUsedCategoryList && (
                        <>
                            {mostUsedCategoryList?.sourcesList.map(category => {
                                const revenue = category?.sourceOfRevenueGroupItems?.[0];

                                const faIcon = mostUsedCategoryList.sourcesObject?.[revenue.sourceOfRevenue]?.faIcon;
                                const categoryKey = faIcon.icon;

                                return (
                                    <PopularCategory
                                        key={categoryKey}
                                        faIcon={faIcon}
                                        title={revenue?.[language]?.categoryName}
                                        onClick={() => {
                                            onSelectCategory(revenue?.sourceOfRevenue);
                                        }}
                                        numberOfCategories={mostUsedCategoryList?.sourcesList?.length}
                                    />
                                );
                            })}
                        </>
                    )}
                </Box>
            </Box>

            <ViewAllCategoriesButton setIsShowAllCategories={setIsShowAllCategories} />
        </CategorisationSection>
    );
};

PopularCategories.propTypes = {
    setIsShowAllCategories: PropTypes.func.isRequired,
    onSelectCategory: PropTypes.func.isRequired,
    mostUsedCategoryList: PropTypes.array,
    side: PropTypes.string.isRequired,
    isRejectedOrCancelled: PropTypes.bool.isRequired,
};

PopularCategories.defaultProps = {
    mostUsedCategoryList: [],
};

export default PopularCategories;
