import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {BridgeActionTypes} from './brdige-api.action-types';
import {BridgeActions} from './bridge-api.action';
import {push, selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {USER_ROLES, isUserCare} from '../../../../../utils/user-roles';
import {USER_STATUSES} from '../../../../../v1/app/user/user.constants';
import {FreelancerSelectors} from '../../../../freelancer';
import {CapitalDepositStatus} from '../../../../freelancer/modules/capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../../../freelancer/modules/companies';
import {OnBoardingLaunchedStatus} from '../../../../freelancer/modules/onboarding/utils/constants';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {UiActions} from '../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {BankApi} from '../../../api/bank.api';
import {BankActions} from '../../../store/bank.action';
// eslint-disable-next-line import/no-cycle
import {getBankIntegrationsFlow} from '../../../store/bank.loader.saga';
import {BankSelector} from '../../../store/bank.selector';
import {BridgeApi} from '../api/brdige-api.api';
import {getIntegrationInfo} from '../utils/bridge-util';
import {BANK_TYPES, BankConnectionModalType, BankIntegrationStatus} from '../utils/constants';

const connectToBridgeFlow = function* () {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const hasBridgeAccess = loggedInUser.hasBridgeAccess;

        if (!hasBridgeAccess || loggedInUser?.role === USER_ROLES.ADMINISTRATOR) {
            return;
        }

        const result = yield call(BridgeApi.getBridgeAuth);

        if (!result?.authUri) {
            Toast.error('genericError');

            return;
        }

        if (result.authUri) {
            window.location = result.authUri;
        }
    } catch (e) {
        Debug.error('bridge', 'Error: ', {e});

        Toast.error('genericError');
    }
};

const checkBridgeConnectionFlow = function* () {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const hasBankAccess = loggedInUser.hasBankAccess;
        const hasBridgeAccess = loggedInUser.hasBridgeAccess;

        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

        const location = yield select(selectRouterLocation);

        if (
            !hasBridgeAccess
            || !hasBankAccess
            || loggedInUser?.role === USER_ROLES.ADMINISTRATOR
            || loggedInUser?.status !== USER_STATUSES.ACTIVE
        ) {
            return;
        }

        let integrations = yield select(BankSelector.selectIntegrations);

        if (!integrations || integrations.length === 0) {
            integrations = yield call(getBankIntegrationsFlow);
        }

        if (isUserCare(loggedInUser) && location.pathname === RoutePaths.DASHBOARD) {
            return;
        }

        if (
            integrations?.length === 0
            && loggedInUser?.onboardingStatus === OnBoardingLaunchedStatus.LAUNCHED
        ) {
            const isNoAccountModalNeeded = location?.pathname !== RoutePaths.BANK_TRANSACTION_LIST;
            yield put(UiActions.setActiveModal(ModalsKeys.NO_ACCOUNT_MODAL, isNoAccountModalNeeded));
            return;
        }

        let hasSingleAccount = false;
        let singleIntegration;
        let hasMultipleAccounts = false;
        let integrationId = null;
        let numberForAutoOnboarding = 0;

        integrations.forEach(integration => {
            if (
                integration?.integrationAccounts
                && integration?.bankAccountHolders?.length === 0
                && integration?.status !== BankIntegrationStatus.ERROR
            ) {
                integrationId = integration?.id;
                if (integration.integrationAccounts?.length === 1) {
                    hasSingleAccount = true;
                    singleIntegration = integration.integrationAccounts[0];
                    numberForAutoOnboarding += 1;
                } else if (integration.integrationAccounts?.length > 1) {
                    hasMultipleAccounts = true;
                }
            }
        });

        if (hasMultipleAccounts) {
            // This modal should be shown on transaction list page
            if (location?.pathname !== RoutePaths.BANK_TRANSACTION_LIST) {
                yield put(push(RoutePaths.BANK_TRANSACTION_LIST));
                return;
            }
            // If there are multiple integration accounts ask user which he wants
            yield put(UiActions.setActiveModal(ModalsKeys.PICK_INTEGRATION_ACCOUNT_MODAL, true));
            return;
        } else if (hasSingleAccount && company?.id) {
            // If there is only one integration account run onboarding for it
            const onboarded = yield call(BankApi.onboardCompany, integrationId, company.id, singleIntegration?.iban);

            if (onboarded) {
                if (numberForAutoOnboarding > 1) {
                    // If there are multiple integrations for onboarding
                    // (which probably should not happen)
                    // Call this function again
                    yield delay(1000);
                    yield call(checkBridgeConnectionFlow);
                    return;
                }

                const bankAccountHolder = singleIntegration?.bankAccountHolders.find(
                    holder => holder.type === 'COMPANY',
                );
                const bankAccount = bankAccountHolder?.bankAccounts?.[0];

                // Set is as default if it is not
                if (!bankAccount?.isDefault) {
                    yield call(BridgeApi.setDefaultBank, {
                        id: singleIntegration?.id,
                        bankAccountHolderId: bankAccountHolder?.id,
                    });
                }

                // Show modal to ask user if he wants to connect to to another integration
                yield put(UiActions.setModalData(ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL, {integrationId}));
                yield put(UiActions.setActiveModal(ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL, true));
                return;
            }
        }

        // If only hiway without flag is used and hiway has no capital deposit show modal
        if (integrations?.length === 1 && !isUserCare(loggedInUser)) {
            // Get hiway integration
            const hiwayIntegration = integrations.find(integration => integration.type === BANK_TYPES.hiway);
            if (hiwayIntegration && !loggedInUser?.additionalInfo?.chosen_bank_connection_modal_type) {
                // Get company bankAccountHolder
                const bankAccountHolder = hiwayIntegration?.bankAccountHolders.find(
                    holder => holder?.type === 'COMPANY',
                );

                const capitalDeposit = bankAccountHolder?.integrationData?.capitalDeposit;

                if (!capitalDeposit || capitalDeposit?.status !== CapitalDepositStatus.COMPLETED) {
                    yield put(
                        UiActions.setModalData(
                            ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL,
                            {integrationId: integrations?.[0]?.id},
                        ),
                    );
                    yield put(UiActions.setActiveModal(ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL, true));
                    return;
                }
            }
        }

        // Check if there are default banks
        // If there are none and none of the above modals should be displayed
        // Either make only one by default or show modal for user to chose
        if (integrations?.length === 0) {
            return;
        }

        let integrationsWithAccount = 0;
        const hasDefault = integrations.some(integration => {
            const {account: chosenAccount} = getIntegrationInfo(integration);

            if (chosenAccount?.id) {
                integrationsWithAccount += 1;
            }

            return chosenAccount?.isDefault;
        });

        if (!hasDefault) {
            if (integrationsWithAccount === 1) {
                const {account, bankAccountHolder} = getIntegrationInfo(integrations[0]);

                yield put(BridgeActions.setDefaultBank(
                    {
                        id: account?.id,
                        bankAccountHolderId: bankAccountHolder?.id,
                    },
                ));
            } else if (integrationsWithAccount > 1) {
                yield put(UiActions.setActiveModal(ModalsKeys.PICK_DEFAULT_BANK_MODAL, true));
            }
        }
    } catch (e) {
        Debug.error('bridge-api', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const onboardChosenAccountFlow = function* ({
    integrationId,
    iban,
}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, true));

        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

        const onboarded = yield call(BankApi.onboardCompany, integrationId, company.id, iban);

        // Get latest integrations
        const integrations = yield call(getBankIntegrationsFlow);

        // Store bank integration data
        yield put(BankActions.storeActiveIntegrationData(integrations));

        yield put(UiActions.setActiveModal(ModalsKeys.PICK_INTEGRATION_ACCOUNT_MODAL, false));

        if (onboarded) {
            // Show modal to ask user if he wants to connect to to another one
            yield put(UiActions.setModalData(ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL, {integrationId}));
            yield put(UiActions.setActiveModal(ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL, true));
        }

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
    } catch (e) {
        Debug.error('bridge-api', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
    }
};

const fixBridgeAccountFlow = function* (integration) {
    try {
        let result;

        if (integration?.status === BankIntegrationStatus.COMPANY_VALIDATION_FORM_REQUIRED) {
            result = yield call(BridgeApi.fixValidationRequired, integration.id);
        }

        if (integration?.status === BankIntegrationStatus.MULTI_FACTOR_AUTHENTICATION_REQUIRED) {
            result = yield call(BridgeApi.fixAuthRequired, integration.id);
        }

        if (integration?.status === BankIntegrationStatus.USER_ACTION_REQUIRED
            || integration?.status === BankIntegrationStatus.ERROR
            || integration?.status === BankIntegrationStatus.IN_PROGRESS) {
            result = yield call(BridgeApi.fixUserActionRequired, integration.id);
        }

        if (result?.authUri) {
            window.location = result.authUri;
        }
    } catch (e) {
        Debug.error('bridge-api', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const setChosenBankConnectionFlagFlow = function* (userId, value) {
    try {
        yield call(BridgeApi.setChosenBankConnectionType, userId, value);

        yield put(LoggedInUserActions.loadUser());
    } catch (e) {
        Debug.error('bridge-api', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const setDefaultBankFlow = function* ({id, bankAccountHolderId, userId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.SET_DEFAULT_BANK, true));

        yield call(BridgeApi.setDefaultBank, {id, bankAccountHolderId});

        yield put(BankActions.getUserIntegrationData(userId));

        yield put(LoadingActions.setLoading(LoadingTypes.SET_DEFAULT_BANK, false));

        Toast.success('genericSuccessSave');
    } catch (e) {
        Debug.error('bridge-api', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SET_DEFAULT_BANK, false));
    }
};

export const connectToBridgeWorker = function* () {
    yield call(connectToBridgeFlow);
};

const checkBridgeConnectionWorker = function* () {
    yield call(checkBridgeConnectionFlow);
};

const onboardChosenAccountWorker = function* ({payload}) {
    yield call(onboardChosenAccountFlow, payload);
};

const fixBridgeAccountWorker = function* ({payload}) {
    yield call(fixBridgeAccountFlow, payload);
};

const setChosenBankConnectionFlagWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (!loggedInUser?.additionalInfo?.chosen_bank_connection_modal_type
    || loggedInUser?.additionalInfo?.chosen_bank_connection_modal_type
        === BankConnectionModalType.CONNECT_ANOTHER_BANK) {
        yield call(setChosenBankConnectionFlagFlow, loggedInUser.id, BankConnectionModalType.ALL_BANKS_CONNECTED);
    }
};

const setDefaultBankWorker = function* ({payload}) {
    yield call(setDefaultBankFlow, payload);

    const isModalOpen = yield select(UiSelectors.createIsModalActiveSelector(ModalsKeys.PICK_DEFAULT_BANK_MODAL));
    if (isModalOpen) {
        yield put(UiActions.setActiveModal(ModalsKeys.PICK_DEFAULT_BANK_MODAL, false));
    }
};

export const watchBridgeSaga = function* () {
    yield all([
        takeEvery(BridgeActionTypes.CONNECT_TO_BRIDGE, connectToBridgeWorker),
        takeEvery(BridgeActionTypes.CHECK_BRIDGE_ONBOARDING, checkBridgeConnectionWorker),
        takeEvery(BridgeActionTypes.ONBOARD_CHOSEN_ACCOUNT, onboardChosenAccountWorker),
        takeEvery(BridgeActionTypes.FIX_BRIDGE_ACCOUNT, fixBridgeAccountWorker),
        takeEvery(BridgeActionTypes.SET_CHOSEN_BANK_CONNECTION_FLAG, setChosenBankConnectionFlagWorker),
        takeEvery(BridgeActionTypes.SET_DEFAULT_BANK, setDefaultBankWorker),
    ]);
};
