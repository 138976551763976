import {faCloud, faDownload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, CircularProgress, Sheet, Typography} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Document, Page} from 'react-pdf';
import {useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {RibAndDocumentsSelector} from '../store/ribAndDocuments.selector';
import {
    DOWNLOADED_RIB_FILE_NAME,
    LG_RIB_PREVIEW_WIDTH,
    lm,
    pdfPageAspectRatio,
    pdfPageWidth,
    pixelDensity,
    tm,
} from '../utils/constants';

const RibPreviewAndDownload = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const [pageData, setPageData] = useState({width: 0, height: 0, canvasUrl: ''});

    const isMobileSize = useMediaQuery(`(max-width:1280px)`);

    const ribDocument = useSelector(RibAndDocumentsSelector.selectRibData);
    const dataUrl = ribDocument?.documentDataUrl;

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_RIB_DOCUMENT));
    const hasError = !isLoading && null === dataUrl;

    const onRenderSuccess = () => {
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        const pdfPageCanvas = document.querySelector('.import-pdf-page canvas');

        const pageWidth = pdfPageWidth;

        const leftMargin = lm * pixelDensity;
        const cardWidth = (pageWidth - 2 * lm) * pixelDensity;

        const pageHeight = pdfPageWidth / pdfPageAspectRatio;

        const topMargin = tm * pixelDensity;
        const cardHeight = (pageHeight - 2 * tm) * pixelDensity;


        canvas.width = cardWidth;
        canvas.height = cardHeight;
        canvasContext.drawImage(
            pdfPageCanvas,
            leftMargin,
            topMargin,
            cardWidth,
            cardHeight,
            0,
            0,
            cardWidth,
            cardHeight,
        );

        setPageData({
            width: cardWidth,
            height: cardHeight,
            canvasUrl: canvas.toDataURL(),
        });
    };

    const downloadRibDocument = () => {
        window.saveAs(dataUrl, DOWNLOADED_RIB_FILE_NAME);
    };

    return (
        <Box
            sx={{
                height: {
                    // xs: (window.innerWidth - 2 * 16) / LG_RIB_PREVIEW_WIDTH * 300, // 2 * x margin
                    md: (window.innerWidth - 2 * 32 - 240) / LG_RIB_PREVIEW_WIDTH * 300, // 2 * x margin - 240 (sidebar)
                    lg: 300,
                },
                overflow: 'hidden',
                minWidth: {
                    xs: 'fit-content',
                    md: LG_RIB_PREVIEW_WIDTH,
                },
                mt: 3,
                ml: 1,
                mr: 3,
                borderTopRightRadius: '12px',
                borderTopLeftRadius: '12px',
                boxShadow: 'md',
                ...(isMobileSize && {
                    mx: 3,
                    mt: 2,
                    height: 'fit-content',
                }),
            }}
        >
            {hasError && (
                <Box
                    component="div"
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <FontAwesomeIcon icon={faCloud} />
                    <Typography
                        sx={{
                            my: 1.25,
                            fontWeight: 500,
                            color: 'v2.light.text.secondary',
                        }}
                    >
                        {t('errorFetchingRibDocument')}
                    </Typography>
                </Box>
            )}
            {!pageData.canvasUrl && (
                <Box component="div" sx={{position: 'fixed', left: '100vw', top: '100vw'}}>
                    <Document
                        file={dataUrl}
                        sx={{width: pdfPageWidth, height: 'auto'}}
                        externalLinkTarget="_blank"
                        options={{workerSrc: 'pdf.worker.min.js'}}
                        width={pdfPageWidth}
                    >
                        <Page
                            pageNumber={1}
                            className="import-pdf-page"
                            onRenderSuccess={onRenderSuccess}
                            width={pdfPageWidth}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                        />
                    </Document>
                </Box>
            )}
            {!pageData.canvasUrl && !hasError && (
                <Box
                    sx={{
                        display: 'flex',
                        width: 'inherit',
                        height: 'inherit',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size="md" />
                </Box>
            )}
            {!!pageData.canvasUrl && (
                <Sheet
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <img
                        src={pageData.canvasUrl}
                        style={{
                            height: '350px',
                            ...(isMobileSize && {
                                height: 'auto',
                                width: '100%',
                            }),
                        }}
                        alt="PDF Page"
                    />
                    <Box
                        component="div"
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            height: '20%',
                            width: 'inherit',
                            backgroundColor: 'rgba(144, 164, 174, 0.1)',
                            backdropFilter: 'blur(2px)',
                            borderTopRightRadius: '12px',
                            borderTopLeftRadius: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'end',
                                p: 2,
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="solid"
                                color="primary"
                                size="sm"
                                onClick={downloadRibDocument}
                                startDecorator={
                                    <FontAwesomeIcon icon={faDownload} />
                                }
                            >
                                {t('downloadPdf')}
                            </Button>
                        </Box>
                    </Box>
                </Sheet>
            )}
        </Box>
    );
};

export default RibPreviewAndDownload;
